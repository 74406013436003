import { React, memo } from "react";

const OurValues = (props) => {
    return (
        <>
            <section id="OurValues">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-ThemeOne heading-Section">{props.heading}</h1>
                        </div>
                        <div className="col-md-12 mt-Btn-Section">
                            <div className="row">
                                <div className="col-md-4 mb-5  text-center">
                                    <div className=" mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integrity.png"} className="our-p-img" alt="Integrity" title="Integrity" />
                                    </div>
                                    <h5 className="subHeadingPresense">
                                        Integrity
                                    </h5>
                                    <p>To always do what's right for you</p>
                                </div>
                                <div className="col-md-4 mb-5 m-our-p text-center">
                                    <div className=" mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/dedication.png"} className="our-p-img" alt="Dedication" title="Dedication" />
                                    </div>
                                    <h5 className="subHeadingPresense">
                                        Dedication
                                    </h5>
                                    <p>To continuously meet the needs of our users, staff, partners,
                                        and investors
                                    </p>
                                </div>
                                <div className="col-md-4 mb-5 m-our-p text-center">
                                    <div className="text-center mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/compassion.png"} className="our-p-img" alt="Compassion" title="Compassion" />
                                    </div>
                                    <h5 className="subHeadingPresense">
                                        Compassion
                                    </h5>
                                    <p>To always work with care and a clear purpose </p>
                                </div>
                                <div className="col-md-4 mb-5 m-our-p text-center offset-md-2">
                                    <div className="mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/innovation.png"} className="our-p-img" alt="Innovation" title="innovation" />
                                    </div>
                                    <h5 className="subHeadingPresense">
                                        Innovation
                                    </h5>
                                    <p>To capitalize on our
                                        technology and continue to innovate for you
                                    </p>
                                </div>
                                <div className="col-md-4 mb-5 m-our-p text-center">
                                    <div className="mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/peoplecentric.png"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <h5 className="subHeadingPresense">
                                        People-centric
                                    </h5>
                                    <p>To continuously focus  on your needs </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(OurValues);