import React, { Component } from 'react';
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListServiceServicesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            serviceServices: []
        }
        this.addServiceServices = this.addServiceServices.bind(this);
        this.editServiceServices = this.editServiceServices.bind(this);
        this.deleteServiceServices = this.deleteServiceServices.bind(this);
    }

    deleteServiceServices(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => httpHelper.delete('DeleteServiceServices', id).then(res => {
                        this.setState({ serviceServices: this.state.serviceServices.filter(serviceServices => serviceServices.serviceServicesId !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/serviceServices');
                        /*setInterval(() => { this.props.history.go('/serviceServices'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.go('/serviceServices'); }
                }
            ]
        });        
    }
    viewServiceServices(id) {
        this.props.history.push(`/view-serviceServices/${id}`);
    }
    editServiceServices(id) {
        this.props.history.push(`/add-serviceServices/${id}`);
    }

    componentDidMount() {
        httpHelper.get('GetListServiceServices').then((res) => {
            this.setState({ serviceServices: res.data });
        });
    }

    addServiceServices() {
        this.props.history.push('/add-serviceServices/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Services</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addServiceServices}> Add </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered ">

                                                        <thead>
                                                            <tr>
                                                                <th> Service </th>
                                                                <th> Title </th>                                                               
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.serviceServices.map(
                                                                    serviceServices =>
                                                                        <tr key={serviceServices.serviceServicesId}>
                                                                            <td> {serviceServices.service}</td>
                                                                            <td> {serviceServices.title} </td>                                                                           
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button onClick={() => this.editServiceServices(serviceServices.serviceServicesId)} className="btn btn-primary btn-sm">Update </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteServiceServices(serviceServices.serviceServicesId)} className="btn btn-danger btn-sm">Delete </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewServiceServices(serviceServices.serviceServicesId)} className="btn btn-primary btn-sm">View </button>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListServiceServicesComponent