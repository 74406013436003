import React, { Component } from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import SunEditor from 'suneditor-react';
import FileBase64 from 'react-file-base64';
import HttpHelper from '../../httpHelper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateHealthCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            content: '',
            image: '',
            routeName:'',
            HealthCategories: [],
            errors: {}
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateHealthCategory = this.saveOrUpdateHealthCategory.bind(this);
    }
    handleValidation(data) {        
        let formIsValid = true;
        let errors = {};
        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }
        if (data.healthcategoryid == "") {
            formIsValid = false;
            errors["healthcategoryid"] = "Cannot be empty";
        }
        if (data.routeName == "") {
            formIsValid = false;
            errors["routeName"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {
        
        if (this.state.id === '_add') {            
            HttpHelper.get('GetHealthLibraryCategory').then(response => {
                this.setState({
                    HealthCategories: response.data
                });
            });
            return
        } else {
            HttpHelper.get('GetHealthLibraryCategory').then(response => {
                this.setState({
                    HealthCategories: response.data
                });
            });
            HttpHelper.getById('GetHealthLibraryById', this.state.id).then((res) => {
                let home = res.data;
                this.setState({
                    title: home.title,
                    description: home.description,
                    content: home.content,
                    image: home.image,
                    healthcategoryid: home.healthCategoryId,
                    routeName: home.routeName
                });
            });
        }
    }

    saveOrUpdateHealthCategory = (e) => {
        e.preventDefault();
        let health = {
            title: this.state.title,
            description: this.state.description,
            content: this.state.content,
            image: this.state.image,
            healthcategoryid: parseInt(this.state.healthcategoryid),
            routeName: this.state.routeName
        };
        // console.log('home => ' + JSON.stringify(home));
        // step 5
        if (this.handleValidation(health)) {
            if (this.state.id === '_add') {
                HttpHelper.create('/AddHealthLibraryContents', health).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/health');
                    /*setInterval(() => { this.props.history.push('/health'); }, 1000);                */
                });
            } else {
                health.id = this.state.id;
                HttpHelper.update('UpdateHealthLibraryContents', health, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/health');
                    /*setInterval(() => { this.props.history.push('/health'); }, 1000);                */

                });
            }
        }
        
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    changeContentHandler = (event) => {
        this.setState({ content: event });
    }
    changeRouteHandler = (event) => {
        this.setState({ routeName: event.target.value });
    }
    
    //getFiles(files) {
    //    this.setState({ image: files.base64 })
    //}

    changeAttachmentsHandler = (event) => {        
        const formData = new FormData();
        const filePath = "Upload\\HealthCategory";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);
        this.setState({ image: event.target.files[0].name });
        try {
            const res = HttpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        
    }

    cancel() {
        this.props.history.push('/health');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add HealthCategory</h3>
        } else {
            return <h3 className="text-center">Update HealthCategory</h3>
        }
    }


    changeHealthCategoryIdHandler = (event) => {
        this.setState({ healthcategoryid: event.target.value });
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Health Library</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Health Category: </label>
                                                            <select className="form-control" name="healthcategoryId" value={this.state.healthcategoryid} onChange={this.changeHealthCategoryIdHandler}  >
                                                                <option>Select Health Category</option>
                                                                {this.state.HealthCategories.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["healthcategoryid"]}</span>
                                                        </div>

                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} required="required" maxLength={255} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                        </div>

                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="description" className="form-control"
                                                                value={this.state.description} onChange={this.changeDescriptionHandler} required="required" />
                                                        </div>
                                                        <div className="form-group">
                                                            <img width='500' height='200' src={this.state.image} alt="" />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="file" placeholder="Image" name="image" className="form-control"
                                                                 onChange={this.changeAttachmentsHandler} />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="200" placeholder="Content" setOptions={{
                                                                buttonList: [
                                                                    /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    /*'/', //Line break*/
                                                                    ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['fontColor','fontSize', 'font'],
                                                                    /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                ],
                                                            }}
                                                                setContents={this.state.content} defaultValue={this.state.content} onChange={this.changeContentHandler} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Page Url: </label>
                                                            <input placeholder="Page Url" name="routeName" className="form-control"
                                                                value={this.state.routeName} onChange={this.changeRouteHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["routeName"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateHealthCategory} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateHealthCategoryComponent