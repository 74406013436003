import React, { Component } from 'react';
import { FaRegClock } from "react-icons/fa";
import httpHelper from '../../httpHelper';
import { Header } from '../Header';
import PageTitle from "../PageTitle";
import Footer from '../Footer';
import { Link } from "react-router-dom";
import moment from 'moment'
import ReactPaginate from 'react-paginate';

class MediaListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            mediaTypeId: '',
            mediaTypes: [
            ],
            title: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            mediamentions: [],
            perPage: 6,
            page: 0,
            pages: 0,
            medperPage: 6,
            medpage: 0,
            medpages: 0

        }

    }
    //get all data for media list 
    componentDidMount() {
        httpHelper.get('GetListMedia').then((res) => {
            if (res != undefined) {
                this.setState({ mediaTypes: res.data.filter(Obj => Obj.mediaTypeId == 'newsandevents') });
                this.setState({ mediamentions: res.data.filter(Obj => Obj.mediaTypeId == 'mediamentions') });
                this.setState({
                    pages: Math.ceil(this.state.mediaTypes.length / this.state.perPage)
                });
                this.setState({
                    medpages: Math.ceil(this.state.mediamentions.length / this.state.medperPage)
                });
            }
        });
    }
    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page })
    }
    mediahandlePageClick = (event) => {
        let medpage = event.selected;
        this.setState({ medpage })
    }
    render() {
        const { page, perPage, pages, mediaTypes } = this.state;
        let items = mediaTypes.slice(page * perPage, (page + 1) * perPage);
        let EventHtml = items.map(element => {
            return <div className="col-md-4" key={element.id}>
                <div className="my-2">
                    <div className="card" style={{ borderWidth: 0, borderBottomWidth: 0.5 }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            right: '0',
                            // top:-5
                        }}>
                        </div>
                        {!element.isVideo ?
                            <img src={element.thumbnail} className="card-img-top" alt={element.title} title={element.title} height="250" />
                            :
                            <iframe width="420" height="250" src={element.videoUrl}></iframe>

                        }
                        <div className="card-body">
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <FaRegClock style={{ color: '#e0e0e0' }} />
                                <p className="card-text">
                                    <small className="text-muted" style={{ marginLeft: 10 }}>{moment(element.createdOn).format('LL')}</small></p></div>
                            <h5 className="card-title  three_line_ellipsis mb-3 mt-3" style={{ color: "#0077b1" }}>{element.title}</h5>
                            <p className="card-text  two_line_ellipsis mb-3 mt-3">{element.description}</p>
                            <Link to={`/${element.routeName}/${element.id}/${element.mediaTypeId}`} style={{ borderRadius: "20px", borderColor: "#b5e9e0" }}
                                className="btn btn-outline-secondary btnListStyle but-health">Read More</Link>
                        </div>
                    </div>

                </div>

            </div>
        }) || '';


        const { medpage, medperPage, medpages, mediamentions } = this.state;
        let meditems = mediamentions.slice(medpage * medperPage, (medpage + 1) * medperPage);
        let mediaTypesHtml = meditems.map(element => {
            return <div className="col-md-4" key={element.id}>
                <div className="my-2">
                    <div className="card" style={{ borderWidth: 0, borderBottomWidth: 0.5 }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            right: '0',
                            // top:-5
                        }}>
                        </div>
                        {!element.isVideo ?
                            <img src={element.thumbnail} className="card-img-top" alt={element.title} title={element.title} height="250" />
                            :
                            <iframe width="420" height="250" src={element.videoUrl}></iframe>
                        }
                        <div className="card-body">
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <FaRegClock style={{ color: '#e0e0e0' }} />
                                <p className="card-text">
                                    <small className="text-muted" style={{ marginLeft: 10 }}>{moment(element.createdOn).format('LL')}</small></p></div>
                            <h5 className="card-title  three_line_ellipsis mb-3 mt-3" style={{ color: "#0077b1" }}>{element.title}</h5>
                            <p className="card-text  two_line_ellipsis mb-3 mt-3">{element.description}</p>
                            <Link to={`/${element.routeName}/${element.id}/${element.mediaTypeId}`} style={{ borderRadius: "20px", borderColor: "#b5e9e0" }}
                                className="btn btn-outline-secondary btnListStyle but-health">Read More</Link>
                        </div>
                    </div>

                </div>

            </div>
        }) || '';

        return (
            <div>
                <Header transparent={false} />
                <PageTitle title="Media" bgImage="assets/img/bg-media.png" />
                <section id="Media" style={{ backgroundColor: "#e8f3f7" }}>
                    <div className="container">
                        <div className="row">
                            <div className="card-body">
                                <div className='d-flex ' style={{ alignItems: 'center', margin: '35px 0px', marginTop: '50px' }}>
                                    <p className="card-text fs-20 lh-30">From interviews to event coverages, we are humbled to see how far we have come in fulfilling our
                                        purpose to reimagine the healthcare delivery system in a way that impacts people's lives positively.</p>
                                </div>
                                <div className='d-flex' style={{ alignItems: 'center', margin: '35px 0px', marginTop: '50px' }}>
                                    <p className="card-text fs-20 lh-30">From's providing accessible care to leading and supporting healthcare initiatives that spread awareness around important health-related topics, here is a glimpse of our presence in the media.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <h1 className="text" style={{ margin: '35px 185px ', marginTop: '120px', color: "#0077b1", }}>News and Events</h1> */}
                <div className="container" >
                    <div className="row">
                        <h3 className="text heading" style={{ margin: '35px 0px ', marginTop: '130px', color: "#0077b1", }}>News and Events</h3>
                        {EventHtml}
                        <ReactPaginate
                            previousLabel={'prev'}
                            nextLabel={'next'}
                            pageCount={pages}
                            onPageChange={this.handlePageClick}
                            containerClassName={'paginationListBox'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
                <section id="Media" style={{ backgroundColor: "#e8f3f7" }}>
                    <div className="container" style={{ marginTop: '50px' }}>
                        <div className="row" >
                            <h3 className="text" style={{ margin: '35px 0px ', marginTop: '100px', color: "#0077b1", }}>Media Mention</h3>
                            {mediaTypesHtml}
                            <ReactPaginate
                                previousLabel={'prev'}
                                nextLabel={'next'}
                                pageCount={medpages}
                                onPageChange={this.mediahandlePageClick}
                                containerClassName={'paginationListBox'}
                                activeClassName={'active'}
                            />


                        </div>
                        <div className="row" style={{ marginTop: '50px' }}>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        );
    }

}
export default MediaListComponent
