import React from "react";
import BoardMembers from "../components/BoardMembers";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';

export default function AboutUs() {

    const paragraphStyle = {
        margin: 0,
        padding: '1em',
        textAlign: 'justify',
        textAlignLast: 'left'
    };

    return (
        <>
            <Helmet>
                <title>Our Story</title>
                <meta name="description" content="region's impending shortage of telemedicine services and the escalating healthcare costs, along with the critical demand for accessible medical care, a profound opportunity emerged to make a transformative impact." />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Our Story" bgImage="assets/img/about-bg.jpg" />
            <PageContent>
                <p style={paragraphStyle}>
                    With the region's impending shortage of telemedicine services and the escalating healthcare costs, along with the critical demand for accessible medical care, a profound opportunity emerged to make a transformative impact.
                <br />
                <br />
                Established in 2011, TruDoc was founded on the understanding of the fundamental needs of patients and payers, driven by a resolute mission to align these needs. This visionary approach laid the creation for TruDoc's 24x7 Care Level Management Delivery System (CLM system), built on evidence-based medicine rather than profit-centric motives.
                At the core of TruDoc's operations is the seamless integration and meticulous management of all healthcare entry points within the continuum of care. A highly skilled and multidisciplinary team of medical professionals and support staff are entrusted to ensure daily stratification and early intervention, leading to superior outcomes for both patients and payers.
                The CLM system was designed to deliver accessible, convenient, and high-quality care by leveraging cutting-edge technology and innovative solutions.
                <br /><br />
                While equipped to provide 24x7 access to 80% of the population who consume 20% of medical services, TruDoc remains focused on bridging the gap in quality and access to care, specifically targeting high-risk chronic patients who constitute 20% of the population but account for a substantial 80% of medical services consumption.
                Under the stewardship of a leadership team with over 100 years of collective experience spanning various disciplines within healthcare, insurance, strategy, operations, and technology, TruDoc is paving the way to realize its vision and mission. The ultimate goal is to ensure quality care delivery to all, while simultaneously achieving cost savings and improved healthcare outcomes, thereby ensuring accessibility, affordability, and long-term sustainability for the broader community.
                <br /><br />
                As a dedicated healthcare guardian, TruDoc pledges unwavering commitment to be there for individuals at every step of their healthcare journey. The organization's narrative will continuously evolve in sync with the dynamic landscape of healthcare and the needs of its beneficiaries, ensuring that TruDoc perpetually remains at the forefront of providing the best possible care, anytime and anywhere.
</p>
            </ PageContent>
            <BoardMembers />
            <Footer />
        </>
    );
}
