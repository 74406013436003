import React from 'react'

const ColoredCardText = ({ coloredText, textColor, textfontsize, fontSize }) => {
    return (
        <div className='colored_card_text' style={{ color: textColor, fontSize: textfontsize || fontSize }}>
            <p>{coloredText}</p>
        </div>
    );
}

export default ColoredCardText

