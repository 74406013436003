import React from 'react';
import ContentDashboard from '../Dashboard/ContentDashboard';
import DashboardHeader from './DashboardHeader';
import { authProvider } from '../../AuthProvider';
import AzureAD from 'react-aad-msal';
import Footer from '../Footer';

export default function Dashboard() {
    return (
        <>
            <AzureAD provider={authProvider} forceLogin={true}>
                <DashboardHeader />
                <ContentDashboard />
                <Footer />
            </AzureAD>
        </>
    )
    //return (
    //    <>
    //        <DashboardHeader />
    //        <ContentDashboard />
    //    </>
    //)
}
