import React, { Component } from 'react'
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import { disable } from 'workbox-navigation-preload';
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';

class ViewTestimonialsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            testimonials: {}
        }
    }

    componentDidMount() {
        HttpHelper.getById('GetTestimonialsById', this.state.id).then(res => {
            this.setState({ testimonials: res.data });
        })
    }

    cancel() {
        this.props.history.push('/testimonials');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Testimonials</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.testimonials.title} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control" readOnly={true}
                                                                value={this.state.testimonials.name} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Age: </label>
                                                            <input type="number" placeholder="Age" name="age" className="form-control" readOnly={true}
                                                                value={this.state.testimonials.age} />
                                                        </div>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isActive" className="form-check-input"
                                                                    checked={this.state.testimonials.isActive} disabled={true} />
                                                                IsActive
                                                                <i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-6">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label> Description: </label>*/}
                                                    {/*        <input placeholder="Description" name="description" className="form-control"*/}
                                                    {/*            value={this.state.testimonials.description} readOnly={true} />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="400" placeholder="Content" setOptions={{
                                                               buttonList: [
                                                                /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                /*'/', //Line break*/
                                                                ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                ['fontColor','fontSize', 'font'],
                                                                /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                            ],
                                                            }}
                                                                setContents={this.state.testimonials.content} defaultValue={this.state.testimonials.content} disable={true} />
                                                            {/*<input placeholder="Content" name="content" className="form-control" readOnly={true}*/}
                                                            {/*    value={this.state.testimonials.content} />*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewTestimonialsComponent