import { React, memo } from "react";
import ReactHtmlParser from 'react-html-parser';

const PageTitle = (props) => {
    return (
        <>
            <section id="Pagetitle" className="mt-TitlePge" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + props.bgImage})` }} >
                <div className="overlayContent">
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-white textPageTile" style={{ color:props.color + '!important' }} >{ReactHtmlParser(props.title)}</h1>
                            <h3 className="text-white" style={{ fontStyle: "italic", width: "397px"}}>{ReactHtmlParser(props.subtitle)}</h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(PageTitle);