import ImageMapper from 'react-image-mapper';
/*import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";*/
import { useEffect, useState } from 'react';
/*import Dimensions from 'react-dimensions';*/
import { IoMdClose } from 'react-icons/io';
import Select from "react-dropdown-select";
import { map } from 'jquery';


var URL = process.env.PUBLIC_URL + 'assets/img/map.png';
var URLMapIcon = process.env.PUBLIC_URL + 'assets/img/icon-map.png';
var MAP = {
    name: "my-map",
    areas: [
        { value: 1, shape: "circle", coords: [575, 168, 16], preFillColor: "rgb(61 181 201 / 10%)", fillColor: "rgb(61 181 201 / 20%)", label: 'Nigeria', title: 'TruDoc', phone: '800878362', email: 'info@trudocgroup.com' },
        { value: 2, shape: "circle", coords: [575, 276, 21], preFillColor: "rgb(61 181 201 / 10%)", fillColor: "rgb(61 181 201 / 20%)", label: 'South Africa', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' },
        { value: 3, shape: "circle", coords: [612, 400, 15], preFillColor: "rgb(61 181 201 / 10%)", fillColor: "rgb(61 181 201 / 20%)", label: 'Kenya', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' },
        { value: 4, shape: "circle", coords: [659, 304, 17], preFillColor: "rgb(61 181 201 / 10%)", fillColor: "rgb(61 181 201 / 20%)", label: 'Kuwait', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' }
    ]
}

function OurPresenceNewMap() {

    const [mapID, setMapID] = useState(4);
    const [mapName, setMapName] = useState('');
    const [mapCountry, setMapCountry] = useState('');
    const [openPopupMap, setOpenPopupMap] = useState(true);
    const [coordsNew, setCoordsNew] = useState([0, 0, 0]);
    const [selectMapData, setSelectMapData] = useState('1');


    const techCompanies = [
        { label: "Apple", value: 1 },
        { label: "Facebook", value: 2 },
        { label: "Netflix", value: 3 },
        { label: "Tesla", value: 4 },
        { label: "Amazon", value: 5 },
        { label: "Alphabet", value: 6 },
    ];

    useEffect(() => {
        setCoordsNew(abc[0].coords);
    }, []);

    const closeCurrentPopUpMap = () => {
        setOpenPopupMap(false);
    }
    const abc = MAP.areas.filter(item => item.value === mapID);

    const popupOurPresenceMap = (area) => {
        setMapID(area.value);
        setOpenPopupMap(true)
        setCoordsNew(area.coords);
    }

    const mapDetailsContent = () => {
        return (
            <div style={{ left: coordsNew[0], top: coordsNew[1], display: openPopupMap === true ? 'flex' : 'none' }} className="mapPopupDetails" >
                <div className='crossOurPreseceBox' onClick={closeCurrentPopUpMap} >
                    <IoMdClose />
                </div>
                <div className='mapPopupDetailsMap'>
                    {/*<img src={URLMapIcon} />*/}
                    <img src={process.env.PUBLIC_URL + 'assets/img/place.png'} className="map-newPresenceLocation" />
                    <img src="https://cdn-icons-png.flaticon.com/512/197/197626.png" className="map-newPresenceLocationFlag" />
                    {/* {abc[0].name} */}
                </div>
                <div className='mapPopupDetailsDetails'>
                    <p className='titleMap'>{abc[0].label}</p>
                    <p className='countryMap'>{abc[0].title}</p>
                    <p className='number'>{abc[0].phone}</p>
                    <p className='email'>{abc[0].email}</p>
                </div>
            </div>
        )
    }

    useEffect(() => {
        MAP.areas.unshift({
            value: 5, shape: "circle", coords: [575, 168, 16], label: 'MAP', title: 'World Map', phone: '', email: ''
        })

    }, []);
    return (
        <>
            <div className="bg-blue-map">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12 mapOurPresenceOverFlow'>
                            <div className="imgMapperBoxMain">
                                <div id='imageData'>
                                    <ImageMapper src={URL} map={MAP} width={1200}
                                        onClick={area => {
                                            popupOurPresenceMap(area)
                                        }}
                                    />
                                </div>
                                <div>
                                    {MAP.areas.coords}
                                </div>
                                {mapDetailsContent()}
                            </div>
                        </div>
                        <div className="col-md-12 col-12 mapOurPresenceOverFlowRES">
                            {console.log(MAP.areas)}
                            <Select options={MAP.areas}
                                onChange={(e) => {
                                    setSelectMapData(e[0].value)
                                }}
                            />
                            <div className="selectBoxData">
                                {MAP.areas.filter(e => e.value == selectMapData).map((e) => {
                                    return (
                                        <>
                                            {e.value == 5 ? 
                                                <div class="col-md-12">
                                                    <img src={URL} className="img-fluid" />
                                                </div>

                                                : <div className="mapPopupDetails" >
                                                <div className='mapPopupDetailsMap'>
                                                    {/*<img src={URLMapIcon} />*/}
                                                    <img src={process.env.PUBLIC_URL + 'assets/img/place.png'} className="map-newPresenceLocation" />
                                                    <img src="https://cdn-icons-png.flaticon.com/512/197/197626.png" className="map-newPresenceLocationFlag" />
                                                    {/* {abc[0].name} */}
                                                </div>
                                                <div className='mapPopupDetailsDetails'>
                                                    <p className='titleMap'>{e.label}</p>
                                                    <p className='countryMap'>{e.title}</p>
                                                    <p className='number'>{e.phone}</p>
                                                    <p className='email'>{e.email}</p>
                                                </div>
                                            </div>}
                                           
                                        </>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurPresenceNewMap;