import React, { memo } from "react";

const PageContent = (props) => {
    return (
        <>
            <section id="PageContent" className={props.class} style={{ paddingBottom: '75px !important' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                {props.children}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(PageContent);