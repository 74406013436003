import React, { Component } from 'react'
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import httpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';

class ViewMediaComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            mediaTypes: [],
            media: {}
        }
    }

    componentDidMount() {
        httpHelper.get('GetListMediaType').then(response => {
            /*console.log(response.data);*/
            this.setState({
                mediaTypes: response.data
            });
        });

        httpHelper.getById('GetMediaById', this.state.id).then(res => {
            this.setState({ media: res.data });
        })
    }

    cancel() {
        this.props.history.push('/media');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Media</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Media Type: </label>
                                                            <select className="form-control" name="mediaTypeId" value={this.state.media.mediaTypeId} disabled="true">
                                                                <option>Select Media Type</option>
                                                                {this.state.mediaTypes.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.media.title} />
                                                        </div>
                                                        <div className="form-group">
                                                            <img width='500' height='200' src={this.state.media.thumbnail} alt={this.state.media.title} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label> Page Url : </label>
                                                            <input placeholder="Page Url" name="routeName" className="form-control" readOnly={true}
                                                                value={this.state.media.routeName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="200" placeholder="Content" setOptions={{
                                                                buttonList: [
                                                                    /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    /*'/', //Line break*/
                                                                    ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['fontColor','fontSize', 'font'],
                                                                    /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                ],
                                                            }}
                                                                setContents={this.state.media.content} disable={true} />
                                                            {/*<input placeholder="Content" name="content" className="form-control"*/}
                                                            {/*    value={this.state.content} onChange={this.changeContentHandler} />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewMediaComponent