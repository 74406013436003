import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import WhyTrudocPageContent from "../components/WhyTrudocPageContent";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';


export default function WhyTruDoc() {
    return (
        <>
            <Helmet>
                <title>Why TruDoc</title>
                <meta name="description" content="Traditional health care solutions are known to be provider-centric which not only adds to the financial burden of the patient but proves disadvantageous for the payer too. Focused on offering clinical and episodic symptom care, traditional health care solutions also fall short in providing preventive care and support for long-term health, which if provided can significantly help reduce the unnecessary use of healthcare services." />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Why TruDoc?" bgImage="assets/img/bg_whytrudoc.jpeg" />
            <WhyTrudocPageContent
                para="Traditional health care solutions are known to be provider-centric which not only adds to the financial burden of the patient but proves disadvantageous for the payer too. Focused on offering clinical and episodic symptom care, traditional health care solutions also fall short in providing preventive care
                and support for long-term health, which if provided can significantly help reduce the unnecessary use of healthcare services. Furthermore, these traditional solutions often follow a fee-for-service model
                focused on volume, not value, leading to the abuse and overutilization of medical services."
                para_1="On the contrary, TruDoc’s Care Level Management Delivery System offers:"
                why_li1="24x7 access to doctor consultations with centralized medical records no matter where you are"
                why_li2="Evidence-based medicine and 24x7 early intervention services which helps save on unnecessary healthcare costs"
                why_li3="Continuous risk stratification through our 24x7 TruDoc telemonitoring system which helps provide better integrated and more efficient care for high-risk patients"
                why_li4="Accessible care provided by licensed doctors, psychologists, or dietitians through voice/video calls and live chat, from anywhere in the world"
                why_li5="Quality and accessible care to high-risk chronic patients, the 20% of the population who consume 80% of the medical services"
                bottom_para_1="From everyday to complex care, our range of high-quality, accessible, and affordable services include:"
                bottom_sec_1="<b>TruDoc’s Integrated Mobile Application</b> offers instant access to 24x7 reliable, convenient, and affordable healthcare"
                bottom_sec_2="TruDoc’s Virtual Clinic offers access to doctors through voice/video calls and live chat during working hours"
                bottom_sec_3="TruDoc’s Hospitalist/Roaming Doctor Program offers coordinated care in the hospital between the patient, family, and the treating physician"
                bottom_sec_4="TruDoc’s Hospital at Home Program offers 24x7 telemonitoring for acute and chronic care of patients in the comfort of their homes"            />
                
            
            <Footer />
        </>
    );
}
