import React from 'react';

const CustomButton = ({ backgroundColor, textColor, children }) => {
  const buttonStyle = {
    backgroundColor: backgroundColor || 'blue',
    color: textColor || 'white',
  };

  return (
    <button className="custom-button" style={buttonStyle}>
      {children}
    </button>
  );
};

export default CustomButton;
