import React, { memo } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const WhyTruDoc = (props) => {



    return (
        <>
            <section id="WhyTruDoc" className="SectionDefaultstyle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})` }}>
                <div className="BgGradient"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <h2 className="heading">
                                {props.heading}
                            </h2>
                        </div>
                        <div className="col-md-12 mb-5">
                            <p className="text-white text-lg">
                                We are Always with You. We offer a <b>24x7 one-stop-care-destination</b> for people who are healthy, acutely, or chronically ill through different entry points that are all connected to our 24x7 Doctor Call Center.
                            </p>
                        </div>


                        <div className="col-md-12">
                            <div className="tabsWhyTruDoc">
                                <Tabs>
                                    <TabList>
                                        <Tab>When traveling<br /> <span>Integrated Mobile Application</span></Tab>
                                        <Tab>When at the office <br /> <span>Virtual Clinic</span></Tab>
                                        <Tab>When at the hospital <br /> <span>Hospitalist/Roaming Doctor Program</span></Tab>
                                        <Tab>When at home <br /> <span>Hospital at Home Program</span> </Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="Boximg">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-2.png'} className="img-fluid" alt="Integrated Mobile Application" title="Integrated Mobile Application" />
                                        </div>
                                        <h4 className="tabContent">Integrated Mobile Application</h4>
                                        <span className="tabContentSubheading">When traveling</span>
                                        <p className="content h-70">
                                            Whether you are traveling to a different city or are a few miles from home, you can speak to a TruDoc doctor through our mobile application via voice.
                                        </p>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="Boximg">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/virtual-clinic.png'} className="img-fluid" alt="Virtual Clinic" title="Virtual Clinic" />
                                        </div>
                                        <h4 className="tabContent">Virtual Clinic</h4>
                                        <span className="tabContentSubheading">When at the office</span>
                                        <p className="content  h-70">
                                            TruDoc’s Virtual Clinics can be made available in different locations including corporate offices, staff accommodation, pharmacies, schools, malls, nursing homes, hotels, etc.
                                        </p>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="Boximg">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-4.png'} className="img-fluid" alt="Hospitalist/Roaming Doctor Program" title="Hospitalist/Roaming Doctor Program" />
                                        </div>
                                        <h4 className="tabContent">Hospitalist/Roaming Doctor Program</h4>
                                        <span className="tabContentSubheading">When at the hospital</span>
                                        <p className="content h-70">
                                            TruDoc’s Hospitalist/Roaming Doctor Program coordinates the care in the hospital and serves as a liaison between the patient, family, and the treating physician.
                                        </p>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="Boximg">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-1.png'} className="img-fluid" alt="Hospital at Home Program" title="Hospital at Home Program" />
                                        </div>
                                        <h4 className="tabContent">Hospital at Home Program</h4>
                                        <span className="tabContentSubheading">When at home</span>
                                        <p className="content h-70">
                                            TruDoc’s Hospital at Home Program with 24x7 telemonitoring provides acute and chronic care for patients in the comfort of their homes. Patients are monitored 24x7 by our Doctor Call Center through TruDoc’s integrated bluetooth devices. Doctors and nurses are available to visit 24x7.
                                        </p>
                                    </TabPanel>
                                </Tabs>
                            </div>
                            <div className="truDocMobile">
                                <div className="truDocMobileBox">
                                    <div className="Boximg">
                                        <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-2.png'} className="img-fluid" alt="Integrated Mobile Application" title="Integrated Mobile Application"/>
                                    </div>
                                    <h4 className="tabContent">Integrated Mobile Application</h4>
                                    <span className="tabContentSubheading">When traveling</span>
                                    <p className="content h-70">
                                        Integrated Mobile Application when traveling
                                        Whether you are traveling to a different city or are a few miles from home, you can speak to a TruDoc doctor through our mobile application via voice.
                                    </p>
                                </div>
                            </div>
                            <div className="truDocMobile">
                                <div className="truDocMobileBox">
                                    <div className="Boximg">
                                        <img src={process.env.PUBLIC_URL + 'assets/img/virtual-clinic.png'} className="img-fluid" alt="Virtual Clinic" title="Virtual Clinic" />
                                    </div>
                                    <h4 className="tabContent">Virtual Clinic</h4>
                                    <span className="tabContentSubheading">When at work</span>
                                    <p className="content  h-70">
                                        TruDoc’s Virtual Clinics can be made available in different locations including corporate offices, staff accommodation, pharmacies, schools, malls, nursing homes, hotels, etc.
                                    </p>
                                </div>
                            </div>

                            <div className="truDocMobile">
                                <div className="truDocMobileBox">
                                    <div className="Boximg">
                                        <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-4.png'} className="img-fluid" alt="Hospitalist/Roaming Doctor Program" title="Hospitalist/Roaming Doctor Program" />
                                    </div>
                                    <h4 className="tabContent">Hospitalist/Roaming Doctor Program</h4>
                                    <span className="tabContentSubheading">When at the hospital</span>
                                    <p className="content h-70">
                                        TruDoc’s Hospitalist/Roaming Doctor Program coordinates the care in the hospital and serves as a liaison between the patient, family, and the treating physician.
                                    </p>
                                </div>
                            </div>

                            <div className="truDocMobile">
                                <div className="truDocMobileBox">
                                    <div className="Boximg">
                                        <img src={process.env.PUBLIC_URL + 'assets/img/why-tru-doc-image-1.png'} className="img-fluid" alt="Hospital at Home Program" title="Hospital at Home Program" />
                                    </div>
                                    <h4 className="tabContent">Hospital at Home Program</h4>
                                    <span className="tabContentSubheading">When at home</span>
                                    <p className="content h-70">
                                        TruDoc’s Hospital at Home Program with 24x7 telemonitoring provides acute and chronic care for patients in the comfort of their homes. Patients are monitored 24x7 by our Doctor Call Center through TruDoc’s integrated bluetooth devices. Doctors and nurses are available to visit 24x7.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(WhyTruDoc);