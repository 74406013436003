import React, { Component } from 'react'
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListFAQCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fAQCategories: []
        }
        this.addFAQCategory = this.addFAQCategory.bind(this);
        this.editFAQCategory = this.editFAQCategory.bind(this);
        this.deleteFAQCategory = this.deleteFAQCategory.bind(this);
    }

    deleteFAQCategory(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => httpHelper.delete('DeleteFaqCategory', id).then(res => {
                        this.setState({ fAQCategories: this.state.fAQCategories.filter(fAQCategory => fAQCategory.id !== id) });
                        toast("Record delete successfully!");
                        this.props.history.go('/fAQCategory');
                        /*setInterval(() => { this.props.history.go('/fAQCategory'); }, 1000);*/

                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.go('/fAQCategory'); }
                }
            ]
        });

    }
    viewFAQCategory(id) {
        this.props.history.push(`/view-fAQCategory/${id}`);
    }
    editFAQCategory(id) {
        this.props.history.push(`/add-fAQCategory/${id}`);
    }

    componentDidMount() {
        httpHelper.get('GetListFaqCategory').then((res) => {

            this.setState({ fAQCategories: res.data });
        });
    }

    addFAQCategory() {
        this.props.history.push('/add-fAQCategory/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">FAQ category</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addFAQCategory}> Add</button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <table className="table table-striped table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th> Title</th>
                                                            <th>Ordinal</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.fAQCategories.map(
                                                                fAQCategory =>
                                                                    <tr key={fAQCategory.id}>
                                                                        <td> {fAQCategory.name} </td>
                                                                        <td> {fAQCategory.ordinal} </td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            <button onClick={() => this.editFAQCategory(fAQCategory.id)} className="btn btn-primary btn-sm">Update </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.deleteFAQCategory(fAQCategory.id)} className="btn btn-danger btn-sm">Delete </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.viewFAQCategory(fAQCategory.id)} className="btn btn-primary btn-sm">View </button>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListFAQCategoryComponent