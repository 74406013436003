import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import SubServiceOffered2 from "../components/SubServiceOffered2";
export default function PatientManagement() {
    return (
        <>
            <Header transparent={false} />
            <PageTitle title="Patient Management Program" subtitle="when you need support in regaining
control of your health" bgImage="assets/img/bg-patient.png" />

            <SubServiceOffered2
                right_image1="assets/img/offer-right-img-1.png" />



            <Footer />
        </>
    );
}
