import React from "react";
import CustomButton from "./CustomButton";

const HighlightWithActions2 = ({
  imageUrl,
  text,
  buttonText,
  onButtonClick,
  hwabackgroundColor,
  hwatextColor,
}) => {
  return (
    <div className="highlight-card2">
      <img src={imageUrl} alt="Highlight card" className="highlight-card-image2" />
      <div className="highlight-card-content2">
        <div>
          <div className="highlight-card-title2">Comprehensive Management of</div>
          <div className="highlight-card-highlight2">Cardiorenal Syndrome</div>
        </div>
        <div className="highlight-card-text2">
          Using a Hospital-at-Home Approach in a Patient
          with Type 2 Diabetes, Advanced Chronic Kidney
          Disease, and Pulmonary Hypertension
        </div>
        <a href="https://forms.office.com/r/vPeUFT9i1J" target="_blank" rel="noreferrer" >
          <CustomButton textColor={hwatextColor} backgroundColor={hwabackgroundColor}>
            <b>READ MORE</b>
          </CustomButton>
        </a>
      </div>
    </div >
  );
};

export default HighlightWithActions2;
