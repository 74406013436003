import React from 'react';

const HomeBanner = () => {
  return (
    <div className="home-banner">
      {/* Text Content */}
      <div className="content">
        <div className="banner-title">
          TruDoc
        </div>
        <div className="banner-subtext">
          Home Healthcare
        </div>
        <div className="banner-footer-text">
          From Vision to Reality!
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
