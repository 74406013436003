import React, { memo } from "react";
const VisionMission = (props) => {
    return (
        <>

<section id="VisionMission">
    <div className="container">
        <div className="row" id="flex">
            <div className="col-md-6 p-0" id="div1">
                <div className="section_purpose res-top-border-radius bg1-blue border-top-left">
                     <h1 className="detailsHeading mb-5 text-white">
                         {props.HeadingOne}
                     </h1>
                     <span className="fs-18  text-white">
                         {props.ParaOne}
                    </span>
                </div>
            </div>
            <div className="col-md-6  p-0  h-440 border-top-right  br-top-40 img-purpose" id="div2"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/img/purpose.jpg'})` }}></div>
            <div className="col-md-6  p-0 h-440  img-vision" id="div3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/img/vision.jpg'})` }}></div>
            <div className="col-md-6   p-0" id="div4">
                 <div className="section_purpose  bg2-specia">
                    
                       <h1 className="detailsHeading mb-5 text-white">
                                    {props.HeadingTwo} 
                       </h1>
                       <span className="fs-18 text-white">
                                    {props.ParaTwo}
                       </span>
                </div>
            </div>
            <div className="col-md-6 p-0" id="div5">
                            <div className="section_purpose bg3-d-blue border-bottom-left  br-bottom-40">
                     <h1 className="detailsHeading mb-5  text-white">
                                     {props.HeadingThree}
                     </h1>
                     <span className="fs-18 text-white">
                                    {props.ParaThree}
                     </span>
                 </div>
            </div>
            <div className="col-md-6  p-0 h-440 border-bottom-right img-mission" id="div6"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/img/mission.jpg'})` }}></div>
        </div>
    </div>
 </section>
        </>
    );
}

export default memo(VisionMission);