import { React, memo } from "react";
import CallButton from "./CallButton";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
//import FaqQues from "../components/FaqQues"

//const OurComplaint = (props) => {
const PatientsRightsResponsibilities = (props) => {
    return (
        <>
            <section id="paddingBottomServices" >
                {/*<PageTitle className="KnowMoreTeleconsultation"  title="Patients' Rights and </br> Responsibilities" bgImage="assets/img/patient-right-responsibilities.jpeg"  />*/}
                <section id="Pagetitle" class="pageTitleTeleMedicine" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + 'assets/img/patient-right-responsibilities.jpeg'})` }}>
                    <div class="overlayContent"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 class="text-white textPageTile pageTitleTeleMedicineTxtHeading">Patients' Rights and <br /> Responsibilities</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <PageContent class="rr-TitlePge"  >
                    <p className="font24">TruDoc 24/7 has created a Patient Charter setting out patient rights and responsibilities. You the Patient and/or when appropriate family, in return, have the following responsibilities.</p>

                </ PageContent>

                <PageContent class="bg-white" >
                    <h4 className="text-ThemeOne heading-Section" color="blue" style={{ paddingBottom: '25px' }}>
                        1.1 You have the right to
                    </h4>

                    <ul className="Telemedicinelisttext" style={{ paddingBottom: '15px' }}>


                        <li> know by name the physician, nurse and other staff members responsible for  your care </li>

                        <li>	talk openly with your physician about your diagnosis, the treatment prescribed  for you, the prognosis of ypur illness, and any instruction required 	for the follow up care.</li>

                        <li>	Request that your physician communicates in terminology you may reasonably except to understand</li>

                        <li>	Have your request courteously received and properly considered as quickly as circumstances permit</li>

                        <li>	Be informed of the reason you are given various tests and treatmnets, and who the person are who give tem to you</li>

                        <li>	Be informed of the general nature and inherent risk of any procedure for which you have given your consent</li>

                        <li>	Change your mind about any procedure for which you have given consent, to refuse treatment and to be informed of the medical of this action</li>

                        <li>	Expect your personal privacy to be respected to te fullest extent consistent with the care 	prescribed for you and applicable to UAE laws</li>

                        <li>	Expect all communications and other records pertaining to your care to be kept confidential to the extent 	required by law</li>

                        <li>	Request through your attending physician a second opinion by another physician, to change physicians, or to change hospitals and/or facilities</li>

                        <li>    Participate in ethical discussion that arise in the course of your care</li>

                        <li>	Have impartial access to medical resources indicated for your care without regard to race, nationality, age, gender or disability</li>

                        <li>	Refuse to participate 	in medical training programs or research projects</li>

                        <li>	Care and treatment in a safe environment</li>

                        <li>	Have pain management in a compassionate manner</li>

                        <li>	Be informed about the outcomes  of care, including unanticipated outcomes</li>

                    </ul>

                    <h3 className="text-ThemeOne heading-Section" color="blue" style={{ paddingBottom: '25px' }}>
                        1.2 You in turn have the responsibility to
                    </h3>

                    <ul className="Telemedicinelisttext">
                        <li>   Give cooperation and to follow the care prescribed or recommended for you by your physician, nurse or staff members responsible for your care</li>

                        <li>	Notify your physician pr nurse if you do not understand your diagnosis, treatment 	or prognosis</li>

                        <li>	Provide to the healthcare provider, to the best of your knowledge, accurate and complete information about present complaints, past illness, hospitalization, medications and any other matters relating 	to your health</li>

                        <li>   Respect 	the rights and privacy 	of other 	patients and follow institutional policies as posted</li>

                        <li>   To keep appointments, and when you are unable to do so for any reason, to notify the healthcare provider</li>

                        <li>   Accept your actions if you refuse treatment or do not follow the healthcare provider's instructions</li>

                        <li>   Accept financial obligations associated with your care 	</li>

                    </ul>

                </ PageContent>
                <div className="complaintPolicy">
                    <div className="container">


                        <div className="row">
                            <div className="col-md-12">

                                <h2 className="headingComplaintPrimary " >Our Complaint Policy</h2>
                            </div>
                        </div>
                        <p> We appereciate any feedback or concerns you may have, and we have a Complaint Policy in place to guarantee  that issues are addressed openly andhonestly.</p>
                        <p> If you have a complaint , please call our toll free number 800878362 or email us at complaints@trudoc.ae at any time. As a patient you have the option oTruDoc directly, or alternatively with Health Authority-Abu Dhabithrough the Abu Dhabi Government Contract Centre toll free number 800 555.</p>
                        <p>We pledge to resolve all complaints within 5 days and to acknowledge all written complaints within 3 working days. If the nature of complaint prevents us from resolving it within 5 days, we will notify you of the delay. On your request, we will notify you of the activities that will be done in response to your complaint.</p>
                        <p>We fully adhere to Health Authority- Abu Dhabi's standards for patient rights and responsibilities and aim to provide you with the reassurance that your complaintwill eb acted upon promptly and fairly as per ourComPlaint Policy. </p>



                    </div>
                </div>


            </section>
        </>
    );
}

//export default memo(OurComplaint);
export default memo(PatientsRightsResponsibilities);
