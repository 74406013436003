import { React, memo, useState, useEffect, useRef } from "react";
import httpHelper from "../httpHelper";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { ToastContainer, toast } from 'react-toastify';

const ContactForm = ({ ContainerId, colorType }) => {


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [classMsg, setClassMsg] = useState('error');
    const [phoneNumberCode, setPhoneNumberCode] = useState('');

    const [emptyName, setEmptyName] = useState('');
    const [emptyEmail, setEmptyEmail] = useState('');
    const [emptyPhone, setEmptyPhone] = useState('');
    const [emptyMessage, setEmptyMessage] = useState('');

    const inputNameRef = useRef(null);
    const inputEmailRef = useRef(null);
    const inputPhoneRef = useRef(null);

    const [selectedFlag, setSelectedFlag] = useState("");


    function sendEmail(e) {
        e.preventDefault();
        console.log(number);
        if (name == '' || email == '' || number == '' || message == "") {

            //toast.error('Please fill all the mandatory fields!');
            if (name == '') {
                inputNameRef.current.focus()
                    setEmptyName('Can not be empty');
            }
            if (email == '') {
                inputEmailRef.current.focus()
                setEmptyEmail('Can not be empty');
            }
            if (number == '') {
                inputPhoneRef.current.focus();
                setEmptyPhone('Can not be empty');
            }
            if (message == '') {
                inputPhoneRef.current.focus();
                setEmptyMessage('Can not be empty');
            }
        }
        else if (isValidPhoneNumber(number) === false) {
            toast.error("Please enter a valid phone number!")
        }
        else {
            let emailSendData = {
                name: name,
                email: email,
                phoneNumber: number,
                subject: subject,
                message: message,
                sourcetype: ContainerId == 'ContactFormIndividuals' ? 'Individuals' : 'ContactUs'
            };
            httpHelper.create('/PostContactUs', emailSendData)
                .then((success) => {
                    if (success) {
                        toast.success('Email sent successfully!!');
                    }
                })
            setName('');
            setEmail('');
            setNumber('');
            setSubject('');
            setMessage('');
        }
    }

    return (
        <>
            <section id={ContainerId} className={colorType}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {ContainerId == 'ContactFormIndividuals' ?
                                <div className="form-group col-md-12 formContact">
                                    <h3 id="txtContactArea">
                                        Contact us for more information
                                    </h3>
                                </div>
                                : null
                            }

                            <form className="form-horizontal formContact row" onSubmit={(e) => sendEmail(e)}>

                                <div className="form-group col-md-6">
                                    <label>
                                        Name*
                                    </label>
                                    <input type="text" value={name} id="nameContactForm" onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setEmptyName('');
                                        } else {
                                            setEmptyName('Can not be empty');
                                        }
                                        setName(e.target.value)
                                    }}
                                        ref={inputNameRef}
                                        className={emptyName ? "invalid-field form-control" : "form-control"} />
                                    {/*{emptyName ? <span className="errorColorForm">{emptyName}</span> : "" }*/}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Email*
                                    </label>
                                    <input type="email" value={email} onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setEmptyEmail('');
                                        } else {
                                            setEmptyEmail('Can not be empty');
                                        }
                                        setEmail(e.target.value)
                                    }}
                                        ref={inputEmailRef}
                                        className={emptyEmail ? "invalid-field form-control" : "form-control"} />
                                    {/*{emptyEmail ? <span className="errorColorForm">{emptyEmail}</span> : ""}*/}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Phone Number*
                                    </label>
                                    <div className={emptyPhone ? "invalid-field PhoneCoutryCodeSelect form-control" : "PhoneCoutryCodeSelect form-control"}>
                                        <PhoneInput
                                            placeholder=""
                                            value={number}
                                            limitMaxLength={15}
                                            ref={inputPhoneRef}
                                            onChange={(e) => {
                                                setNumber(e);
                                                if (e && e.length > 0) {
                                                    setEmptyPhone("");
                                                } else {
                                                    setEmptyPhone("Can not be empty");
                                                }
                                            }}
                                        />
                                       {/*{emptyPhone ? <span className="errorColorForm mt-2 d-block">{emptyPhone}</span> : "" }*/}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Subject
                                    </label>
                                    <input type="text" value={subject} maxLength="60" onChange={(e) => {
                                        setSubject(e.target.value)
                                    }} className="form-control" />
                                    {/*<span class="errorColorForm msg-pl-20">Maximum limit is 60 characters</span>*/}
                                </div>
                                <div className="form-group col-md-12 mt-3">
                                    <label>
                                        Message*
                                    </label>
                                    <textarea className={emptyMessage ? "invalid-field textareatransparent" : "textareatransparent"} maxLength="500" value={message} onChange={(e) => {
                                        setMessage(e.target.value);
                                        if (e && e.target.value.length > 0) {
                                            setEmptyMessage("");
                                        } else {
                                            setEmptyMessage("Can not be empty");
                                        }
                                        
                                    }}></textarea>
                                    {/*{emptyMessage ? <span className="errorColorForm mt-2 d-block">{emptyMessage}</span> : ""}*/}
                                    {/*<span className="errorColorForm msg-pl-20">Maximum limit is 500 characters</span>*/}
                                </div>
                                <div className="col-md-12 text-left">
                                    <p className="errorColorForm msg-pl-20">*Mandatory fields </p>
                                </div>
                                <div className="col-md-12 text-center">
                                    <p className={classMsg == 'error' ? 'errorColorForm' : 'successColorForm'}>
                                        {error}
                                    </p>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button className="btn btnContactform ContactUs-Form-Submission-Event"  >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer style={{ zIndex: 999999 }} position="top-right" closeButton autoClose={3000} />
            </section>

        </>
    );
}

export default memo(ContactForm);