import { useEffect } from "react";
import { useLocation } from "react-router-dom";


//Sandeep: Scroll to top. Add this to index.js so all component loading will scroll to top.
export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}