import React, { memo } from "react";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const SocialFeed = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };
    return (
        <>
            <section id="SocialFeed">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <h2 className="heading">
                                {props.heading}
                            </h2>
                        </div>
                        <div className="col-md-4">
                            <div className="socialFeedBox">
                                <div className="SocialImg">
                                    <img src={process.env.PUBLIC_URL + "assets/img/social-feed.png"} alt="Instagram" title="Instagram" />
                                </div>
                                <div className="overlayGradinet">
                                    <a href={props.urlOne} target="_blank"><FaInstagram /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="socialFeedBox">
                                <div className="SocialImg">
                                    <img src={process.env.PUBLIC_URL + "assets/img/social-fb.png"} alt="FaceBook" title="facebook" />
                                </div>
                                <div className="overlayGradinet">
                                    <a href={props.urlTwo} target="_blank"><FaFacebookF /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="socialFeedBox">
                                <div className="SocialImg">
                                    <img src={process.env.PUBLIC_URL + "assets/img/social-linkedin.png"} alt="Linked In" title="Linked In" />
                                </div>
                                <div className="overlayGradinet">
                                    <a href={props.urlThree} target="_blank"><FaLinkedinIn /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(SocialFeed);