import React, { Component } from 'react';
import { Route } from 'react-router';
import HomePage from './Pages/HomePage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css';
import ListCareerComponent from './components/Careers/ListCareerComponent';
import ViewCareerComponent from './components/Careers/ViewCareerComponent';
import CareerPage from './Pages/Career';
import CreateContactUsComponent from './components/ContactUs/CreateContactUsComponent';
import ListContactUsComponent from './components/ContactUs/ListContactUsComponent';
import ViewContactUsComponent from './components/ContactUs/ViewContactUsComponent';
import CreateTestimonialsComponent from './components/Testimonials/CreateTestimonialsComponent';
import ListTestimonialsComponent from './components/Testimonials/ListTestimonialsComponent';
import ViewTestimonialsComponent from './components/Testimonials/ViewTestimonialsComponent';
import CreateHomeComponent from './components/Home/CreateHomeComponent';
import ListHomeComponent from './components/Home/ListHomeComponent';
import ViewHomeComponent from './components/Home/ViewHomeComponent';
import CreateSettingComponent from './components/Setting/CreateSettingComponent';
import ListSettingComponent from './components/Setting/ListSettingComponent';
import ViewSettingComponent from './components/Setting/ViewSettingComponent';
import CreateServiceComponent from './components/WhatWeDo/Service/CreateServiceComponent';
import ListServiceComponent from './components/WhatWeDo/Service/ListServiceComponent';
import ViewServiceComponent from './components/WhatWeDo/Service/ViewServiceComponent';
import CreateSubServiceComponent from './components/WhatWeDo/SubService/CreateSubServiceComponent';
import ListSubServiceComponent from './components/WhatWeDo/SubService/ListSubServiceComponent';
import ViewSubServiceComponent from './components/WhatWeDo/SubService/ViewSubServiceComponent';
import CreateServiceServicesComponent from './components/WhatWeDo/Service/Services/CreateServiceServicesComponent';
import ListServiceServicesComponent from './components/WhatWeDo/Service/Services/ListServiceServicesComponent';
import ViewServiceServicesComponent from './components/WhatWeDo/Service/Services/ViewServiceServicesComponent';
import Dashboard from './components/Dashboard/Dashboard';
import AboutUs from './Pages/AboutUs';
import ListServiceFeaturesComponent from './components/WhatWeDo/Service/Features/ListServiceFeaturesComponent';
import CreateServiceFeaturesComponent from './components/WhatWeDo/Service/Features/CreateServiceFeaturesComponent';
import ViewServiceFeaturesComponent from './components/WhatWeDo/Service/Features/ViewServiceFeaturesComponent';
import Purpose from './Pages/Purpose';
import Milestone from './Pages/Milestone';
import WhyTruDoc from './Pages/WhyTruDoc';
import WhatWeDo from './Pages/WhatWeDo';
import IntegratedMobileApp from './Pages/IntegratedMobileApp';
import NutritionCare from './Pages/NutritionCare';
import MentalHealthCare from './Pages/MentalHealthCare';
import PatientManagement from './Pages/PatientManagement';
import ContactUsPage from './Pages/ContactUsPage';
import RefundsAndCancellation from './Pages/RefundsAndCancellation.js';
import ListMediaComponent from './components/Media/ListMediaComponent';
import CreateMediaComponent from './components/Media/CreateMediaComponent';
import ViewMediaComponent from './components/Media/ViewMediaComponent';
import ListHealthCategoryComponent from './components/HealthLibrary/ListHealthCategoryComponent';
import CreateHealthCategoryComponent from './components/HealthLibrary/CreateHealthCategoryComponent';
import ViewHealthCategoryComponent from './components/HealthLibrary/ViewHealthCategoryComponent';
import TermsAndCondition from './Pages/TermsAndCondition';
import PrivacyAndPolicy from './Pages/PrivacyAndPolicy';
import ListServiceBenefitsComponent from './components/WhatWeDo/Service/Benefits/ListServiceBenefitsComponent';
import CreateServiceBenefitsComponent from './components/WhatWeDo/Service/Benefits/CreateServiceBenefitsComponent';
import ViewServiceBenefitsComponent from './components/WhatWeDo/Service/Benefits/ViewServiceBenefitsComponent';
import MediaListComponent from './components/Media/MediaListComponent';
import HealthComponent from './components/HealthLibrary/Health';
import ListFAQCategoryComponent from './components/FAQ/FAQCategory/ListFAQCategoryComponent';
import CreateFAQCategoryComponent from './components/FAQ/FAQCategory/CreateFAQCategoryComponent';
import ViewFAQCategoryComponent from './components/FAQ/FAQCategory/ViewFAQCategoryComponent';
import ListFAQComponent from './components/FAQ/ListFAQComponent';
import CreateFAQComponent from './components/FAQ/CreateFAQComponent';
import ViewFAQComponent from './components/FAQ/ViewFAQComponent';
import MediaDetailComponent from './components/Media/MediaDetailComponent';
import ListCategoryComponent from './components/HealthLibrary/HealthCategory/ListCategoryComponent';
import CreateCategoryComponent from './components/HealthLibrary/HealthCategory/CreateCategoryComponent';
import ViewCategoryComponent from './components/HealthLibrary/HealthCategory/ViewCategory';
import HealthDetailComponent from './components/HealthLibrary/HealthDetailComponent';
import SearchDetailComponent from './components/SearchDetailComponent';
import Corporates from './Pages/Corporates';
import Governments from './Pages/Governments';
import Individuals from './Pages/Individuals';
import My404Component from './Pages/My404Component';
import OurPresenceListComponent from './components/OurPresence/OurPresenceListComponent';
import OurPresenceCreateComponent from './components/OurPresence/OurPresenceCreateComponent';
import OurPresenceInMap from './components/OurPresence/OurPresenceInMap';
import FaqListComponent from './components/FAQ/FaqListComponent';
import httpHelper from './httpHelper';
import { Switch, } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import OurPresenceMapDemo from './Pages/OurPresenceMapDemo';
import TeleMedicinesAbuDhabiEn from './Pages/TeleMedicinesAbuDhabiEn';
import TeleMedicinesAbuDhabiAr from './Pages/TeleMedicinesAbuDhabiAr';
import EntryPointsTeleconsultation from './components/EntryPointsTeleconsultation';
import PatientsRightsResponsibilities from './components/PatientsRightsResponsibilities';
import KSAHomePage from './Pages/KSA/WhyTruDoc';
import hahStaticApp from './Pages/KSA/hah';
//Sandeep Sirohi: Set default country.
var selectedCountry = sessionStorage.getItem("SelectedCountry");
if (!selectedCountry)
    sessionStorage.setItem("SelectedCountry", 'Headquarters');

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props)
        this.state = {
            MediaRoutlist: [],
            HealthRoutlist: [],
            isLoaded: false,

        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        TagManager.initialize({ gtmId: 'GTM-PSXJDJZ' });
        console.log(ReactGA.pageview);
        httpHelper.get('RouteLinkList').then((res) => {
            if (res != undefined) {
                //  debugger;
                this.setState({ MediaRoutlist: res.data.filter(obj => obj.status == 'MediaContent') });
                this.setState({ HealthRoutlist: res.data.filter(obj => obj.status == 'HealthContent') });
                this.setState({ isLoaded: true });

            }
        });
    }


    render() {
        return this.state.isLoaded ? (

            <div>
                <Helmet>
                    <title>TruDoc | Always with You</title>
                    <meta name="description" content="We offer a 24x7 one-stop-care-destination for people who are healthy, acutely, or chronically ill through different entry points that are all connected to our 24x7 Doctor Call Center." />
                    <meta name="theme-color" content="#0778b2" />
                </Helmet>
                {/* {console.log(this.state.modules.mounted)} */}
                <Switch>
                    {/* pluginStore.getAll().mounted.filter(obj => {return obj.data.name === item.component}) */}
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/about' component={AboutUs} />
                    <Route exact path='/integratedmobileapp/:id' component={IntegratedMobileApp} />
                    <Route exact path='/purpose' component={Purpose} />
                    <Route exact path='/nutritioncare' component={NutritionCare} />
                    <Route exact path='/mentalhealthcare' component={MentalHealthCare} />
                    <Route exact path='/milestone' component={Milestone} />
                    <Route exact path='/whytrudoc' component={WhyTruDoc} />
                    <Route exact path='/24x7carelevelmanagement' component={WhatWeDo} />
                    <Route exact path='/patientmanagement' component={PatientManagement} />
                    <Route exact path='/faq' component={FaqListComponent} />
                    <Route exact path='/refundsandcancellation' component={RefundsAndCancellation} />
                    <Route exact path='/corporates' component={Corporates} />
                    <Route exact path='/governments' component={Governments} />
                    <Route exact path='/individuals' component={Individuals} />
                    <Route path='/termsandcondition' component={TermsAndCondition} />
                    <Route path='/privacyandpolicy' component={PrivacyAndPolicy} />
                    <Route exact path='/contactus' component={ContactUsPage} />
                    <Route path='/dashboard' component={Dashboard} ></Route>
                    <Route path='/careeradmin' component={ListCareerComponent}  ></Route>

                    <Route path="/view-career/:id" component={ViewCareerComponent}></Route>
                    <Route path='/contactusadmin' component={ListContactUsComponent} ></Route>
                    <Route path="/add-contactUs/:id" component={CreateContactUsComponent}></Route>
                    <Route path="/view-contactUs/:id" component={ViewContactUsComponent}></Route>
                    <Route path='/testimonials' component={ListTestimonialsComponent} ></Route>
                    <Route path="/add-testimonials/:id" component={CreateTestimonialsComponent}></Route>
                    <Route path="/view-testimonials/:id" component={ViewTestimonialsComponent}></Route>
                    <Route path='/home' component={ListHomeComponent} ></Route>
                    <Route path="/add-home/:id" component={CreateHomeComponent}></Route>
                    <Route path="/view-home/:id" component={ViewHomeComponent}></Route>
                    <Route path='/setting' component={ListSettingComponent} ></Route>
                    <Route path="/add-setting/:id" component={CreateSettingComponent}></Route>
                    <Route path="/view-setting/:id" component={ViewSettingComponent}></Route>
                    <Route path='/service' component={ListServiceComponent} ></Route>
                    <Route path="/add-service/:id" component={CreateServiceComponent}></Route>
                    <Route path="/view-service/:id" component={ViewServiceComponent}></Route>
                    <Route path='/subservice' component={ListSubServiceComponent} ></Route>
                    <Route path="/add-subservice/:id" component={CreateSubServiceComponent}></Route>
                    <Route path="/view-subservice/:id" component={ViewSubServiceComponent}></Route>
                    <Route path='/serviceServices' component={ListServiceServicesComponent} ></Route>
                    <Route path="/add-serviceServices/:id" component={CreateServiceServicesComponent}></Route>
                    <Route path="/view-serviceServices/:id" component={ViewServiceServicesComponent}></Route>
                    <Route path='/serviceFeatures' component={ListServiceFeaturesComponent} ></Route>
                    <Route path="/add-serviceFeatures/:id" component={CreateServiceFeaturesComponent}></Route>
                    <Route path="/view-serviceFeatures/:id" component={ViewServiceFeaturesComponent}></Route>
                    <Route path='/mediaadmin' component={ListMediaComponent} ></Route>
                    <Route path="/add-media/:id" component={CreateMediaComponent}></Route>
                    <Route path="/view-media/:id" component={ViewMediaComponent}></Route>
                    <Route path='/serviceBenefits' component={ListServiceBenefitsComponent} ></Route>
                    <Route path="/add-serviceBenefits/:id" component={CreateServiceBenefitsComponent}></Route>
                    <Route path="/view-serviceBenefits/:id" component={ViewServiceBenefitsComponent}></Route>
                    <Route path='/media' component={MediaListComponent} ></Route>
                    <Route path='/fAQCategory' component={ListFAQCategoryComponent} ></Route>
                    <Route path="/add-fAQCategory/:id" component={CreateFAQCategoryComponent}></Route>
                    <Route path="/view-fAQCategory/:id" component={ViewFAQCategoryComponent}></Route>
                    <Route path='/faqsContent' component={ListFAQComponent} ></Route>
                    <Route path="/add-faqsContent/:id" component={CreateFAQComponent}></Route>
                    <Route path="/view-faqsContent/:id" component={ViewFAQComponent}></Route>
                    <Route path="/healthlibrary" component={HealthComponent}></Route>
                    <Route path='/health' component={ListHealthCategoryComponent} ></Route>
                    <Route path="/add-health/:id" component={CreateHealthCategoryComponent}></Route>
                    <Route path="/view-healthlib/:id" component={ViewHealthCategoryComponent}></Route>
                    <Route path='/healthcategory-list' component={ListCategoryComponent} ></Route>
                    <Route path="/add-healthcategory/:id" component={CreateCategoryComponent}></Route>
                    <Route path="/view-healthcategory/:id" component={ViewCategoryComponent}></Route>
                    <Route path='/career' component={CareerPage} ></Route>
                    <Route path="/Search-detail/:text" component={SearchDetailComponent}></Route>
                    <Route path='/ourpresence-list' component={OurPresenceListComponent} ></Route>
                    <Route path="/add-ourpresence/:id" component={OurPresenceCreateComponent}></Route>
                    <Route exact path='/ourpresence' component={OurPresenceMapDemo} />
                    <Route exact path='/ourpresenceDemo' component={OurPresenceMapDemo} />
                    <Route exact path='/telemedicinesabudhabien' component={TeleMedicinesAbuDhabiEn} />
                    <Route exact path='/telemedicinesabudhabiar' component={TeleMedicinesAbuDhabiAr} />

                    <Route exact path='/ PatientsRightsResponsibilities' component={PatientsRightsResponsibilities}></Route>

                    <Route path='/Ksa/hospital-at-home' component={hahStaticApp} />
                    <Route path='/Ksa/whytrudoc' component={KSAHomePage} />
                    {this.state.MediaRoutlist.map(item => {

                        return <Route exact path={item.routeName} component={MediaDetailComponent} />
                    }
                    )}
                    {this.state.HealthRoutlist.map(item => {
                        return <Route path={item.routeName} component={HealthDetailComponent} />
                    }
                    )}
                    <Route component={My404Component} />
                </Switch>
                {/*{document.body.style.background = '#fff'}*/}
            </div>

        ) : <div className="loading d-flex justify-content-center align-items-center">
            <div class="container d-flex justify-content-center align-items-center" >
                <div class="loader-three"></div>
            </div>
        </div>;
    }
}