import React, { Component } from 'react'
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import httpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateMediaComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            mediaTypeId: '',
            mediaTypes: [],
            title: '',
            description: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            routeName:'',
            errors: {}
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateMedia = this.saveOrUpdateMedia.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }
        if (data.mediaTypeId == "") {
            formIsValid = false;
            errors["mediaTypeId"] = "Cannot be empty";
        }
        if (data.routeName == "") {
            formIsValid = false;
            errors["routeName"] = "Cannot be empty";
        }       
        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListMediaType').then(response => {
                /*console.log(response.data);*/
                this.setState({
                    mediaTypes: response.data
                });
            });
            return
        } else {

            httpHelper.get('GetListMediaType').then(response => {
                /*console.log(response.data);*/
                this.setState({
                    mediaTypes: response.data
                });
            });

            httpHelper.getById('GetMediaById', this.state.id).then((res) => {
                let mediaContents = res.data;
                
                this.setState({
                    mediaTypeId: mediaContents.mediaTypeId,
                    title: mediaContents.title,
                    description: mediaContents.description,
                    content: mediaContents.content,
                    thumbnail: mediaContents.thumbnail,
                    image: mediaContents.image,
                    isVideo: mediaContents.isVideo,
                    videoUrl: mediaContents.videoUrl,
                    routeName: mediaContents.routeName
                });
            });

        }
    }

    saveOrUpdateMedia = (e) => {
        e.preventDefault();
        let mediaContents = {
            mediaTypeId: this.state.mediaTypeId,
            title: this.state.title,
            description: this.state.description,
            content: this.state.content,
            thumbnail: this.state.thumbnail,
            image: this.state.image,
            isVideo: this.state.isVideo,
            videoUrl: this.state.videoUrl,
            routeName: this.state.routeName
        };
        /*console.log('mediaContents => ' + JSON.stringify(mediaContents));*/

        // step 5
        if (this.state.id === '_add') {

            if (this.handleValidation(mediaContents)) {
                httpHelper.create('/AddMedia', mediaContents).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/mediaadmin');
                    /* setInterval(() => { this.props.history.push('/media');}, 1000);*/
                });
            }
        } else {
            if (this.handleValidation(mediaContents)) {
                httpHelper.update('UpdateMedia', mediaContents, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/mediaadmin');
                    /*                    setInterval(() => {this.props.history.push('/media');}, 1000);*/
                });
            }
        }
    }


    changeMediaTypeIdHandler = (event) => {
        this.setState({ mediaTypeId: event.target.value });
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    changeContentHandler = (event) => {
        this.setState({ content: event });
    }
    changeRouteHandler = (event) => {      
        this.setState({ routeName: event.target.value });
    }
    

    //getFiles(files) {
    //    this.setState({ thumbnail: files.base64 })
    //}

    changeThumbnailHandler = (event) => {

        const formData = new FormData();
        const filePath = "Upload\\Media";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);
        try {
            const res = httpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        this.setState({ thumbnail: event.target.files[0].name });

    }
    changeIsVideoHandler = (event) => {
        this.setState({ isVideo: event.target.checked });
    }

    changeVideoUrlHandler = (event) => {
        this.setState({ videoUrl: event.target.value });
    }



    cancel() {
        this.props.history.push('/mediaadmin');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add Media</h3>
        } else {
            return <h3 className="text-center">Update Media</h3>
        }
    }


    render() {
        return (
            <div>

                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Media</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label> Media Type: </label>
                                                                <select className="form-control" name="mediaTypeId" value={this.state.mediaTypeId} onChange={this.changeMediaTypeIdHandler}  >
                                                                    <option>Select Media Type</option>
                                                                    {this.state.mediaTypes.map((e, key) => {
                                                                        return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                    })}
                                                                </select>
                                                                <span style={{ color: "red" }}>{this.state.errors["mediaTypeId"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label> Title: </label>
                                                                <input placeholder="Title" name="title" className="form-control"
                                                                    value={this.state.title} onChange={this.changeTitleHandler} />
                                                                <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label> Content: </label>
                                                                <SunEditor name="content" height="200" placeholder="Content"
                                                                    setContents={this.state.content} defaultValue={this.state.content} onChange={this.changeContentHandler} />
                                                                <span style={{ color: "red" }}>{this.state.errors["content"]}</span>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Page Url: </label>
                                                                <input placeholder="Page Url" name="routeName" className="form-control"
                                                                    value={this.state.routeName} onChange={this.changeRouteHandler} />
                                                                <span style={{ color: "red" }}>{this.state.errors["routeName"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">

                                                            <div className="form-group">
                                                                <label> Description: </label>
                                                                <input placeholder="Description" name="description" className="form-control"
                                                                    value={this.state.description} onChange={this.changeDescriptionHandler} />
                                                                <span style={{ color: "red" }}>{this.state.errors["description"]}</span>
                                                            </div>

                                                            <div className="form-group">
                                                                <img width='500' height='200' src={this.state.thumbnail} alt="" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="file" placeholder="Thumbnail" name="thumbnail" className="form-control"
                                                                    onChange={this.changeThumbnailHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Content: </label>
                                                                <input placeholder="VideoUrl" name="videoUrl" className="form-control"
                                                                    value={this.state.videoUrl} onChange={this.changeVideoUrlHandler} />
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateMedia} style={{ marginTop: "30px" }}>Save</button>
                                                            <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                        </div>
                                                        <div className="col-md-5">
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateMediaComponent