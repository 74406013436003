import React from "react";
import { Header } from '../Header';
import Footer from '../Footer';
import httpHelper from '../../httpHelper';
import { GoogleMap, LoadScript, MarkerClusterer } from "@react-google-maps/api";
import MapMarker from "./MapMarker";
import OurPresenceNewMap from "./OurPresenceNewMap";
import { map } from 'jquery';
import $ from 'jquery';


var URL = process.env.PUBLIC_URL + 'assets/img/map.png';
var URLMapIcon = process.env.PUBLIC_URL + 'assets/img/icon-map.png';



const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    minZoom: 3,
    styles: [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "transit",
            elementType: "all",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        }
    ]
};
const key = "AIzaSyB-PmNeMI8A3sMYeU4-i0QoPKajkAbhPbo"; // PUT GMAP API KEY HERE
const defaultLocation = {
    lat: 37.9755691,
    lng: 23.7361789
};
var objnew = [
    {
        id: 1,
        lat: 37.975,
        lng: 23.7361789
    },
    {
        id: 2,
        lat: 37.9755,
        lng: 23.7361789
    },
    {
        id: 3,
        lat: 37.976,
        lng: 23.7361789
    },
    {
        id: 4,
        lat: 28.612912,
        lng: 77.2295097
    },
    {
        id: 5,
        lat: 26.384255,
        lng: 26.8448353
    }
];

class OurPresenceInMap extends React.Component {
    state = {
        isInfoOpen: false,
        selectedMarkerId: null,
        noOfClusters: null,
        markers: []
    };

    onClick = (isInfoOpen, selectedMarkerId) => {
        this.setState({
            isInfoOpen,
            selectedMarkerId,
            isMarkerShown: false,
        })
    };
    componentDidMount() {
        
        this.delayedShowMarker()
        httpHelper.get('GetListOurPresence').then((res) => {
            var ObjResData = res.data.map(function (el) {
                var o = Object.assign({}, el);
                o.lat = el.latitude;
                return o;
            });
            var finaldata = ObjResData.map(function (el) {
                var o = Object.assign({}, el);
                o.lng = el.longitude;
                return o;
            });
            this.setState({ markers: finaldata });
        });

    }
    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 3000)
    }
    render() {
        const { isInfoOpen, selectedMarkerId } = this.state;

        return (
            <div>

                <Header transparent={false} scrolling={false} whiteheader="#fff" />
                <section className="paddingTopOurPresence">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="heading headingWhiteBold text-white">Our Presence</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<div className="OurPresenceMapBox" style={{ paddingTop: '0px', marginTop:'0px' }} >*/}
                {/*    <img src={process.env.PUBLIC_URL + 'assets/img/OurPresenceMap.svg'} alt="Our Presence Map" title="Our Presence Map" />*/}
                {/*</div>*/}
                <div className="mapPresenseBox">







                    {/*<OurPresenceNewMap />*/}

                    <LoadScript googleMapsApiKey={key} isMarkerShown >
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    height: 700,
                                    display: "flex"
                                }}
                            >
                                <GoogleMap
                                    options={mapOptions}
                                    center={defaultLocation}
                                    zoom={1}

                                    onLoad={this.onMapMounted}
                                    onIdle={this.onMapIdle}
                                    onBoundsChanged={this.onBoundsChanged}
                                    onZoomChanged={this.onZoomChanged}
                                    mapContainerStyle={{ flex: 1 }}

                                >
                                    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
                                        {clusterer =>
                                            this.state.markers.map(markerData => (
                                                <MapMarker
                                                    key={markerData.id}
                                                    clusterer={clusterer}
                                                    markerData={markerData}
                                                    isSelected={markerData.id === selectedMarkerId}
                                                    isInfoOpen={
                                                        markerData.id === selectedMarkerId && isInfoOpen
                                                    }
                                                    onClick={() => this.onClick()}
                                                />
                                            ))
                                        }
                                    </MarkerClusterer>
                                </GoogleMap>
                            </div>
                        </div>
                    </LoadScript>
                </div>
                <Footer />
            </div>


        );
    }
}


const rootElement = document.getElementById("root");
export default OurPresenceInMap