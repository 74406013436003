import React, { Component, memo, useEffect, useState } from 'react'
import HttpHelper from '../../httpHelper';
import { FaSearch } from "react-icons/fa";
import { Header } from '../Header';
import httpHelper from '../../httpHelper';
import PageTitle from "../PageTitle";
import Footer from '../Footer';
import ReactHtmlParser from 'react-html-parser';
import Accordion from 'react-bootstrap/Accordion';

class FaqListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            mediaTypeId: '',
            renView: 1,
            media: [],
            title: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            categories: [],
            articles: [],
            Isloaded:false
        }
    }

    componentDidMount() {
        httpHelper.get('GetListFaqCategory').then((res) => {
            this.setState({ categories: res.data });
        });
        httpHelper.getById('GetFaqsContentByCategoryId', 1).then((res) => {           
            this.setState({ articles: res.data });
            this.setState({ Isloaded: true });
        });
    }
    setView = (id) => {
        httpHelper.getById('GetFaqsContentByCategoryId', id).then((res) => {
            this.setState({ articles: res.data });
        });
        this.setState({ renView: id });

    }
    onClick = () => {
        const search = document.getElementById('txtsearch').value;
        HttpHelper.getBySearch('GetFaqContents',this.state.renView,search).then(res => {        
            this.setState({ articles: res.data });
        })
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const search = document.getElementById('txtsearch').value;
            HttpHelper.getBySearch('GetFaqContents',this.state.renView,search).then(res => {
                this.setState({ articles: res.data });
            })
        }
    }
    handleOnChanges = (event) => {
        const search = document.getElementById('txtsearch').value;
        if (search == '' || search == null) {
            httpHelper.getById('GetFaqsContentByCategoryId', this.state.renView).then((res) => {
                this.setState({ articles: res.data });
            });
        }
    }
    render() {

        return (
            <>
                <Header transparent={false} />
                <PageTitle title="FAQs" bgImage="assets/img/faq.png" />

                <section className="searchFaqSection">
                    <div className="container mt-5">
                        <div class="input-group searchFaqSectionBox">
                            <div class="d-flex searchFaqSectionBoxMain">
                                <input id="txtsearch" onChange={this.handleOnChanges} onKeyPress={this.handleKeyPress} type="search" class="form-control" placeholder='Search' />
                                <button type="button" class="btn btn-search-faq" onClick={() => this.onClick()}>
                                    <FaSearch class='fas fa-search' style={{ color: '#888888' }} />
                                </button>
                            </div>
                            <ul className="navbar navbar-expand navbar-light border-bottom  search-faq-tabs ">
                                {this.state.categories.map((element) => {
                                    return <li class="nav-item d-flex" className={this.state.renView == element.id ? 'active' : ''}>
                                        <a class="nav-link" onClick={() => this.setView(element.id)} >{element.name}</a>
                                    </li>
                                })
                                }

                            </ul>
                        </div>

                       
                        <div className="row">
                            {(this.state.Isloaded) ? <Accordion defaultActiveKey={['0']}>
                                {this.state.articles.map((element, index) => {
                                    return <Accordion.Item eventKey={index} className="accordianFAQ">
                                        <Accordion.Header>{element.question}</Accordion.Header>
                                        <Accordion.Body className="fs-20 text-thm-blue contentPara">
                                            {ReactHtmlParser(element.answer)}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                                <br></br>
                            </Accordion>
                                : null
                            }
                        </div>

                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
export default memo(FaqListComponent);

