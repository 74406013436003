import React, { Component } from 'react';
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListServiceFeaturesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            serviceFeatures: []
        }
        this.addServiceFeatures = this.addServiceFeatures.bind(this);
        this.editServiceFeatures = this.editServiceFeatures.bind(this);
        this.deleteServiceFeatures = this.deleteServiceFeatures.bind(this);
    }

    deleteServiceFeatures(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => httpHelper.delete('DeleteServiceFeatures', id).then(res => {
                        this.setState({ serviceFeatures: this.state.serviceFeatures.filter(serviceFeature => serviceFeature.serviceFeaturesId !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/serviceFeatures');
                        /*setInterval(() => { this.props.history.go('/serviceFeatures'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/serviceFeatures'); }
                }
            ]
        });        
    }
    viewServiceFeatures(id) {
        this.props.history.push(`/view-serviceFeatures/${id}`);
    }
    editServiceFeatures(id) {
        this.props.history.push(`/add-serviceFeatures/${id}`);
    }

    componentDidMount() {
        httpHelper.get('GetListServiceFeatures').then((res) => {
            console.log(res.data);
            this.setState({ serviceFeatures: res.data });
        });
    }

    addServiceFeatures() {
        this.props.history.push('/add-serviceFeatures/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Features</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addServiceFeatures}> Add </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered ">

                                                        <thead>
                                                            <tr>
                                                                <th> Service </th>
                                                                <th> Title </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.serviceFeatures.map(
                                                                    serviceFeature =>
                                                                        <tr key={serviceFeature.serviceFeaturesId}>
                                                                            <td> {serviceFeature.service}</td>
                                                                            <td> {serviceFeature.title} </td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button onClick={() => this.editServiceFeatures(serviceFeature.serviceFeaturesId)} className="btn btn-primary btn-sm">Update </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteServiceFeatures(serviceFeature.serviceFeaturesId)} className="btn btn-danger btn-sm">Delete </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewServiceFeatures(serviceFeature.serviceFeaturesId)} className="btn btn-primary btn-sm">View </button>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListServiceFeaturesComponent