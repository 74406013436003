import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import HomeBanner from "./components/HomeBanner";
import ColoredCardText from "./components/ColoredCardText";
import StatsCard from "./components/StatsCard";
import HighlightWithActions from "./components/HighlightWithAction";
import ProductInfo from "./components/ProductInfo";
import IconCard from "./components/IconCard";
import CustomButton from "./components/CustomButton";
import HighlightWithActions2 from "./components/HighlightWithAction2";
import { Helmet } from "react-helmet";
import { Header } from "../Header";
import Footer from "../Footer";
import "./hah.css";

export function hahStaticApp() {
  return (
    <>
      <Helmet>
        <title>Why TruDoc</title>
        <meta
          name="description"
          content="Traditional health care solutions are known to be provider-centric which not only adds to the financial burden of the patient but proves disadvantageous for the payer too. Focused on offering clinical and episodic symptom care, traditional health care solutions also fall short in providing preventive care and support for long-term health, which if provided can significantly help reduce the unnecessary use of healthcare services."
        />
        <meta name="theme-color" content="#0778b2" />
      </Helmet>
      <Header transparent={false} />
      <div className="parentofall">
        <HomeBanner />
        <section className="hah-impact">
          <div className="impt-content">
            <div className="header">
              <h1>Hospital at Home Impact</h1>
              <h3>Clinical, Financial Outcomes & Patient Satisfaction</h3>
            </div>
            <img
              src={process.env.PUBLIC_URL + "assets/img/hah/hah-lux.png"}
              alt=""
              srcset=""
              className="lux-logo"
            />
          </div>
          <div className={"trudocstats"}>
            <div className={"trudocstats1"}>
              <StatsCard
                title={"~40%"}
                content={"Reduction in Overall Claims Cost"}
              />
              <StatsCard
                title={"~95%"}
                content={"Patient Satisfaction Based on the Program"}
              />
              <StatsCard title={"~35%"} content={"Reduction in ER Visits"} />
              <StatsCard
                title={"~30%"}
                content={"Reduction in Ambulance Utilizations"}
              />
              <StatsCard
                title={"~30%"}
                content={"Reduction in OP GP Consultations"}
              />
              <StatsCard
                title={"~30%"}
                content={"Reduction in Specialist Consultations"}
              />
              <StatsCard title={"~15%"} content={"Reduction in Readmissions"} />
              <StatsCard
                title={"~30%"}
                content={"Reduction in Hospital Bed Days"}
              />
            </div>
          </div>
        </section>
        <section className="conference">
          <div className={"HighlightAction"}>
            <HighlightWithActions
              imageUrl={process.env.PUBLIC_URL + "assets/img/hah/Action.png"}
              text={"Second Annual International"}
              buttonText={"Connect with us"}
              hwabackgroundColor={"white"}
              hwatextColor={"#132A73"}
            />
          </div>
        </section>
        <section className="products-section">
          <h1 className={"HAsubtext"}>
            Home Health <span>Product Suite</span>
          </h1>
          <div className={"prodInfo"}>
            <div>
              <ProductInfo
                url={
                  process.env.PUBLIC_URL + "assets/img/hah/home-location.png"
                }
                title={"Hospital at Home"}
                backgroundclr={"#72DCE2"}
              />
            </div>
            <div>
              <ProductInfo
                url={process.env.PUBLIC_URL + "assets/img/hah/doctor.png"}
                title={"Doctor at Home"}
                backgroundclr={"#72DCE2"}
              />
            </div>
            <div>
              <ProductInfo
                url={process.env.PUBLIC_URL + "assets/img/hah/stethoscope.png"}
                title={"Nursing Care at Home"}
                backgroundclr={"#72DCE2"}
              />
            </div>
            <div>
              <ProductInfo
                url={
                  process.env.PUBLIC_URL + "assets/img/hah/Physiotherapy.png"
                }
                title={"Physiotherapy at Home"}
                backgroundclr={"#72DCE2"}
              />
            </div>
          </div>
        </section>
        <section className="impact-section">
          <div className={"impact"}>
            <div className={"impactinner"}>
              <b className="impct-title">
                <ColoredCardText
                  coloredText={"Impact"}
                  textColor={"#132A73"}
                  fontSize={"60px"}
                />
              </b>
              <div className={"impct-subtitle no-p-margin"}>
                <ColoredCardText
                  coloredText={"Hospital at Home vs. In-hospital Stay"}
                  textColor={"#72DCE2"}
                  fontSize="30px"
                />
              </div>
              <ul className="impct-list">
                <li>Lower Mortality Rates</li>
                <li>Lower Readmission Risk</li>
                <li>Enhanced Length of Treatment</li>
                <li>Lower Risk of Long-Term Care Admission</li>
              </ul>
              <div className={"impct-subtitle"}>
                <ColoredCardText
                  coloredText={"Cost Reduction"}
                  textColor={"#72DCE2"}
                  fontSize="30px"
                />
              </div>
              <div className={"desc"}>
                A meta-analysis of 5 studies assessing several medical
                conditions found that HAH saved an average of around{" "}
                <b>2000$</b> per patient when compared with in-hospital stays
              </div>
            </div>
            <div className={"impactImages"}>
              <IconCard
                imageURL={
                  process.env.PUBLIC_URL + "assets/img/hah/Impactimg1.png"
                }
                title={"Quality of Life"}
                subtitle={
                  "Improved QoL for HaH patients compared to in-hospital stays"
                }
              />
              <IconCard
                imageURL={
                  process.env.PUBLIC_URL + "assets/img/hah/Imapactimg2.png"
                }
                title={"Anxiety & Depression"}
                subtitle={"Anxiety and depression improve more in HaH groups"}
              />
              <IconCard
                imageURL={
                  process.env.PUBLIC_URL + "assets/img/hah/Impactimg3.png"
                }
                title={"Caregiver Stress"}
                subtitle={
                  "Higher caregiver stress at admission decreased at discharge in the HaH group"
                }
              />
              <IconCard
                imageURL={
                  process.env.PUBLIC_URL + "assets/img/hah/Impactimg4.png"
                }
                title={"Patient Satisfaction"}
                subtitle={"Higher patient satisfaction in the HaH group"}
              />
            </div>
          </div>
        </section>
        <section className="comparative-management">
          <div className={"HighlightAction2"}>
            <HighlightWithActions2
              imageUrl={
                process.env.PUBLIC_URL +
                "assets/img/hah/HighlightAction2img.png"
              }
              text={"Second Annual International"}
              buttonText={"Connect with us"}
              hwabackgroundColor={"white"}
              hwatextColor={"#132A73"}
            />
          </div>
        </section>
        <section className="podcasts">
          <div className={"HighlightActionSubtext"}>
            <div className={"HighlightActionSubtextcolored"}>
              <h1>
                Discover Our Leader <span>and Unlock Expert Insights</span>
              </h1>
              <a
                href="https://www.youtube.com/@TruDocHealthcare/videos"
                target="_blank"
                rel="noreferrer"
              >
                <CustomButton backgroundColor={"#132A73"} textColor={"white"}>
                  <b>Watch All Podcasts</b>
                </CustomButton>
              </a>
            </div>
            <div className={"podcastcards"}>
              <a
                href="https://youtu.be/PQgTv-RaV08?si=Bv8Kq4VT6rI7hFPL"
                target="_blank"
                rel="noreferrer"
              >
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 1 (YouTube) </b>
                </div>
              </a>
              <a href="https://youtu.be/f9r1b7OFRa8?si=AlkOs8zFhrke9N1C">
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 2 (YouTube)</b>
                </div>
              </a>
              <a href="https://youtu.be/Ym7-f3FOKX4?si=ZrcixW3Z2GgQhSBC">
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 3 (YouTube)</b>
                </div>
              </a>
              <a
                href="https://open.spotify.com/episode/1YaUpedu4fWBhgfrFnGRvz?si=mgtWrmmvT0OC_Cw-bAgS0g"
                target="_blank"
                rel="noreferrer"
              >
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 1 (Spotify)</b>
                </div>
              </a>
              <a href="https://open.spotify.com/episode/3N7mhizeLXhk7pn1UWhmWL?si=pUDZ5m7zQ5CjYcTapjR2Xw">
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 2 (Spotify)</b>
                </div>
              </a>
              <a href="https://open.spotify.com/episode/4OM3QySMrqDA2S1ps2n8dT?si=B_VtBmAERumANvQmJdxROg">
                <div className={"podcast-card"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/img/hah/podcast-card.png"
                    }
                    alt=""
                  />
                  <b>Episode 3 (Spotify)</b>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className="anytime-anywhere-section">
          <div className={"anytimeanywhere"}>
            <div className={"anytimeanywheretext"}>
              <b>
                <ColoredCardText
                  coloredText={"Connect Consult Care"}
                  textColor={"white"}
                  textfontsize="var(--font-size-anytimeanywhere)"
                />
              </b>
            </div>
            <div className={"anytimeanywheretext"}>
              <b>
                <ColoredCardText
                  coloredText={"Anytime Anywhere "}
                  textColor={"#72DCE2"}
                  textfontsize="var(--font-size-anytimeanywhere)"
                />
              </b>
            </div>
          </div>
        </section>
        <section className="JHAH-section">
          <div className={"anytimeanywhereSubtext"}>
            <div>
              <b className={"JHAH"}>JHAH</b>
              <span className={"alongJHAH"}>the vital partner</span>
            </div>
            <div className={"alongJHAH2"}>In our Journey</div>
          </div>
          <img
            src={process.env.PUBLIC_URL + "assets/img/hah/JHAH-logo.png"}
            alt=""
          />
        </section>
        <hr className="footer-divider" />
        <div className={"social"}>
          <div className="icons">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/trudocgroup"
            >
              <div className={"socialImg"}>
                <FaLinkedin color="white" size="50" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/trudoc"
            >
              <div className={"socialImg"}>
                <FaFacebookF color="white" size="50" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/trudocgroup"
            >
              <div className={"socialImg"}>
                <FaInstagram color="white" size="50" />
              </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://x.com/ksatrudoc">
              <div className={"socialImg"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="52"
                  height="52"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#ffffff"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M5.91992,6l14.66211,21.375l-14.35156,16.625h3.17969l12.57617,-14.57812l10,14.57813h12.01367l-15.31836,-22.33008l13.51758,-15.66992h-3.16992l-11.75391,13.61719l-9.3418,-13.61719zM9.7168,8h7.16406l23.32227,34h-7.16406z"></path>
                    </g>
                  </g>
                </svg>
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@TruDocHealthcare/videos"
            >
              <div className={"socialImg"}>
                <FaYoutube color="white" size="50" />
              </div>
            </a>
          </div>
          <div className={"moh-logo"}>
            <img src={process.env.PUBLIC_URL + "assets/img/hah/MOH.png"} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default hahStaticApp;
