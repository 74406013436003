import React, { memo } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

const WhoWeServeTwoCol = (props) => {
    return (
        <>
            <section id="WhoWeServeTwoCol" style={{ backgroundColor: '#fff' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h2 className="heading">
                                {props.heading}
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <div className="Card">
                                <div className="imgCard">
                                    <img src={process.env.PUBLIC_URL + props.imageOne} alt={props.HeadingOne} title={props.HeadingOne} />
                                </div>
                                <div className="details">
                                    <Link to={props.urlOne}>
                                        <h6 className="detailsHeading">
                                            {props.HeadingOne}
                                        </h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="Card">
                                <div className="imgCard">
                                    <img src={process.env.PUBLIC_URL + props.imageTwo} alt={props.HeadingTwo} title={props.HeadingTwo} />
                                </div>
                                <div className="details">
                                    <Link to={props.urlTwo}>
                                        <h6 className="detailsHeading">
                                            {props.HeadingTwo}
                                        </h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(WhoWeServeTwoCol);