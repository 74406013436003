import React, { memo, Component } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import httpHelper from "../httpHelper";
import ReactHtmlParser from 'react-html-parser';

class Service_WWD extends Component {
    constructor(props) {
        super(props);

        console.log(this.props.service_id);
        this.state = {
            id: this.props.service_id,
            services: []
        }

        httpHelper.getById('GetListSubServicesByServiceId', this.state.id).then(res => {
            this.setState({ services: res.data });
        });
    }



    render() {
        return (
            <>
                <div className="row">
                    {
                        this.state.services.map(
                            services =>
                                <div className="col-md-6 mb-4" key={services.serviceId}>
                                    <div className="services_bg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + services.image})` }} >
                                        <div className="row">

                                            <div className="col-md-8 col-10 spacingData">
                                                <h2 className="text-white content-desc">
                                                    {services.title}

                                                </h2>
                                                <h3 className="text-white">
                                                    {ReactHtmlParser(services.content)}
                                                </h3>
                                            </div>


                                            <div className="col-md-4 col-2 right_arrow">
                                                <Link to={`/${services.subServiceUrl}`}>
                                                    <span>
                                                        <MdOutlineKeyboardArrowRight />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </div>
            </>
        )
    }
}


export default memo(Service_WWD);