import React from 'react';
import { authProvider } from '../../AuthProvider';

function logout() {
    //Sandeep: Logout and redirect to home page based on Post logout redirect setting.
    authProvider.logout();
}

export default function DashboardHeader() {

    return (
        <>
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                    <div id="dheader">
                        <a className="navbar-brand brand-logo" href="#">
                            <img
                                src={process.env.PUBLIC_URL + "assets/img/logo.svg"}
                                alt="Tru Doc"
                                title="Tru Doc"

                            />
                        </a>
                        <a className="navbar-brand brand-logo-mini" href="index.html">
                            <img
                                src={process.env.PUBLIC_URL + "assets/img/logo.svg"}
                                alt="Tru Doc"
                                title="Tru Doc"
                            />
                        </a>
                    </div>
                </div>

                <ul class="navbar-nav ms-auto mx-5">
                    <li class="nav-item">
                        Welcome, {authProvider.getAccount().name} <button className="btn btn-danger btn-sm ml-5 btn-logout " onClick={logout}>Logout</button>
                    </li>
                </ul>
            </nav>
        </>
    )
}
