import React, { memo } from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';

const TermsAndCondition = () => {

    //const titleWebsite = "Database | Xetech";
    //const descriptionWebsite = "Being a specialised software development company, we help our clients with database solutions and extract valuable business insights from their existing database and data to understand their needs better using analysis tools and artificial intelligence.";
    //useEffect(() => {
    //    document.title = titleWebsite;
    //    document.getElementsByTagName("meta")[2].content = descriptionWebsite;
    //    document.description = descriptionWebsite;
    //    window.scrollTo(0, 0);
    //}, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Helmet>
                <title>Terms And Condition</title>
                <meta name="description" content="With TruDoc, individuals can get instant access to 24x7 reliable, convenient, and affordable health care. " />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Terms and Conditions" bgImage="assets/img/terms-bg.png" />
            <PageContent class="WhiteHeader-policy" >
                Our Physician Help Line will provide a physician assessment of your condition. It is not designed to
                replace an Emergency Room for a true emergency.
                <br /><br />
                Please read this page carefully. It contains the terms and conditions (the “Terms and Conditions”) governing your access to and use of the Physician Helpline Service provided by TruDoc Healthcare L.L.C. (“TruDoc 24×7”). “Trudoc 24x7”maintainsthe https://trudoc24x7.com/Website ("Site"). The country of domicile of “Trudoc 24x7” is United Arab Emirates.
                <br /><br />
                If you do not accept these Terms and Conditions or you do not meet or comply with their provisions, you may not use the Physician Helpline Service. By subscribing to/using the Physician Helpline Service, you hereby acknowledge that you are familiar with these terms and any changes or modifications that may occur from time to time. These Terms and Conditions are effective as of April 1, 2012.


                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">1. Binding Agreement.</p>
               
                These Terms and Conditions form a binding agreement (the “Agreement”) between you and TruDoc 24×7. Your access to or use of the Physician Helpline Service indicates your acceptance of these Terms and Conditions. You are agreeing to use the Physician Helpline Service at your own risk. Certain Definitions.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">2. The following definitions apply to this Agreement.</p>
                
                2.1 “Physician Helpline Application” or the “Application” (collectively, the “Applications”) means any
                application under the control of TruDoc 24×7, whether partial or otherwise and includes such
                Application’s Content, Text, Graphics, Design, Programming, Physician Helpline Materials and Services (as applicable in each context).
                <br /><br />
                2.2 “Content” includes all Text, Graphics, Design and Programming used on the Applications.
                <br /><br />
                2.3 “Services” means any services provided by TruDoc 24×7 or its agents described herein.
                 <br /><br />
                2.4 “User” refers to any individual or entity that uses any aspect of the Applications.
                <br /><br />
                2.5 “You” or “you” means the person who (or the entity on behalf of whom such person is acting) is agreeing to these Terms and Conditions.


                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">3. General Use Rules.</p>
                
                The Applications and the Services are intended for individuals seeking personal medical advice. You may use the Applications and Services only for lawful purposes within the stated context of TruDoc 24×7’s intended and acceptable use of the Applications and Services.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">4. Other Specific Rules Regarding Usage.</p>
                
                You represent, warrant and agree that you (a) are at least 18 years of age or older, and if under the age of 18 or the age of majority as that is defined in your jurisdiction, you will only use the Applications and Services under the supervision of a parent, legal guardian, or other responsible adult; and (b) will not use (or plan, encourage or help others to use) the Services and Applications for any other purpose or in any manner that is prohibited by these Terms and Conditions or by applicable law. It is your
                responsibility to ensure that your use of the Applications and Services complies with these Terms and Conditions and all applicable laws.

                 <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">5. Services by Licensed Medical Practitioners.</p>
                
                You acknowledge that the Physician Helpline Services are being provided to You by TruDoc 24x7and their licensed medical practitioners (the “Personnel”). You also acknowledge your awareness that the TruDoc 24x7physicians you speak with are Dubai Healthcare Authority licensed physicians.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">6. Limitations on Damages and Liability Limitations.</p>
                
                6.1. You assume all responsibility and risk for your use of the Physician Helpline Services, the internet and for your conduct on and off of the Applications, and You hereby acknowledge and agree that your use of the Application and the Services is on an “as is” and “as available” basis.
                <br /><br />
                6.2. You represent, warrant and agree that the views and suggestions expressed by TruDoc 24×7 under these Physician Helpline Services shall in no manner be construed by You as an endorsement of any such advice and views by the entity (e.g. your insurance, your employer or any other third party)
                providing TruDoc 24×7 services to you (the “Payer”).
                <br /><br />
                6.3. You represent, warrant and agree that any and all advice provided to You by TruDoc 24×7
                physicians shall depend in entirety on the accuracy of the description of the symptoms provided by You.
                <br /><br />
                6.4. While TruDoc 24×7 agree to maintain the confidentiality of the information provided by You, you acknowledge and agree that the calls made by You during the course of services provided by
                Physician Helpline Services may be recorded for quality control purposes and for the purpose of
                maintaining a record of your health information.
                <br /><br />
                6.5 Your acceptance of and compliance with the advice given by the Personnel shall be at your risk. In no event shall TruDoc 24×7 (or any of their officers, directors, shareholders, employees, subsidiaries, affiliates, agents or advertisers) be liable for any damages whatsoever resulting from or arising under or in connection with the Application or the Services or the use or access to, or the inability to use or access the Applications and/or the Services, whether based on warranty, contract, tort, or any other legal theory, and whether or not TruDoc 24×7 is advised of the possibility of such damages.
                <br /><br />
                6.6. You acknowledge and agree that You are not authorized by TruDoc 24x7to provide medical advice to other persons based upon the advice You obtain from the Physician Helpline Services and that You bear sole responsibility for the consequences of providing such advice to any such other persons.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">7. Quality, Functionality and Connectivity Issues.</p>

                You acknowledge and agree that quality, functionality, connectivity and/or availability of the Physician Helpline Services may be affected from time to time by circumstances within or outside of the control of TruDoc 24x7or the Payer. You also acknowledge and agree that the Payer, without any liability
                whatsoever, shall be entitled to refuse, limit, suspend, vary or disconnect the Physician Helpline
                Services at any time, for any reasonable cause, including, but not limited, to the following.
                <br /><br />
                7.1. any violation of applicable rules, regulations, orders, directions, notifications, conditions of License Agreements, etc., issued by any government/telecom or other regulatory authority.
                <br /><br />
                7.2. during technical failure, modification, up-gradation, variation, relocation, repair and/or maintenance of the systems/equipment.
                <br /><br />
                7.3. to combat potential fraud, sabotage, willful destruction, national security or for any other force majeure reasons.
                <br /><br />
                7.4. transmission limitation caused by topographical, geographical, atmospheric, hydrological and/or mechanical or electronic constraints/limitations and/or availability of suitable cell
                Applications.
                <br /><br />
                7.5. if Physician Helpline Services are used in violation of any law, rule/regulation.
                <br /><br />
                7.7. any other reason which the Payer, in its sole discretion, deems reasonable and which
                warrants limiting, suspension or disconnection of Physician Helpline Service.
                <br /><br />
                7.8. Payer shall not be responsible for any claims or damages arising out of technical failures,
                including failure of a telephone line, failure of servers, or the failure to implement system updates due to technical failure, whether such failure occurs before, during or after the completion of the Physician Helpline Services.
                <br /><br />
                7.9. TruDoc 24x7and its Personnel shall not be responsible for or obligated to provide call-back Physician Helpline Service in the event of a dropped call or any other interruption of service.


                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">8. Accuracy or Completeness of Content.</p>

                You hereby acknowledge and agree that.
                <br /><br />
                8.1 Payer makes no warranties or representations regarding the accuracy or completeness of the
                content provided on or via the Physician Helpline Services and assumes no liability or responsibility for any (i) errors, omissions or inaccuracies of content or submissions, (ii) personal injury or property damage of any nature resulting from your access to and use of the Physician Helpline Services, (iii) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information that may be contained therein, (iv) any deletion of data or interruption or discontinuance of transmission to or from or through the Physician Helpline Services.
                <br /><br />
                8.2 Payer is not responsible for any content or transactions that are contained or may be available outside the Physician Helpline Services.
                
                
                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">9. User Authentication.</p>
                Because user authentication on the Internet is difficult, TruDoc 24×7 cannot and does not confirm that each user is who they claim to be. Because TruDoc 24×7 does not and cannot be involved in
                user-to-user dealings or control the behaviour of participants on the Applications, in the event that you have a dispute with one or more users, you release TruDoc 24×7 from claims, demands and
                damages (actual and consequential and direct and indirect) of every kind and nature, known and
                unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way
                connected with such disputes.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">10. Unauthorised Use.</p>
                Unauthorised use of the Application may give rise to a claim for damages and/or be a criminal offence.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">11. Website Links.</p>
                From time to time this application may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">12. Amendments to this Agreement and Changes to Applications.</p>
                TruDoc 24×7 may revise these Terms and Conditions at any time by updating this page. Changes will be binding on you on the date they are posted on the Applications (or as otherwise stated in the any notice of such changes). Any use of an Application will be considered acceptance by you of the then-current Terms and Conditions (including any exhibits thereto) contained on such Application. If at any time you find the Terms and Conditions unacceptable, you may not use the applicable Application any longer. Any new or different terms supplied by you are specifically rejected by TruDoc 24×7 unless TruDoc 24×7 agrees to them in a signed writing specifically including those new or different terms. TruDoc 24x7may change the Applications at any time.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">13. Indemnity.</p>
                You agree to defend, indemnify, and hold harmless TruDoc 24×7(and its subsidiaries, affiliates, officers, directors, employees and agents) from and against any third party claims, actions or demands
                (including, without limitation, costs, damages and reasonable legal and accounting fees) alleging or resulting from or in connection with your use of the Applications, your use of the Services or your breach of this Agreement. TruDoc 24x7shall use reasonable efforts to provide you prompt notice of any such claim, suit, or proceeding and may assist you, at your expense, in defending any such claim, suit or proceeding.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">14. Applicable Law.</p>
                Any dispute arising out of or in connection with these Terms and the Physician Helpline Services,
                including any question regarding the existence, validity or termination of the same, shall be referred to and finally resolved by arbitration under the arbitration rules of the Dubai International Financial Center, which rules are deemed to be incorporated by reference into this clause. The number of
                arbitrators shall be three. The seat, or legal place, of arbitration shall be Dubai. The language to be used in the arbitration shall be English. These Terms and the Physician Helpline Services shall be
                governed by and construed in accordance with the law of Dubai.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">15. Payment Authorization-Fees and Purchase Terms.</p>
                15.1. By providing a credit card or other payment method accepted by TruDoc 24x7 (“Payment Method”), you are expressly agreeing that TruDoc 24x7 is authorized to charge to the Payment Method any fees for your use of the Services, together with any applicable taxes. TruDoc 24x7 accepts Visa or MasterCard debit and credit cards in AED for payment.
                <br /><br />
                15.2. TruDoc 24x7 shall not trade with or provide any service to OFAC and sanctioned countries.
                <br /><br />
                15.3. You agree to pay all fees or charges to your Account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. By providing TruDoc 24x7 with your credit or debit card number and associated payment information, you agree that TruDoc 24x7 is authorized to immediately invoice your account for all fees and charges due and payable to TruDoc 24x7 hereunder and that no additional notice or consent is required. You shall be required to retain a copy of transaction records and the policy document of TruDoc 24x7. If your health plan, employer or agency has arranged with TruDoc 24x7 to pay the fee or any portion of the fee, or if the fee is pursuant to some other arrangement with TruDoc 24x7, that fee adjustment will be reflected in the fee that you are ultimately charged. Please check with your employer, health plan or insurance provider to
                determine if any Services will be reimbursed.
                <br /><br />

                15.4. Payment for upgrades will deducted from your credit or debit card on a pro rata basis.
                <br /><br />
                15.5. You may pay for your Subscription fee only with credit and debit card payments.
                <br /><br />
                We will charge your credit or debit card for your first Subscription Fee on the date that we process your order for your Subscription. Once your credit or debit card is charged the first Subscription Fee, we will begin provisioning your account.
                <br /><br />
                15.6 Once the payment is made, the confirmation notice will be sent to the client via email within 24 hours of receipt of payment.
                <br /><br />
                15.7. If all payment methods submitted by you are declined for payment of your Subscription Fee, your Subscription will be cancelled. If you provide us with new payment details and you are successfully charged, your Subscription Period will be based on the original renewal date and not the date of the successful charge.
                <br /><br />
                15.8. If you do not have insurance coverage for Services, or if your coverage is denied, you
                acknowledge and agree that you shall be personally responsible for all incurred expenses. TruDoc 24x7 offers no guarantee that you shall receive any such reimbursement. TruDoc 24x7 reserves the right to modify or implement a new pricing structure at any time prior to billing you for your initial payment or for future payments due pursuant to these Terms of Use.
                <br /><br />
                15.9. You shall be solely responsible to maintain the confidentiality of your bank accounts and TruDoc 24x7 shall not be liable for any kind of theft or misuse of the same or any loss or damage arising out of or in relation to the use of your account.











            </PageContent>
            <Footer />
        </>
    )
}

export default memo(TermsAndCondition);