import React, { memo } from "react";
import CallButton from "./CallButton";

const TextWithOutGradientImageFullWidth = (props) => {
    const bgBrush = process.env.PUBLIC_URL + 'assets/img/brush-effect.png';
    return (
        <>
            <section id="TextWithOutGradientImageFullWidth" className="SectionDefaultstyle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})` }} >
                {props.gradient == true ? <div className="BgGradient"></div> : '' }
                {/*<div className="BgGradient"></div>*/}
                <div className="container content">
                    <div className="row">
                        <div className="col-md-7">
                            
                                {props.children}
                            
                            {props.button == true ? <CallButton content="Learn More" url="#" icon="" class="mt-Btn-Section padding-button" /> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(TextWithOutGradientImageFullWidth);