import React, { memo, useEffect } from "react";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import PageContent from "../components/PageContent";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';


const RefundsAndCancellation = () => {
    
    return (
        <>
            <Helmet>
                <title>Refunds And Cancellation</title>
                <meta name="description" content="With TruDoc, individuals can get instant access to 24x7 reliable, convenient, and affordable health care. " />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header />
            <PageTitle
                title="Refunds and Cancellation Policy"
                bgImage="assets/img/refund-bg.png"
            />
            <PageContent class="privacy-h-b WhiteHeader-policy">
                <p className="fs-20 text-thm-blue">Subscription Cancellation</p>
             
                You may cancel your Subscription through your account settings or by sending an email to
                support@trudoc24x7.com. Subscription cancellation may take up to 48 hours to be effective.
                Any Subscription fees charged prior to the effective date of cancellation will not be refunded, in whole or in part. You will not be eligible for a pro-rated refund of any portion of the Subscription Fees paid for any unused days of the Subscription term.
                
                If you cancel your Subscription, you will enjoy your Subscription benefits until the expiration of the
                Subscription term for which you have paid, and your Subscription benefits will expire at the end of the Subscription term.
                
                If you already have paid for a Monthly or Yearly subscription yourself and your employer then provides you with access to the Service as a Corporate Subscription Member, both subscriptions will be active until one is terminated. It is your responsibility to inform us of any duplicate subscriptions by contacting us at support@trudoc24x7. com.
                
                After such termination, TruDoc 24x7 will have no further obligation to provide the Services, except to
                the extent we are obligated to provide you access to your health records or Healthcare Professionals are required to provide you with continuing care under their applicable legal, ethical and professional
                obligations to you.
                
                <p className="fs-20 text-thm-blue">Auto Renewal</p>
                
                We will automatically renew your subscription, depending on your choice of subscription (monthly or yearly) and will notify you on the automatic renewal 7 days prior the date. The annual subscriptions’ automatic renewal is for the same period of time as your original subscription. The monthly
                subscriptions’ automatic renewal is for a month.
                
                We will charge your credit or debit card with the applicable yearly or monthly subscription fee and any sales or similar taxes that may be imposed on your subscription fee payment unless you cancel before midnight on the anniversary date.
                
                <p className="fs-20 text-thm-blue">Refunds on Subscription plans</p>
                We do not entertain any requests for refunds on our healthcare subscription plans. This is irrespective of if you have used any of the services after subscribing for the service or otherwise. There is also no policy of offering prorated refunds based on time elapsed for any subscription plans (monthly or yearly plans).
            </PageContent>
            <Footer />
        </>
    )
}

export default memo(RefundsAndCancellation);
