import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ListSettingComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            settings: []
        }
        this.addSetting = this.addSetting.bind(this);
        this.editSetting = this.editSetting.bind(this);
        this.deleteSetting = this.deleteSetting.bind(this);
    }

    deleteSetting(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => HttpHelper.delete('DeleteSetting', id).then(res => {
                        this.setState({ settings: this.state.settings.filter(setting => setting.settingId !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/setting');
                        /*setInterval(() => { this.props.history.go('/setting'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/setting'); }
                }
            ]
        });
    }
    viewSetting(id) {
        this.props.history.push(`/view-setting/${id}`);
    }
    editSetting(id) {
        this.props.history.push(`/add-setting/${id}`);
    }

    componentDidMount() {
        HttpHelper.get('GetListSetting').then((res) => {
            this.setState({ settings: res.data });
        });
    }

    addSetting() {
        this.props.history.push('/add-setting/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Setting</h4>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-md-10">*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-2">*/}
                                            {/*        <button className="btn btn-primary btn-sm" style={{ marginLeft: "25px" }} onClick={this.addSetting}> Add Setting</button>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <br></br>
                                            <div className="row">
                                                <table className="table table-striped table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th> Name</th>
                                                            <th> Value </th>
                                                            <th> Description </th>
                                                            <th style={{ textAlign: "center" }}> Actions </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.settings.map(
                                                                setting =>
                                                                    <tr key={setting.settingId}>
                                                                        <td> {setting.name} </td>
                                                                        <td> {setting.value}</td>
                                                                        <td> {setting.description}</td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            <button onClick={() => this.editSetting(setting.settingId)} className="btn btn-primary btn-sm">Update </button>
                                                                            {/*<button style={{ marginLeft: "10px" }} onClick={() => this.deleteSetting(setting.settingId)} className="btn btn-danger btn-sm">Delete </button>*/}
                                                                            {/*<button style={{ marginLeft: "10px" }} onClick={() => this.viewSetting(setting.settingId)} className="btn btn-info btn-sm">View </button>*/}
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListSettingComponent