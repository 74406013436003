import React, { Component, memo, useEffect, useState } from 'react'
import HttpHelper from '../../httpHelper';
import { FaSearch, FaRegClock } from "react-icons/fa";
import { Header } from '../Header';
import httpHelper from '../../httpHelper';
import PageTitle from "../PageTitle";
import Footer from '../Footer';
import moment from 'moment'
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';


class Health extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            mediaTypeId: '',
            renView: 1,
            media: [],
            title: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            categories: [],
            articles: [],
            perPage: 12,
            page: 0,
            pages: 0,
        }
    }
    componentDidMount() {       
        httpHelper.get('GetListHealthLibraryCategory').then((res) => {
            this.setState({ categories: res.data });
            
        });

        httpHelper.getById('GetHealthLibraryContentsByCategory', 1).then((res) => {
            this.setState({ articles: res.data });
            this.setState({
                pages: Math.ceil(this.state.articles.length / this.state.perPage)
            });
        });
    }
    setView = (id) => {
        httpHelper.getById('GetHealthLibraryContentsByCategory', id).then((res) => {
            this.setState({ articles: res.data });
        });
        this.setState({ renView: id });

    }
    onClick = () => {
        const search = document.getElementById('txtsearch').value;
        HttpHelper.getBySearch('GetHealthLibraryContents',this.state.renView,search).then(res => {
            this.setState({ articles: res.data });
        })
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const search = document.getElementById('txtsearch').value;
            HttpHelper.getBySearch('GetHealthLibraryContents',this.state.renView,search).then(res => {
                this.setState({ articles: res.data });
            })
        }
    }
    handleOnChanges = (event) => {
        const search = document.getElementById('txtsearch').value;
        if (search == '' || search == null) {
            httpHelper.getById('GetHealthLibraryContentsByCategory', this.state.renView).then((res) => {
                this.setState({ articles: res.data });
            });
        }
    }
    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page })
    }

    render() {
        const { page, perPage, pages, articles } = this.state;
        let items = articles.slice(page * perPage, (page + 1) * perPage);
        let EventHtml = items.map(element => {
            return <div className="col-md-4 mb-5" key={element.url}>

            <div className="my-2">
                <div className="card cardProductList" style={{ borderWidth: 0, borderBottomWidth: 0.5 }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        right: '0',
                    }}>
                    </div>
                        <img src={element.image} className="card-img-top" alt={element.title} title={element.title} height={250} />
                    <div className="card-body">
                        <div className='d-flex' style={{ alignItems: 'center' }}>
                            <FaRegClock style={{ color: '#e0e0e0' }} />
                            <p className="card-text">
                                <small className="text-muted" style={{ marginLeft: 10 }}>{moment(element.createdOn).format('LL')}</small></p></div>
                        <h5 className="card-title titleCardProductList three_line_ellipsis " style={{ color: "#0077b1" }}>{element.title}</h5>
                        <p className="card-text  two_line_ellipsis">{element.description}</p>
                        <Link to={`/${element.routeName}/${element.id}/${this.state.renView}`} style={{ borderRadius: "20px", borderColor: "#b5e9e0" }}
                            className="btn btn-outline-secondary btnListStyle but-health">Read More</Link>
                    </div>
                </div>

            </div>

        </div>
        }) || '';
        return (
            <>
                <Header transparent={false} />
                <PageTitle title="Health Library" bgImage="assets/img/bg-health.png" />
                <section id="HealthLibrary" style={{ backgroundColor: "#e8f3f7" }}>
                    <div className="container">
                        <div className="row">
                            <div className="card-body" >
                                <div className='d-flex'  style={{alignItems: 'center', margin: '35px 0px', marginTop: '50px' }}>
                                    <p className="card-text fs-20 lh-30" >Our health library is a resourceful platform that provides access to high-quality, vetted, up-to-date, and reliable information around a variety of important health-related topics like thyroid, diabetes, and cancer, to name a few.</p>
                                </div>
                                <div className='d-flex' style={{ alignItems: 'center', margin: '35px 0px' }}>
                                    <p className="card-text fs-20 lh-30">The resources in our library will not only add value to your knowledge base but will also help you enhance your lifestyle habits and incorporate ways to live a healthier life.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="container filtersearchPost">

                    <div class="input-group" style={{ margin: '35px 0px', marginTop: '40px', width: '400px' }}>
                        <div class="d-flex">
                        <input id="txtsearch" style={{ width: '322px', height: '40px', borderRadius: '0px' }} onChange={this.handleOnChanges} onKeyPress={this.handleKeyPress} type="search" class="form-control" placeholder='Search articles by keyword' />
                            <button type="button" class="btn" style={{ border: '1px solid #ced4da', borderLeft: 'none', borderRadius: '0px' }} onClick={() => this.onClick()}>
                                <FaSearch class='fas fa-search' style={{ color: '#888888' }} />
                            </button>
                        </div>
                    </div>


                    <ul className="navbar navbar-expand navbar-light border-bottom " style={{ paddingLeft: 0, paddingRight: 0, right: 0, justifyContent: 'space-between', overflow: 'auto', background: 'transparent' }}>
                        {this.state.categories.map((element) => {
                            return <li class="nav-item d-flex" style={{ padding: 0, cursor: 'pointer' }}>
                                <a class="nav-link" style={{ color: this.state.renView == element.id ? '#71d6c3' : '#424345', fontSize: 18, fontWeight: 500 }} onClick={() => this.setView(element.id)} >{element.category}</a>
                            </li>
                        })
                        }
                        
                    </ul>
                    <div className="row">
                    {EventHtml}
                        <ReactPaginate
                            previousLabel={'prev'}
                            nextLabel={'next'}
                            pageCount={pages}
                            onPageChange={this.handlePageClick}
                            containerClassName={'paginationListBox'}
                            activeClassName={'active'}
                        />

                        


                    </div>

                </div>
                <Footer />
            </>
        )
    }
}
export default memo(Health);

