import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class OurPresenceListComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            homes: []
        }
        this.addOurPresence = this.addOurPresence.bind(this);
        this.editOurPresence = this.editOurPresence.bind(this);
        this.deleteOurPresence = this.deleteOurPresence.bind(this);
    }

    deleteOurPresence(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => HttpHelper.delete('DeleteOurPresence', id).then(res => {
                        this.setState({ homes: this.state.homes.filter(home => home.id !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/ourpresence-list');
                        /*setInterval(() => { this.props.history.go('/ourpresence-list'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/ourpresence-list'); }
                }
            ]
        });
    }
    viewOurPresence(id) {
        this.props.history.push(`/add-ourpresence/${id}`);
    }
    editOurPresence(id) {
        this.props.history.push(`/add-ourpresence/${id}`);
    }

    componentDidMount() {
        HttpHelper.get('GetListOurPresence').then((res) => {
            this.setState({ homes: res.data });
        });
    }

    addOurPresence() {
        this.props.history.push('/add-ourpresence/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Our Presence</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addOurPresence}> Add</button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <table className="table table-striped table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th> Country</th>
                                                            <th> Title</th>
                                                            <th> Phone no</th>
                                                            <th> Email</th>
                                                            <th style={{ textAlign: "center" }}> Actions </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.homes.map(
                                                                home =>
                                                                    <tr key={home.id}>
                                                                        <td> {home.country} </td>
                                                                        <td> {home.title} </td>
                                                                        <td> {home.phoneNo} </td>
                                                                        <td>{home.email}</td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            <button onClick={() => this.editOurPresence(home.id)} className="btn btn-primary btn-sm">Update </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.deleteOurPresence(home.id)} className="btn btn-danger btn-sm">Delete </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.viewOurPresence(home.id)} className="btn btn-primary btn-sm">View </button>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default OurPresenceListComponent