import React, { memo } from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import SubscriptionPlans from "../components/SubscriptionPlans";
import SolutionsTile from "../components/SolutionsTile";
import WhoWeServeTwoCol from "../components/WhoWeServeTwoCol";
import CallButton from "../components/CallButton";
import { Helmet } from 'react-helmet';

const Governments = () => {


    return (
        <>
            <Helmet>
                <title>Who We Serve | For Governoments</title>
                <meta name="description" content="With TruDoc, governments can reduce unnecessary hospital admissions" />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Governments" bgImage="assets/img/Goverment-page.png" />
            <PageContent class="bg-white" >
                When a person falls ill, he or she tends to go straight for specialty care, bypassing the primary care level. This is a very common occurrence in the GCC region.
                <br />
                <br />
                The overutilization of healthcare services does not only drive up insurance premiums but also wastes hospital resources on an illness that could have been treated virtually.
                <br />
                <br />
                TruDoc delivers primary healthcare services to ensure that different levels of clinical care are utilized effectively.

            </ PageContent>
            <PageContent class="" >

                <h2 class="headingLgPrimary">
                    With TruDoc, governments can:
                </h2>

                <div class="row">
                    <div className="col-md-6">
                        <ul class="truoc_ul_center">
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Reduce unnecessary hospital admissions</li>
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Improve accessibility and affordability of healthcare services </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul class="truoc_ul_center">

                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Reduce unnecessary emergency room visits</li>
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Reduce hospital length of stay</li>
                        </ul>
                    </div>
                </div>

            </ PageContent>
            
            <section id="SubscriptionPlans">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="headingLgPrimary">Subscription Plans</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="boxContactSubscription">
                                <div className="boxContactSubscriptionDetails">
                                    Customized offering based <br /> on your needs
                                </div>
                                <CallButton icon="" content="Contact Us" url="/contactus" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <WhoWeServeTwoCol heading="Solutions"
                imageOne="assets/img/corporates-gov.png"
                HeadingOne="For Individuals"
                urlOne="/individuals"
                imageTwo="assets/img/corporates-corporates.png"
                HeadingTwo="For Corporates"
                urlTwo="/corporates"
            />
            <Footer />
        </>
    ) 
}

export default memo(Governments);