import React, { Component } from 'react'
import httpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CreateFAQComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            faqCategories: [],
            faqCategoryId: '',
            questions: '',
            answers: '',
            questionArabic: '',
            answerArabic: '',
            mainFaq: true,
            abuDhabiFaq: false,
            errors: {},
            ordinal:''

        }
        this.changeQuestionHandler = this.changeQuestionHandler.bind(this);
        this.saveOrUpdateFAQ = this.saveOrUpdateFAQ.bind(this);
    }
    handleValidation(data) {
        debugger;
        let errors = {};
        let formIsValid = true;

        if (data.faqCategoryId == "") {
            formIsValid = false;
            errors["faqCategoryId"] = "Cannot be empty";
        }

        if (data.questions == "") {
            formIsValid = false;
            errors["question"] = "Cannot be empty";
        }

        if (data.answers == "") {
            formIsValid = false;
            errors["answer"] = "Cannot be empty";
        }
        if (data.ordinal == "" || data.ordinal == undefined) {
            formIsValid = false;
            errors["ordinal"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListDropDownFaqCategory').then(response => {
                /*console.log(response.data);*/
                this.setState({
                    faqCategories: response.data
                });
            });
            return
        } else {
            httpHelper.get('GetListDropDownFaqCategory').then(response => {
                /*console.log(response.data);*/
                this.setState({
                    faqCategories: response.data
                });
            });
            httpHelper.getById('GetFaqsContentById', this.state.id).then((res) => {
                let faqsContent = res.data;
                this.setState({
                    faqCategoryId: faqsContent.faqCategoryId,
                    questions: faqsContent.questions,
                    answers: faqsContent.answers,
                    questionArabic: faqsContent.questionArabic,
                    answerArabic: faqsContent.answerArabic,
                    mainFaq: faqsContent.mainFaq,
                    abuDhabiFaq: faqsContent.abuDhabiFaq,
                    ordinal: faqsContent.ordinal,
                });
            });
        }
    }

    saveOrUpdateFAQ = (e) => {
        e.preventDefault();
        let faqsContent = {
            faqCategoryId: this.state.faqCategoryId,
            questions: this.state.questions,
            answers: this.state.answers,
            mainFaq: this.state.mainFaq ,
            abuDhabiFaq: this.state.abuDhabiFaq ,
            answerArabic: this.state.answerArabic,
            questionArabic: this.state.questionArabic,
            ordinal: parseInt(this.state.ordinal)
        };
        /*console.log('faqsContent => ' + JSON.stringify(faqsContent));*/

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(faqsContent)) {
                httpHelper.create('/AddFaqsContent', faqsContent).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/faqsContent');
                    /*setInterval(() => { this.props.history.push('/faqsContent'); }, 1000);*/
                });
            }

        } else {
            if (this.handleValidation(faqsContent)) {
                httpHelper.update('UpdateFaqsContent', faqsContent, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/faqsContent');
                    /*setInterval(() => { this.props.history.push('/faqsContent'); }, 1000);*/
                });
            }
        }
    }


    changeMainFAQ = (event) => {

        this.setState({ mainFaq: event.target.checked });

    }

    changeabuDhabiFaq = (event) => {

        this.setState({ abuDhabiFaq: event.target.checked });

    }

    changeFAQCategoryHandler = (event) => {

        this.setState({ faqCategoryId: event.target.value });

    }

    changeQuestionHandler = (event) => {

        this.setState({ questions: event.target.value });

    }

    changeAnswerHandler = (event) => {

        this.setState({ answers: event });

    }

    changeArabicQuestionHandler = (event) => {

        this.setState({ questionArabic: event.target.value });
    }

    changeArabicAnswerHandler = (event) => {

        this.setState({ answerArabic: event });
        console.log(event)

    }
    changeOrdinalHandler = (event) => {

        this.setState({ ordinal: event.target.value });

    }



    cancel() {
        this.props.history.push('/faqsContent');
    }

    getQuestion() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add FAQ</h3>
        } else {
            return <h3 className="text-center">Update FAQ</h3>
        }
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">FAQ</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label> Category: </label>
                                                            <select className="form-control" name="faqCategoryId" value={this.state.faqCategoryId} onChange={this.changeFAQCategoryHandler}  >
                                                                <option>Select Category</option>
                                                                {this.state.faqCategories.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["faqCategoryId"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <div class="form-check checkboxFAQAlign">
                                                                <input class="form-check-input" type="checkbox" checked={this.state.mainFaq == true ? 'checked' : ''} id="mainFAQ" onChange={this.changeMainFAQ} />
                                                                <label class="form-check-label" for="flexCheckChecked"> Main FAQ </label>
                                                            </div>
                                                            <div class="form-check checkboxFAQAlign marginleft-4">
                                                                <input class="form-check-input" type="checkbox" checked={this.state.abuDhabiFaq == true ? 'checked' : ''} id="abuDhabiFAQ" onChange={this.changeabuDhabiFaq} />
                                                                <label class="form-check-label" for="flexCheckChecked"> Abu Dhabi FAQ </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Question (English): </label>
                                                            <input placeholder="Question" name="question" className="form-control"
                                                                value={this.state.questions} onChange={this.changeQuestionHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["question"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Answer: </label>
                                                            {/*<input placeholder="Answer" name="answer" className="form-control"*/}
                                                            {/*    value={this.state.answer} onChange={this.changeAnswerHandler} required="required" />*/}
                                                            <SunEditor name="answer" height="200" placeholder="Answer" setOptions={{
                                                                buttonList: [
                                                                    /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    /*'/', //Line break*/
                                                                    ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['fontColor', 'fontSize', 'font'],
                                                                    /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                ],
                                                            }}
                                                                setContents={this.state.answers} defaultValue={this.state.answer} onChange={this.changeAnswerHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["answer"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Question (Arabic): </label>
                                                            <input placeholder="Question" name="question" className="form-control"
                                                                value={this.state.questionArabic} onChange={this.changeArabicQuestionHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["question"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Answer: </label>
                                                            {/*<input placeholder="Answer" name="answer" className="form-control"*/}
                                                            {/*    value={this.state.answer} onChange={this.changeAnswerHandler} required="required" />*/}
                                                            <SunEditor name="answer" height="200" placeholder="Answer" setOptions={{
                                                                buttonList: [
                                                                    /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    /*'/', //Line break*/
                                                                    ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['fontColor', 'fontSize', 'font'],
                                                                    /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                ],
                                                            }}
                                                                setContents={this.state.answerArabic} defaultValue={this.state.answerArabic} onChange={this.changeArabicAnswerHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["answer"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Ordinal : </label>
                                                            <input placeholder="Ordinal" name="Ordinal" className="form-control"
                                                                type="number" value={this.state.ordinal} onChange={this.changeOrdinalHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["ordinal"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateFAQ} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span style={{ color: "red" }}>{this.state.error}</span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateFAQComponent