import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { NavLink } from "reactstrap";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const Footer = () => {
    return (
        <section id='footer'>
            <div className='container bottomBorder'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='logoFooter'>
                            <img
                                src={process.env.PUBLIC_URL + "assets/img/logo-white.png"}
                                alt="TruDoc White Logo"
                                title="TruDoc White Logo"
                                className='img-fluid img' />
                        </div>
                        <a href="tel:8001000225"><button class="callButton mt-3 btn Call-Buttons-On-Footer"><svg className="Call-Buttons-On-Footer" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>  <span className="Call-Buttons-On-Footer">8001000225</span></button></a>
                        {
                            sessionStorage.getItem("SelectedCountry") == "UAE" ?
                                <a href="mailto:info@trudoc.ae"><button class="callButton mt-3 btn Email-Link-In-Footer"> <span className="Email-Link-In-Footer">info@trudoc.ae</span></button></a> :
                                <a href="mailto:info@trudoc.sa"><button class="callButton mt-3 btn Email-Link-In-Footer"> <span className="Email-Link-In-Footer">info@trudoc.sa</span></button></a>
                        }

                        <p className='text-white mt-5 addressTextFooterCom'>
                            6599 Takhasussi St. AlMohammadiyyah, <br /> 12361, Riyadh, KSA
                        </p>
                    </div>
                    <div className='col-md-2'>
                        <ul className='FooterMenuList'>
                            <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/whytrudoc" }}>
                                Why TruDoc
                            </NavLink>
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/about" }}>*/}
                            {/*    About Us*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/contactus" }} to="/">*/}
                            {/*    Who We Serve*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/24x7carelevelmanagement" }}>*/}
                            {/*    What We Do*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/healthlibrary" }}>*/}
                            {/*    Health Library*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/media" }}>*/}
                            {/*    Media*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/career" }}>*/}
                            {/*    Careers*/}
                            {/*</NavLink>*/}
                            {/*<NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/contactus" }}>*/}
                            {/*    Contact Us*/}
                            {/*</NavLink>*/}
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <ul className='FooterMenuList'>
                            <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/privacyandpolicy" }}>
                                Privacy Policy
                            </NavLink>
                            <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/termsandcondition" }}>
                                Terms and Conditions
                            </NavLink>
                            <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/refundsandcancellation" }}>
                                Refunds and Cancellation Policy
                            </NavLink>
                            <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/faq" }} >
                                FAQs
                            </NavLink>
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <h6 className='text-white text-uppercase downloadFooter'>Download</h6>
                        <div className='iconAppBox align-items-start'>
                        <br/>
                            <a href="https://apps.apple.com/us/app/trudoc-ksa/id6444935973" target="_blank" className="App-download-Apple-Header"><img src={process.env.PUBLIC_URL + 'assets/img/apple.png'} className='img-fluid iconApp App-download-Apple-Header' alt="playstore" title="playstore" /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.trudoc.ksa&hl" target="_blank" className="App-download-Google-Header"><img src={process.env.PUBLIC_URL + 'assets/img/play-store.png'} className='img-fluid iconApp App-download-Google-Header' alt="Apple" title="playstore" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-6 text-left">
                        <p className="addressTextFooter pt-2 text-white">
                            Copyright <AiOutlineCopyrightCircle /> 2022 TRUDOC HEALTHCARE L.L.C.
                            <br />
                            All Right Reserved.
                        </p>
                    </div>
                    {/*<div className="col-md-6 d-flex justify-content-md-end socialFooterIcons">*/}

                    {/*    <span>*/}
                    {/*        <a href="https://www.facebook.com/trudoc" target="_blank"><FaFacebookF /></a>*/}
                    {/*    </span>*/}
                    {/*    <span>*/}
                    {/*        <a href="https://www.instagram.com/trudocgroup" target="_blank"><FaInstagram /></a>*/}
                    {/*    </span>*/}
                    {/*    <span>*/}
                    {/*        <a href="https://www.linkedin.com/company/trudoc24x7" target="_blank"><FaLinkedinIn /></a>*/}
                    {/*    </span>*/}
                    {/*    <span>*/}
                    {/*        <a href="https://www.youtube.com/channel/UCbnG_jCp23MvmVI91A89i9Q" target="_blank" > <FaYoutube /></a>*/}
                    {/*    </span>*/}

                    {/*</div>*/}
                </div>
            </div>
        </section>

    )
}


export default memo(Footer);