import React from 'react';

const ProductInfo = ({ url, title, backgroundclr }) => {
  return (
    <div className="product-card" style={{ backgroundColor: backgroundclr }}>
      <img className="product-card-img" src={url} alt={title} />
      <p className="product-card-text">{title}</p>
    </div>
  );
};

export default ProductInfo;
