import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./Util/Scroll";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

/*Sandeep: Remove logging for UAT and Production from entire application.*/
if (process.env.NODE_ENV !== "development")
    console.log = () => { };


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    rootElement);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
