import React, { memo } from "react";


const SubServiceOffered = (props) => {
    return (
        <>
            <section id="SubServiceOffered">
                <div className="">
                    <div className="row align-center">
                        <div className="col-md-6 ">
                            <div className="padd135">
                            <h2 className="text-white mb-4">Other services offered:</h2>
                                <ul className="truoc_ul text-white">
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Sports nutrition</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Child nutrition</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Pre/post-natal nutrition</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Interactive wellness challenges</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Health monitoring on the “TruDoc 24x7” app</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Body composition analysis (onsite screening)</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Onsite workshops and webinars on
                                    nutrition and healthy lifestyle habits</li>
                                    
                                </ul>
                                </div>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + props.right_image1} alt="" className="w-100 h644"/>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default memo(SubServiceOffered);