import React, { memo } from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import WhoWeServeTwoCol from "../components/WhoWeServeTwoCol";
import CallButton from "../components/CallButton";
import { Button } from "reactstrap";
import ContactForm from "../components/ContactForm";
import { Helmet } from 'react-helmet';


const Individuals = () => {

    const priceHandler = () => {
        //SS: Show hide pricing section based on the country selected. Also change the flag.
        var ddlprice = document.getElementById("subscribePanelHeadingSelect");

        if (ddlprice) {
            if (ddlprice.value == "monthly") {
                var price = document.getElementById("priceDefault");
                price.innerHTML = '75';
            }
            else if (ddlprice.value == "quarterly") {
                var price = document.getElementById("priceDefault");
                price.innerHTML = '165';
            }
            else if (ddlprice.value == "yearly") {
                var price = document.getElementById("priceDefault");
                price.innerHTML = '350';
            }
        }
    }
    return (
        <>
            <Helmet>
                <title>Who We Serve | Individuals</title>
                <meta name="description" content="With TruDoc, individuals can get instant access to 24x7 reliable, convenient, and affordable health care. " />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Individuals" bgImage="assets/img/Individual-page.png" />
            <PageContent class="bg-white" >
                Given the fast-paced lives we live, visiting the doctor’s office has become a burden and an inconvenience for many. In fact, most people try to self-diagnose and self-treat rather than take a trip to the doctor’s office. Why?
                <br />
                <br />

                <div className="row mt-4 d-flex justify-content-center align-items-center">
                    <div className="col-md-4 mb-4">
                        <div className="bgTabsCard">
                            Rushed visits
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="bgTabsCard">
                            Long waiting hours
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-4 d-flex justify-content-center align-items-center">
                    <div className="col-md-4 mb-4">
                        <div className="bgTabsCard">
                            Unnecessary tests and medications
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="bgTabsCard">
                            Expensive co-payments and hospital bills
                        </div>
                    </div>
                </div>


            </ PageContent>
            <PageContent class="" >

                <h2 className="headingLgPrimary">
                    With TruDoc, individuals can:
                </h2>

                <div className="row">
                    <div className="col-md-6">
                        <ul className="truoc_ul_center">
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Get instant access to 24x7 reliable, convenient, and affordable health care</li>
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Get lab tests done at home for acute and chronic conditions</li>
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Benefit from customized wellness programs including meal plans and exercise plans provided by accredited dietitians</li>
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Get medications prescribed and delivered </li>

                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="truoc_ul_center">

                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Be rest assured that all medical advice offered is in line with international clinical guidelines</li>
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Get a referral and appointment booking, if required</li>
                            <li className="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" className="li_tick_center" />Benefit from confidential one-on-one psychological and emotional counseling provided by licensed clinical psychologists</li>
                        </ul>
                    </div>
                </div>

            </ PageContent>
            <section id="pricing" className="d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="headingLgPrimary">Subscription Plans</h2>
                        </div>
                        <div className="subscribePlanBox">
                            <div className="subscribePlanBoxWhiteBox">
                                <div className="subscribePlanBoxPanel border-right-subs">
                                    <div className="subscribePanelBoxData">
                                        <h2 className="subscribePanelHeading text-center">Medical Consultation</h2>
                                        <select className="subscribePanelHeadingSelect"
                                            id="subscribePanelHeadingSelect"
                                            onChange={priceHandler}
                                        >
                                            <option value="monthly" >Monthly</option>
                                            <option value="quarterly">Quarterly</option>
                                            <option value="yearly">Yearly</option>
                                        </select>
                                        <h3 className="subHeading">AED <b id="priceDefault" >75</b></h3>
                                        <ul className="subHeadingList">
                                            <li> <img src={process.env.PUBLIC_URL + 'assets/img/tick-theme-two.png'} alt="Tick" title="tick" /> Unlimited 24x7 access to doctor consultations </li>
                                            <li><img src={process.env.PUBLIC_URL + 'assets/img/tick-theme-two.png'} alt="Tick" title="tick" /> Medication delivery, where available</li>
                                            <li><img src={process.env.PUBLIC_URL + 'assets/img/tick-theme-two.png'} alt="Tick" title="tick" /> Referrals and appointment booking, if required</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="subscribePlanBoxPanel">
                                    <div className="subscribePanelBoxData border-bottom">
                                        <div className="btnSubscribeAddone">
                                            Add ons
                                        </div>
                                        <h2 className="subscribePanelHeading text-center">Nutritional Care</h2>
                                        <h3 className="subHeading">AED <b>75</b></h3>
                                        <span class="tagLineSeubscribe">Per Session</span>
                                        <ul className="subHeadingList no-border">
                                            <li> <img src={process.env.PUBLIC_URL + 'assets/img/tick-theme-two.png'} alt="Tick" title="tick" /> Nutritional counseling session with a dietitian</li>
                                        </ul>
                                    </div>
                                    <div className="subscribePanelBoxData">
                                        <h2 className="subscribePanelHeading text-center">Mental Health Care</h2>
                                        <h3 className="subHeading">AED <b>200</b></h3>
                                        <span class="tagLineSeubscribe">Per Session</span>
                                        <ul className="subHeadingList no-border">
                                            <li> <img src={process.env.PUBLIC_URL + 'assets/img/tick-theme-two.png'} alt="Tick" title="tick" /> Psychological counseling session with a psychologist</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="subscribePlanBoxButton">
                                <CallButton LinkTag="No" icon=""  content="Sign up through our TruDoc app" target="#" />
                            </div>
                            <div className="DownloadIndividualsButton">
                                <div className="AppButtonIconDown">
                                    <a href="https://apps.apple.com/us/app/you-click-we-care/id1015588412" alt="Apple App Download button" title="Apple App Download button" >
                                        <img src={process.env.PUBLIC_URL + 'assets/img/apple-store-icon-download.png'} />                                    </a>
                                </div>
                                <div className="AppButtonIconDown">
                                    <a href="https://play.google.com/store/apps/details?id=com.youclick.wecare" alt="Google Play App Download button" title="Google Play App Download button" >
                                        <img src={process.env.PUBLIC_URL + 'assets/img/google-play-store-icon-download.png'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="SubscriptionPlans" className="d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="headingLgPrimary">Subscription Plans</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="boxContactSubscription">
                                <div className="boxContactSubscriptionDetails">
                                    Customized offering based <br /> on your needs
                                </div>
                                <CallButton icon="" content="Contact Us" url="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ContactForm ContainerId="ContactFormIndividuals" colorType="darkForm" />
            <WhoWeServeTwoCol heading="Solutions"
                imageTwo="assets/img/corporates-Individuals.png"
                HeadingTwo="For Governments"
                urlTwo="/governments"
                imageOne="assets/img/corporates-corporates.png"
                HeadingOne="For Corporates"
                urlOne="/corporates"
            />
            <Footer />
        </>
    )
}

export default memo(Individuals);
