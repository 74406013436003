import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateContactUsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',
            email: '',
            /*fromEmail: '',*/
            phoneNumber: '',
            subject: '',
            message: '',
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.saveOrUpdateContactUs = this.saveOrUpdateContactUs.bind(this);
    }

    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            HttpHelper.getById('GetContactUsById', this.state.id).then((res) => {
                let contactUs = res.data;
                this.setState({
                    name: contactUs.name,
                    email: contactUs.email,
                    /*fromEmail: contactUs.fromEmail,*/
                    phoneNumber: contactUs.phoneNumber,
                    subject: contactUs.subject,
                    message: contactUs.message
                });
            });
        }
    }

    saveOrUpdateContactUs = (e) => {
        e.preventDefault();
        let contactUs = {
            name: this.state.name,
            email: this.state.email,
            /*fromEmail: this.state.fromEmail,*/
            phoneNumber: this.state.phoneNumber,
            subject: this.state.subject,
            message: this.state.message
        };
        /*console.log('contactUs => ' + JSON.stringify(contactUs));*/

        // step 5
        if (this.state.id === '_add') {
            HttpHelper.create('/PostContactUs', contactUs).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/contactUs');
                /*setInterval(() => { this.props.history.push('/contactUs'); }, 1000);*/
            });

        } else {
            HttpHelper.update('UpdateContactUs', contactUs, this.state.id).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/contactUs');
                /*setInterval(() => { this.props.history.push('/contactUs'); }, 1000);*/
            });
        }
    }

    changeNameHandler = (event) => {

        this.setState({ name: event.target.value });

    }

    changeEmailHandler = (event) => {

        this.setState({ email: event.target.value });

    }

    //changeFromEmailHandler = (event) => {
    //    this.setState({ fromEmail: event.target.value });
    //}

    changePhoneNumberHandler = (event) => {
        this.setState({ phoneNumber: event.target.value });
    }

    changeSubjectHandler = (event) => {
        this.setState({ subject: event.target.value });
    }

    changeMessageHandler = (event) => {
        this.setState({ message: event.target.value });
    }

    cancel() {
        this.props.history.push('/contactUs');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add ContactUs</h3>
        } else {
            return <h3 className="text-center">Update ContactUs</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Contact</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control"
                                                                value={this.state.name} onChange={this.changeNameHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email: </label>
                                                            <input placeholder="Email Address" name="email" className="form-control"
                                                                value={this.state.email} onChange={this.changeEmailHandler} />
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-6">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label>From Email: </label>*/}
                                                    {/*        <input placeholder="From Email Address" name="fromEmail" className="form-control"*/}
                                                    {/*            value={this.state.fromEmail} onChange={this.changeFromEmailHandler} />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Phone Number: </label>
                                                            <input placeholder="Phone Number" name="phoneNumber" className="form-control"
                                                                value={this.state.phoneNumber} onChange={this.changePhoneNumberHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Subject: </label>
                                                            <input placeholder="Subject" name="subject" className="form-control"
                                                                value={this.state.subject} onChange={this.changeSubjectHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Message: </label>
                                                            <textarea className="form-control" name="message" placeholder="Message"
                                                                value={this.state.message} onChange={this.changeMessageHandler} style={{ maxHeight: '90px', minHeight: '90px', resize: 'none', padding: '9px', boxSizing: 'border-box', fontSize: '15px' }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateContactUs} style={{ marginTop: "30px" }}>Send</button>
                                                        {/*<button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>*/}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span style={{ color: "red" }}>{this.state.error}</span>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateContactUsComponent