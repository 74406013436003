import React from 'react';

const IconCard = ({ imageURL, title, subtitle }) => {
  return (
    <div className="icon-card">
      <img src={imageURL} alt={title} className="icon-card-image" />
      <div className="icon-card-content">
        <h2 className="icon-card-title">{title}</h2>
        <p className="icon-card-subtitle">{subtitle}</p>
      </div>
    </div>
  );
};

export default IconCard;
