import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';


class ViewServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            services: {}
        }
    }

    componentDidMount() {        
        httpHelper.getById('GetServicesById', this.state.id).then(res => {
            this.setState({ services: res.data });
        })
    }

    cancel() {
        this.props.history.push('/service');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.services.title} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="description" className="form-control"
                                                                value={this.state.services.description} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <input placeholder="Content" name="content" className="form-control" readOnly={true}
                                                                value={this.state.services.content} />
                                                        </div>
                                                    </div>                                                                                                        
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Image: </label>
                                                            <img width='500' height='200' src={this.state.services.image} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{ marginTop: "25px" }}>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isActive" className="form-check-input"
                                                                    checked={this.state.services.isVideo} disabled={true} />
                                                                IsActive
                                                                <i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Thumbnail: </label>
                                                            <input type="text" placeholder="VideoUrl" name="videoUrl" className="form-control"
                                                                readOnly={true} value={this.state.services.videoUrl} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewServiceComponent