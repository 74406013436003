import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
/*import flagIcon from '../assets/icons/uae-flag.png';*/

import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { BsSearch } from "react-icons/bs";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';


var pageurl = "https://localhost:44417";

var pageUrlMuenuTag = window.location.pathname;


//SS: Check environment.
if (process.env.NODE_ENV !== "development")
    pageurl = `${window.location.origin}`

export class Header extends Component {
    static displayName = Header.name;
    constructor(props) {
        super(props);
        this.searchRef = React.createRef();
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleMenuNew = this.toggleMenuNew.bind(this);
        this.state = {
            menushowNew: false,
            collapsed: false,
            selectedCountry: sessionStorage.getItem("SelectedCountry"),
            flagselect: 'assets/img/uae-flag.png',
            options: [
                'one', 'two', 'three'
            ],
            redirect: null
        };
        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false
        };

        this.toggleMenuOne = this.toggleMenuOne.bind(this);
        this.toggleMenuTwo = this.toggleMenuTwo.bind(this);
        this.toggleMenuThree = this.toggleMenuThree.bind(this);
        this.countryHandler = this.countryHandler.bind(this);

        this.state = {
            subMenuOne: false,
            subMenuTwo: false,
            subMenuThree: false
        };
        this.headerShown = this.headerShown.bind(this);
        this.state = {
            searchShown: false
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            scrolling: false
        }
    }

    toggleNavbar() {
        var menu = document.getElementById("menu-control");
        if (menu.classList.contains("d-none")) {
            menu.classList.remove("d-none")
            menu.classList.add("show")
        }
        else {
            menu.classList.remove("show")
            menu.classList.add("d-none")
        }

    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    toggleMenuOne() {
        this.setState(prevState => ({
            subMenuOne: !prevState.subMenuOne
        }));
    }

    toggleMenuTwo() {
        this.setState(prevState => ({
            subMenuTwo: !prevState.subMenuTwo
        }));
    }
    toggleMenuThree() {
        this.setState(prevState => ({
            subMenuThree: !prevState.subMenuThree
        }));
    }

    headerShown() {
        this.setState(prevState => ({
            searchShown: !prevState.searchShown
        }));
        var a = document.getElementById('txtSearch');
        a.focus();
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            var searchpageurl = pageurl + '/Search-detail/' + document.getElementById('txtSearch').value;
            window.location.href = searchpageurl;
        }
    }


    toggleMenuNew() {
        var menu = document.getElementById("menu-control");
        if (menu.classList.contains("d-nonemenures")) {
            menu.classList.remove("d-nonemenures")
            menu.classList.add("show")
        }
        else {
            menu.classList.remove("show")
            menu.classList.add("d-nonemenures")
        }
    }

    componentDidMount() {
        //document.getElementById('sel').value = sessionStorage.getItem("SelectedCountry");
        this.setState({ selectedCountry: sessionStorage.getItem("SelectedCountry") });
        window.addEventListener('scroll', this.handleScroll);
        if (sessionStorage.getItem("SelectedCountry") == "UAE") {
            var image = document.getElementById("imgCountry");
            image.src = process.env.PUBLIC_URL + "assets/img/uae-flag.png";

            var pricingSection = document.getElementById("pricing");
            if (pricingSection)
                pricingSection.classList.remove("d-none")
        } else if (sessionStorage.getItem("SelectedCountry") == "KSA") {
            var image = document.getElementById("imgCountry");
            image.src = process.env.PUBLIC_URL + "assets/img/ksa-flag.png";
            window.setTimeout(function () {
                console.log(window.location.href)
                window.location.href = 'Ksa/whytrudoc';
            }, 2000);
            var pricingSection = document.getElementById("pricing");
            if (pricingSection)
                pricingSection.classList.remove("d-none")
        }
        else {
            var image = document.getElementById("imgCountry");
            image.src = process.env.PUBLIC_URL + "assets/img/globe.svg";

            var pricingSection = document.getElementById("pricing");
            if (pricingSection)
                pricingSection.classList.add("d-none")
        }



    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        if (window.scrollY === 0 && this.state.scrolling === true) {
            this.setState({ scrolling: false });
        }
        else if (window.scrollY !== 0 && this.state.scrolling !== true) {
            this.setState({ scrolling: true });
        }
    }

    countryHandler(e) {
        //SS: Show hide pricing section based on the country selected. Also change the flag.
        var ddlcountry = document.getElementById("ddlCountry");

        if (ddlcountry) {

            sessionStorage.setItem("SelectedCountry", ddlcountry.value);
            if (ddlcountry.value == "UAE") {
                this.setState({ selectedCountry: ddlcountry.value });
                var image = document.getElementById("imgCountry");
                image.src = process.env.PUBLIC_URL + "assets/img/uae-flag.png";

                var pricingSection = document.getElementById("pricing");
                if (pricingSection)
                    pricingSection.classList.remove("d-none")
            }
            else {
                this.setState({ selectedCountry: ddlcountry.value });
                var image = document.getElementById("imgCountry");
                image.src = process.env.PUBLIC_URL + "assets/img/globe.svg";

                var pricingSection = document.getElementById("pricing");
                if (pricingSection)
                    pricingSection.classList.add("d-none")
            }
            window.setTimeout(function () {
                window.location.href = window.location.href;
            }, 2000);

        }

    }

    render() {
        

        return (
            <header id="Header" className={this.props.transparent == true ? 'transparent' : 'whiteheader', this.props.ourMapResence == true ? 'ourMapResenceNewClass' : 'transparent'} >
                <Navbar
                    style={{ backgroundColor: this.state.scrolling == false ? 'transparent' : '#fff' }}
                    className="navbar-expand-xl menuResPonsiveMedia"
                    fixed="top"
                    light
                >
                    <div className="container-fluid px-md-5 headerMobile ">
                        <NavbarBrand className="logoHeader" tag={Link} onClick={() => { window.location.href = "/" }}>
                            <img
                                src={process.env.PUBLIC_URL + "assets/img/logo.svg"}
                                alt="Logo"
                            />
                        </NavbarBrand>
                        <NavbarToggler

                            onClick={this.toggleMenuNew}
                            className="mr-2" />
                        <Collapse id="menu-control"
                            className="d-sm-inline-flex d-nonemenures flex-sm-row-reverse menu-lg-screen-res"

                            navbar
                        >
                            <ul className="navbar-nav flex-grow menuHeader SelectedMnuHightLight ">
                                <NavItem>
                                    <NavLink tag={Link}
                                        className={`text-dark importantColor ${pageUrlMuenuTag == '/whytrudoc' ? 'activeMenuHighLight' : ''}`}
                                        onClick={() => { window.location.href = "/whytrudoc" }}>
                                        Why TruDoc
                                    </NavLink>
                                </NavItem>
                                <Dropdown
                                    className="subMenuHeader"
                                    isOpen={this.state.subMenuOne} toggle={this.toggleMenuOne}>
                                    <DropdownToggle
                                        className={`text-dark subMenuHeader importantColor ${pageUrlMuenuTag == '/about' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/purpose' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/milestone' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/ourpresence' ? 'activeMenuHighLight' : ''}`}
                                        caret>About Us</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/about" }}>Our Story</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/purpose" }}>Our Purpose, Vision and Mission</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/milestone" }}>Our Milestones</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/ourpresence" }}>Our Presence</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown className="subMenuHeader" isOpen={this.state.subMenuTwo} toggle={this.toggleMenuTwo}>
                                    <DropdownToggle
                                        className={`text-dark subMenuHeader importantColor ${pageUrlMuenuTag == '/individuals' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/corporates' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/governments' ? 'activeMenuHighLight' : ''}`}
                                        caret>Who We Serve</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/individuals" }}>For Individuals</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/corporates" }}>For Corporates</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/governments" }}>For Governments</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown className="subMenuHeader" isOpen={this.state.subMenuThree} toggle={this.toggleMenuThree}>
                                    <DropdownToggle
                                        className={`text-dark subMenuHeader importantColor ${pageUrlMuenuTag == '/telemedicinesabudhabien' ? 'activeMenuHighLight' : pageUrlMuenuTag == '/telemedicinesabudhabiar' ? 'activeMenuHighLight' : ''}`}
                                        caret>What We Do</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/24x7carelevelmanagement" }}>24x7 Care Level Management</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/telemedicinesabudhabien" }}>Telemedicine for Abu Dhabi</DropdownItem>
                                        <DropdownItem tag={Link} className="" onClick={() => { window.location.href = "/telemedicinesabudhabiar" }}>الطب عن بعد في أبوظبي</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <NavItem>
                                    <NavLink tag={Link}
                                        className={`text-dark importantColor ${pageUrlMuenuTag == '/healthlibrary' ? 'activeMenuHighLight' : ''}`}
                                         onClick={() => { window.location.href = "/healthlibrary" }}>
                                        Health Library
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link}
                                        className={`text-dark importantColor ${pageUrlMuenuTag == '/media' ? 'activeMenuHighLight' : ''}`}
                                        onClick={() => { window.location.href = "/media" }}>
                                        Media
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link}
                                        className={`text-dark importantColor ${pageUrlMuenuTag == '/contactus' ? 'activeMenuHighLight' : ''}`}
                                        onClick={() => { window.location.href = "/contactus" }}>
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                            </ul>
                            <div className="menuSubHeader">
                                <NavItem className="searchBarInput">
                                    <BsSearch className="iconHeaderSerch" id="iconHeaderSerchID" onClick={this.headerShown}  />
                                    <input type="text" id="txtSearch" ref={(ip) => this.searchRef = ip} className={!this.state.searchShown ? 'd-none' : 'searchInput'} onKeyPress={this.handleKeyPress} />
                                    <input type="button" id="btnDetailSearch" className="d-none" onKeyPress={this.handleKeyPress} />
                                </NavItem>
                                <div className="flagSelectBox">
                                    <span className="flagSelect"><img id="imgCountry" src={process.env.PUBLIC_URL + 'assets/img/globe.svg'} alt="Globe" alt="Title" /></span>
                                    <select id="ddlCountry"
                                        value={this.state.selectedCountry}
                                        onChange={this.countryHandler}>
                                        <option value="Headquarters" > Headquarters</option>
                                        <option value="UAE">UAE</option>
                                        <option value="KSA">KSA</option>
                                    </select>
                                </div>
                                <NavItem className="menuHeaderPhoneAndApp">
                                    <a href="tel:800878362"><button class="callButtonThemeColor callButtontopHeader undefined btn ">
                                        {/*<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z">*/}
                                        {/*    </path>*/}
                                        {/*</svg>*/}
                                        <img src={process.env.PUBLIC_URL + 'assets/img/call-new.svg'} className="imgCallUsHeader Call-Buttons-On-Header" alt="Call Us Button"  title="Call Us Button" />
                                    </button></a>

                                    {/*{isIOS ?*/}
                                    {/*    <a href="https://apps.apple.com/us/app/you-click-we-care/id1015588412" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/img/newDownloadButton.png'} className="HeaderImagedownloadApp" alt="App download Button" title="App download Button" /></a>*/}
                                    {/*    :*/}
                                    {/*    <a href="https://play.google.com/store/apps/details?id=com.youclick.wecare" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/img/newDownloadButton.png'} className="HeaderImagedownloadApp" alt="App download Button" title="App download Button" /></a>*/}

                                    {/*}*/}
                                    <div className="appandplaystore">
                                        <a href="https://apps.apple.com/us/app/you-click-we-care/id1015588412" target="_blank" className="ml-2-app App-download-Apple-Header"><img src={process.env.PUBLIC_URL + 'assets/img/apple-grey-logo.png'} alt="App Store" title="App Store" className="App-download-Apple-Header" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.youclick.wecare" target="_blank" className="App-download-Google-Header" ><img src={process.env.PUBLIC_URL + 'assets/img/google-play.png'} alt="Play Store" title="Play Store" className="App-download-Google-Header" /></a>
                                    </div>
                                </NavItem>
                            </div>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }
}
