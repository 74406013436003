import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ListTestimonialsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            testimonialss: []
        }
        this.addTestimonials = this.addTestimonials.bind(this);
        this.editTestimonials = this.editTestimonials.bind(this);
        this.deleteTestimonials = this.deleteTestimonials.bind(this);
    }

    deleteTestimonials(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => HttpHelper.delete('DeleteTestimonials', id).then(res => {
                        this.setState({ testimonialss: this.state.testimonialss.filter(testimonials => testimonials.id !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/testimonials');
                        /*setInterval(() => { this.props.history.go('/testimonials'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/testimonials'); }
                }
            ]
        });        
    }
    viewTestimonials(id) {
        this.props.history.push(`/view-testimonials/${id}`);
    }
    editTestimonials(id) {
        this.props.history.push(`/add-testimonials/${id}`);
    }

    componentDidMount() {
        HttpHelper.get('GetListTestimonials').then((res) => {
            this.setState({ testimonialss: res.data });
        });
    }

    addTestimonials() {
        this.props.history.push('/add-testimonials/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Testimonials</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addTestimonials}> Add </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row table-responsive">
                                                <table className="table table-striped table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th> Title </th>                                                            
                                                            <th> Name </th>
                                                            <th> Age </th>
                                                            <th> Active </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.testimonialss.map(
                                                                testimonials =>
                                                                    <tr key={testimonials.id}>
                                                                        <td> {testimonials.title} </td>                                                                       
                                                                        <td> {testimonials.name}</td>
                                                                        <td> {testimonials.age}</td>
                                                                        <td> {testimonials.isActive === true ? 'Yes' : 'No'}</td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            <button onClick={() => this.editTestimonials(testimonials.id)} className="btn btn-primary btn-sm">Update </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.deleteTestimonials(testimonials.id)} className="btn btn-danger btn-sm">Delete </button>
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.viewTestimonials(testimonials.id)} className="btn btn-primary btn-sm">View </button>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListTestimonialsComponent