import React from "react";
import CustomButton from "./CustomButton";

const HighlightWithAction = ({
  imageUrl,
  text,
  buttonText,
  onButtonClick,
  hwabackgroundColor,
  hwatextColor,
}) => {
  return (
    <div className="highlight-card">
      <img
        src={imageUrl}
        alt="Highlight card"
      />
      <div className="text-content">
        <div>
          <div className="main-text">Second Annual International</div>
          <div className="highlight-text">Home Healthcare Conference</div>
        </div>
        <div className="main-text-big">
          2025 <span className="highlight-text-big">Riyadh</span>
        </div>
        <div className="main-text-smal">Join the Revolution in Healthcare</div>
        <a href="https://forms.office.com/r/Wf3tCCB8mw" target="_blank" rel="noreferrer">
          <CustomButton textColor={hwatextColor} backgroundColor={hwabackgroundColor}>
            <b>CONNECT WITH US</b>
          </CustomButton>
        </a>
      </div>
    </div>
  );
};

export default HighlightWithAction;
