import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ListContactUsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contactUss: []
        }
        this.addContactUs = this.addContactUs.bind(this);
        this.editContactUs = this.editContactUs.bind(this);
        this.deleteContactUs = this.deleteContactUs.bind(this);
    }

    deleteContactUs(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => HttpHelper.delete('DeleteContactUs', id).then(res => {
                        this.setState({ contactUss: this.state.contactUss.filter(contactUs => contactUs.id !== id) });
                        toast("Record delete successfully!");
                        this.props.history.go('/contactUs');
                        /*setInterval(() => { this.props.history.go('/contactUs'); }, 1000);*/

                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.go('/contactUs'); }
                }
            ]
        });
    }
    viewContactUs(id) {
        this.props.history.push(`/view-contactUs/${id}`);
    }
    editContactUs(id) {
        this.props.history.push(`/add-contactUs/${id}`);
    }

    componentDidMount() {
        HttpHelper.get('GetListContactUs').then((res) => {
            this.setState({ contactUss: res.data });
        });
    }

    addContactUs() {
        this.props.history.push('/add-contactUs/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Contact</h4>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-md-10">*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-2">*/}
                                            {/*        <button className="btn btn-primary btn-sm" style={{ marginLeft: "25px" }} onClick={this.addContactUs}> Add ContactUs</button>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <br></br>
                                            <div className="row">
                                                <table className="table table-striped table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th> Name</th>
                                                            <th> Email </th>
                                                            <th> Phone Number </th>
                                                            <th> Subject </th>
                                                            <th style={{ textAlign: "center" }}> Actions </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.contactUss.map(
                                                                contactUs =>
                                                                    <tr key={contactUs.id}>
                                                                        <td> {contactUs.name} </td>
                                                                        <td> {contactUs.email}</td>
                                                                        <td> {contactUs.phoneNumber}</td>
                                                                        <td> {contactUs.subject}</td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            {/*<button onClick={() => this.editContactUs(contactUs.id)} className="btn btn-info btn-sm">Update </button>*/}
                                                                            {/*<button style={{ marginleft: "10px" }} onclick={() => this.deleteContactus(contactUs.id)} className="btn btn-danger btn-sm">delete </button>*/}
                                                                            <button style={{ marginLeft: "10px" }} onClick={() => this.viewContactUs(contactUs.id)} className="btn btn-primary btn-sm">View </button>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListContactUsComponent