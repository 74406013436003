import React, { memo, Component } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import httpHelper from '../httpHelper';


class WhoWeServe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: []
        }
        httpHelper.get('GetListServices').then((res) => {
            this.setState({ services: res.data });
        });

    }

    componentDidMount() {

    }


    render() {
        return (
            <>
                <section id="WhoweServe" style={{ boxShadow: this.props.Bordershadow === true ? ' 0 0 11px #80a2ae69' : '' }} >
                    <div className="container">
                        <div className="row">
                            {
                                this.state.services.map(
                                    service =>
                                        <div className="col-md-6 mb-4" key={service.serviceId}>
                                            <div className="services_bg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + service.image})` }} >
                                                <div className="overlayServicesCard">
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-10 col-8 spacingData">
                                                        <h2 className="text-white">
                                                            {service.title}
                                                        </h2>
                                                        <h3 className="text-white">
                                                            {service.description}
                                                        </h3>
                                                    </div>
                                                    <div className="col-md-2 col-2 right_arrow">
                                                        <Link to={`/integratedmobileapp/${service.serviceId}`}>
                                                            <span>
                                                                <MdOutlineKeyboardArrowRight />
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default memo(WhoWeServe);