import React, { Component } from 'react';
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListSubServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subServices: []
        }
        this.addSubService = this.addSubService.bind(this);
        this.editSubService = this.editSubService.bind(this);
        this.deleteSubService = this.deleteSubService.bind(this);
    }

    deleteSubService(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => httpHelper.delete('DeleteSubServices', id).then(res => {
                        this.setState({ subServices: this.state.subServices.filter(subService => subService.subServiceId !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/subService');
                        /*setInterval(() => { this.props.history.go('/subService'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/subService'); }
                }
            ]
        });        
    }
    viewSubService(id) {
        this.props.history.push(`/view-subService/${id}`);
    }
    editSubService(id) {
        this.props.history.push(`/add-subService/${id}`);
    }

    componentDidMount() {
        httpHelper.get('GetListSubServices').then((res) => {
            console.log(res.data);
            this.setState({ subServices: res.data });

        });
    }

    addSubService() {
        this.props.history.push('/add-subService/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Sub Service</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addSubService}> Add </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered ">

                                                        <thead>
                                                            <tr>
                                                                <th> Service </th>
                                                                <th> Title </th>
                                                                <th> VideoUrl </th>
                                                                <th> Video </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.subServices.map(
                                                                    subService =>
                                                                        <tr key={subService.subServiceId}>
                                                                            <td> {subService.service} </td>
                                                                            <td> {subService.title} </td>
                                                                            <td> {subService.videoUrl}</td>
                                                                            <td> {subService.isVideo === true ? 'Yes' : 'No'}</td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button onClick={() => this.editSubService(subService.subServiceId)} className="btn btn-primary btn-sm">Update </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteSubService(subService.subServiceId)} className="btn btn-danger btn-sm">Delete </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewSubService(subService.subServiceId)} className="btn btn-primary btn-sm">View </button>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListSubServiceComponent