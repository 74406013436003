import React, { Component } from 'react'
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CreateServiceBenefitsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            serviceId: '',
            service: [],
            benefitsTypeId: '',
            benefits: [],
            title: '',
            errors: {}

        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateServiceBenefits = this.saveOrUpdateServiceBenefits.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.serviceId == "") {
            formIsValid = false;
            errors["serviceId"] = "Cannot be empty";
        }

        if (data.benefitsTypeId == "") {
            formIsValid = false;
            errors["benefitsId"] = "Cannot be empty";
        }

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    service: response.data
                });
            });
            httpHelper.get('GetListDropDownBenefits').then(response => {
                console.log(response.data);
                this.setState({
                    benefits: response.data
                });
            });
            return
        } else {
            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    service: response.data
                });
            });
            httpHelper.get('GetListDropDownBenefits').then(response => {
                console.log(response.data);
                this.setState({
                    benefits: response.data
                });
            });
            httpHelper.getById('GetServiceBenefitsById', this.state.id).then((res) => {
                let serviceBenefits = res.data;
                this.setState({
                    benefitsTypeId: serviceBenefits.benefitsTypeId,
                    serviceId: serviceBenefits.serviceId,
                    title: serviceBenefits.title,

                });
            });
        }
    }

    saveOrUpdateServiceBenefits = (e) => {
        e.preventDefault();
        let serviceBenefits = {
            benefitsTypeId: this.state.benefitsTypeId,
            serviceId: this.state.serviceId,
            title: this.state.title,
        };
        console.log('serviceBenefits => ' + JSON.stringify(serviceBenefits));

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(serviceBenefits)) {
                httpHelper.create('/PostServiceBenefits', serviceBenefits).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceBenefits');
                    /*setInterval(() => { this.props.history.push('/serviceBenefits'); }, 1000);                    */
                });
            }
        } else {
            if (this.handleValidation(serviceBenefits)) {
                httpHelper.update('UpdateServiceBenefits', serviceBenefits, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceBenefits');
                    /*setInterval(() => { this.props.history.push('/serviceBenefits'); }, 1000);*/
                });
            }
        }
    }

    changeServiceIdHandler = (event) => {
        this.setState({ serviceId: event.target.value });
    }

    changeBenefitsTypeIdHandler = (event) => {
        this.setState({ benefitsTypeId: event.target.value });
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }


    cancel() {
        this.props.history.push('/serviceBenefits');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add ServiceBenefits</h3>
        } else {
            return <h3 className="text-center">Update ServiceBenefits</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Benefits</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.serviceId} onChange={this.changeServiceIdHandler}  >
                                                                <option>Select Service</option>
                                                                {this.state.service.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["serviceId"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Benefits Type: </label>
                                                            <select className="form-control" name="benefitsId" value={this.state.benefitsTypeId} onChange={this.changeBenefitsTypeIdHandler}  >
                                                                <option>Select Benefits</option>
                                                                {this.state.benefits.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["benefitsId"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateServiceBenefits} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateServiceBenefitsComponent