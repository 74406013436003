import React, { Component } from 'react';
import { FaRegClock } from "react-icons/fa";
import httpHelper from '../../httpHelper';
import { Header } from '../Header';
import Footer from '../Footer';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment'
import Slider from "react-slick";
import { Link } from "react-router-dom";
var pageurl = "https://localhost:44417";
//SS: Check environment.
if (process.env.NODE_ENV !== "development")
pageurl = `${window.location.origin}`
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

class HealthDetailComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            HealthCategoryId: this.props.match.params.HealthCategoryId,
            media: [
            ],
            title: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            mediamentions: []
        }

    }
    OnRedirect = (routeName,currentId) => {
        this.setState({ id: currentId });
        var searchpageurl = pageurl + '/' + routeName + '/' + currentId + '/'+this.state.HealthCategoryId;
        window.location.href = searchpageurl;       
    }
    //get all data for media list as per id
    componentDidMount() {
        httpHelper.getById('GetHealthLibraryContentsByCategory',  this.props.match.params.HealthCategoryId).then((res) => {           
            this.setState({ media: res.data.filter(obj=>obj.id == this.props.match.params.id)[0] });
            this.setState({ mediamentions: res.data.filter(obj=>obj.id != this.props.match.params.id) });
          });
      
    }

    render() {
        return (
            <div>
                <Header transparent={false} />
                <div>
                    <div className="page-body-wrapper innerContent container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <div className="card"> */}
                                <div className="card-body p-res-0 w-res">
                                    <section className="container">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card-title" style={{color: "#0077b1" }}>
                                                    <h2 class="subDesc" style={{ fontWeight: "600" }}>{this.state.media.title}</h2>
                                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                                        <FaRegClock style={{ color: '#bbbbbb !important' }} size="15" style={{ marginRight: '10px', color: '#bbbbbb' }} />
                                                        <p className="card-text">
                                                            <small className="text-muted datePostCardStart">{moment(this.state.media.createdOn).format('LL')}</small></p></div>
                                                    <div className="contentImageInner mb-5 ">
                                                        {!this.state.media.isVideo ?
                                                            <img src={this.state.media.image} alt={this.state.media.title} title={this.state.media.title} />
                                                            :
                                                            <iframe width="420" height="315" src={this.state.media.videoUrl}></iframe>
                                                        }
                                                    </div>
                                                    <p className='contentPara'>
                                                        {ReactHtmlParser(this.state.media.content)}
                                                    </p>
                                                    <br></br>
                                                    <header>
                                                        <h1 style={{ fontWeight: "600" }}>Related Stories</h1>
                                                    </header>

                                                    <br></br>
                                                    <br></br>
                                                    <div className="row widthFixedContentDetails ">
                                                        <div className="col-md-12 mt-3">
                                                            <Slider {...settings}>
                                                                {this.state.mediamentions.map((element) => {
                                                                    return <div className="col-md-3 p-3" key={element.url}>
                                                                        <div className="sliderTestimonial">
                                                                            <div className="card card-hideBox-shadow" style={{ borderWidth: 0, borderBottomWidth: 0.5 }}>

                                                                                {!element.isVideo ?
                                                                                    <img src={element.image} className="card-img-top" alt={element.title} title={element.title} />
                                                                                    :
                                                                                    <iframe width="420" height="315" src={element.videoUrl}></iframe>

                                                                                }
                                                                                <div className="card-body">
                                                                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                                                                        <FaRegClock style={{ color: '#e0e0e0' }} />
                                                                                        <p className="card-text">
                                                                                            <small className="text-muted" style={{ marginLeft: 10 }}>{moment(element.createdOn).format('LL')}</small></p></div>
                                                                                    <h5 className="card-title cardSubscribeDetailsHeading" style={{ color: "#0077b1" }}>{element.title}</h5>
                                                                                    <p className="card-text cardSubscribeDetails">{element.description}</p>
                                                                                    <Link onClick={() => this.OnRedirect(element.routeName,element.id)}  style={{ borderRadius: "20px", borderColor: "#b5e9e0" }}
                                                                                        className="btn btn-outline-secondary btnListStyle but-health">Read More</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                })}
                                                            </Slider>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}
export default HealthDetailComponent
