import React, { memo } from "react";
import CallButton from "./CallButton";

const TextWithImageFullWidth = (props) => {
    return (
        <>
            <section id="TextWithImageFullWidth" className="SectionDefaultstyle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})` }} >
                <div className="BgGradient"></div>
                <div className="container content">
                    <div className="row">
                        {props.children}
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(TextWithImageFullWidth);