import React, { memo, useEffect, useState } from "react";
import Footerar from "../components/Footerar";
import { Headerar } from "../components/Headerar";
import HomeSlider from "../components/HomeSlider";
import { Helmet } from 'react-helmet';
import PageTitle from "../components/PageTitle";
import PatientsRightsResponsibilities from "../components/PatientsRightsResponsibilities";
import EntryPointsTeleconsultation from "../components/EntryPointsTeleconsultation";
import TeleconsultationServices from "../components/TeleconsultationServices";
import PageContent from "../components/PageContent";
import Situations from "../components/Situations";
import Accordion from 'react-bootstrap/Accordion';
import ReactHtmlParser from 'react-html-parser';
import HttpHelper from '../httpHelper';
import TextWithImageFullWidth from "../components/TextWithImageFullWidth";




const TeleMedicinesAbuDhabiAr = () => {
    const [articles, setArticles] = useState([]);
    const [arabic, setArabic] = useState('arabic');
    useEffect(() => {
        HttpHelper.get('GetFaqsContentForWhatWeDoAr').then((res) => {
            setArticles(res.data);
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>TeleMedicine for AbuDhabi</title>
                <meta name="description" content="" />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>

            <Headerar transparent={false} />
            <section id="Pagetitle" className="mt-TitlePge ArabicTextContentFont" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + 'assets/img/TelemedicineforAbudhabiar.jpeg'})`, direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                <div class="overlayContent"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-white textPageTile ArabicTextContentFont-bold">الطب عن بعد
                                <br />   في أبوظبي</h1>
                        </div>
                    </div>
                </div>
            </section>
            { /* <TeleconsultationServices heading="Know more about our Teleconsultation Services" /> */}




            <section id="TeleconsultationServices" className="KnowMoreTeleconsultation ArabicTextContentFont-bold" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                <section id="PageContent" className="bg-white" style={{ paddingBottom: '75px' }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ArabicTextContentFont-bold">
                                <h2 className="text-ThemeOne heading-Section ArabicTextContentFont-bold" color="blue">
                                    اعرف المزيد عن خدمات الطب عن بعد
                                </h2>
                                <h4 className="ArabicTextContentFont-bold">تشمل خدمات الطب عن بعد::</h4>
                                <br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className={`truoc_ul_center ${arabic == 'arabic' ? 'ArabicTextContent ArabicTextContentFont-medium' : 'ArabicTextContent'}`}>
                                            <li className="fs-20"><img src="assets/img/24_7access.jpg" alt="Tick" title="tick" className="teleservices " />القدرة على الاستفادة من خدمات الاستشارة عبر الإنترنت مع الطبيب العام 7/24 </li>
                                            <li className="fs-20"><img src="assets/img/cronic-care.svg" alt="Tick" title="tick" className="teleservices " />التصنيف والتشخيص، وتقديم الاستشارة الطبية، وتوفير الرعاية للحالات الحرجة والمزمنة ومتابعتها</li>
                                            <li className="fs-20"><img src="assets/img/guidance.svg" alt="Tick" title="tick" className="teleservices " />الإرشادات والتوصيات المتعلقة بالوقاية والعناية بالنفس</li>
                                            <li className="fs-20"><img src="assets/img/video-call.svg" alt="Tick" title="tick" className="teleservices " />الاستشارة عن بعد عبر المكالمة والفيديو </li>

                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className={`truoc_ul_center ${arabic == 'arabic' ? 'ArabicTextContent ArabicTextContentFont-medium' : 'ArabicTextContent'}`}>

                                            <li className="fs-20"><img src="assets/img/prescription.svg" alt="Tick" title="tick" className="teleservices " />وصف الأدوية عند إمكانية ذلك (الأدوية بدون وصفة طبية، والمضادات الحيوية، وأدوية الأمراض المزمنة)</li>
                                            <li className="fs-20"><img src="assets/img/delivery.svg" alt="Tick" title="tick" className="teleservices " />توصيل الأدوية في حال توفرها</li>
                                            <li className="fs-20"><img src="assets/img/multilingual.svg" alt="Tick" title="tick" className="teleservices " />خدمة متعددة اللغات (العربية والإنجليزية والهندية والأردية)</li>
                                            <li className="fs-20"><img src="assets/img/referral.svg" alt="Tick" title="tick" className="teleservices " />الإحالة الطبية عن بعد إلى الأخصائي في حال القدرة على ذلك، وحجز موعد لدى أقرب مزود للخدمات الصحية بما يناسب كل مريض </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* }<Situations heading="Situations you can use Teleonsultation for" />*/}

            <section id="OurSection" className="SituationKnowMoreTelemedicineAbudhabi ArabicTextContentFont" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-ThemeOne heading-Section ArabicTextContentFont-bold">يمكنك الاستفادة من خدمات الطب عن بعد للحالات التالية:</h1>
                        </div>
                        <div className="col-md-12 mt-Btn-Section ">
                            <div className="row ">
                                <div className="col-md-3 mb-3  text-center">
                                    <div className=" mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/preventive-care.svg"} className="our-p-img" alt="Preventive" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">نصائح الرعاية الوقائية والطب العام</p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className=" mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/respiratory.svg"} className="our-p-img" alt="Dedication" title="Dedication" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الجهاز التنفسي </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="text-center mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/chronic-condition.svg"} className="our-p-img" alt="Compassion" title="Compassion" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الأمراض المزمنة </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/digestive.svg"} className="our-p-img" alt="Innovation" title="innovation" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الجهاز الهضمي </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/skin.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">البشرة </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/musculoskeletel.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الجهاز العضلي الهيكلي </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/genitourinary.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الجهاز البولي والتناسلي </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/eye.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">العين </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/systematic.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الأمراض الجهازية </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/ear-nose-throat.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">الأنف والأذن والحنجرة </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/obstetrics.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation ArabicTextContentFont-bold">أمراض النساء والولادة </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <EntryPointsTeleconsultation heading="Situations you can use Teleonsultation for" /> */}

            <section id="PageContent" className='bg-white' style={{ paddingBottom: '75px', direction: arabic == 'arabic' ? 'rtl' : 'ltr' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-ThemeOne heading-Section mb-5 ArabicTextContentFont-bold" color="blue">
                                كيفية عملها
                            </h2>
                            <img src="assets/img/how-it-worksar.png" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="paddingBottomServices" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }} >
                {/*<PageTitle className="KnowMoreTeleconsultation"  title="Patients' Rights and </br> Responsibilities" bgImage="assets/img/patient-right-responsibilities.jpeg"  />*/}
                <section id="Pagetitle" class="pageTitleTeleMedicine" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + 'assets/img/patient-right-responsibilitiesar.jpeg'})` }}>
                    <div class="overlayContent"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 class="text-white textPageTile pageTitleTeleMedicineTxtHeading ArabicTextContentFont-bold">حقوق المريض ومسؤولياته</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <PageContent class="rr-TitlePge" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                    <p className="font24 ArabicTextContentFont-bold">قامت   24/7 Trudoc  بإنشاء ميثاق للمرضى يحدد حقوق ومسؤوليات المريض.</p>
                    <p className="font24 ArabicTextContentFont-bold">كمريض أو أحد من أفراد أسرتك عند الاقتضاء يتحتم عليكم المسؤوليات التالية: </p>

                </ PageContent>

                <PageContent class="bg-white" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                    <h4 className="text-ThemeOne heading-Section ArabicTextContentFont-bold" color="blue" style={{ paddingBottom: '25px' }}>
                        1.1 حقوقكم هي
                    </h4>

                    <ul className="Telemedicinelisttext ArabicTextContentFont-medium" style={{ paddingBottom: '15px' }}>


                        <li> معرفة اسم الطبيب والممرض / الممرضة وغيرهم من الموظفين المسؤولين عن رعايتك </li>

                        <li>	التحدث مع طبيبك المعالج بصراحة عن التشخيص الخاص بك، والعلاج الموصوف لك، وتشخيص مرضك، وأي تعليمات مطلوبة لمتابعة الرعاية 	</li>

                        <li>	من حقك أن تطلب من الطبيب أن يتواصل معك من خلال مصطلحات يمكنك فهمها</li>

                        <li>	أن يتم تلقي طلبك بالكياسة الكاملة والطريقة الملائمة والتعامل معه بشكل صحيح وسريع</li>

                        <li>	أن نقوم بإطلاعك على الأسباب التي يتم من خلالها إعطائك مختلف الاختبارات والعلاجات، ومن هم الأشخاص المسؤولين عن ذلك</li>

                        <li>	أن تكون على علم بالطبيعة العامة والمخاطر الكامنة في أي إجراء كنت قد أعطيت موافقتك عليه</li>

                        <li>	يمكنك تغيير رأيك حول أي إجراء كنت قد أعطيت الموافقة عليه، لرفض العلاج وأن تكون على علم بالعواقب الطبية المترتبة على هذا الإجراء</li>

                        <li>	بالطبع نحترم حقوقك الشخصية إلى أقصى حد يتفق مع الرعاية المنصوص عليها بالنسبة لك وقابلة للتطبيق اتفاقاً مع قوانين دولة الإمارات العربية المتحدة</li>

                        <li>	يجب أن تبقى سرية جميع السجلات وغيرها من السجلات المتعلقة برعايتك إلى الحد الذي يقتضيه القانون</li>

                        <li>	يمكنك أن تطلب من طبيبك المعالج الخاص بك الحصول على رأي ثانٍ من طبيب آخر، لتغيير الأطباء، أو لتغيير المستشفيات و / أو المرافق</li>

                        <li>    المشاركة في المناقشات الأخلاقية التي تساعد على رعايتك بشكل أفضل</li>

                        <li>	الحصول على الرعاية الصحية الملائمة بغض النظر عن العرق أو الجنسية أو العمر أو الجنس أو الإعاقة</li>

                        <li>	من حقك رفض المشاركة في برامج التدريب الطبي أو المشاريع البحثية </li>

                        <li>	الرعاية والعلاج في بيئة آمنة</li>

                        <li>	تلقي إدارة الألم بطريقة رحيمة </li>

                        <li>	كن مطلعاً على نتائج الرعاية، بما في ذلك النتائج غير المتوقعة</li>

                    </ul>

                    <h3 className="text-ThemeOne heading-Section ArabicTextContentFont-bold" color="blue" style={{ paddingBottom: '25px' }}>
                        1.2 المسؤوليات التي عليك تحملها كمريض
                    </h3>

                    <ul className="Telemedicinelisttext ArabicTextContentFont-medium">
                        <li>   التعاون ومتابعة الرعاية المنصوص عليها أو الموصى بها بالنسبة لك من قبل الطبيب المعالج أو الممرضة أو الموظفين المسؤولين عن الرعاية الخاصة بك </li>

                        <li>	إبلاغ الطبيب أو الممرضة إذا لم يكن لديك فهم للتشخيص والعلاج</li>

                        <li>	إعلام مقدم الرعاية الصحية، معلومات دقيقة وكاملة عن الشكاوى الحالية، والأمراض السابقة، والأوقات التي تم إدخالك فيها إلى المستشفى، والأدوية، وأية مسائل أخرى تتعلق بصحتك</li>

                        <li>  احترام حقوق وخصوصية المرضى الآخرين واتباع السياسات المؤسسية كما ذكرت</li>

                        <li>   الحفاظ على المواعيد، وعندما تكون غير قادر على القيام بذلك لأي سبب من الأسباب، يجب إخطار مقدم الرعاية الصحية لديك</li>

                        <li>   القبول بنتيجة أفعالك عند رفضك للعلاج أو عدم اتباع تعليمات مزود الرعاية الصحية</li>

                        <li>   الموافقة على الالتزامات المالية المرتبطة برعايتك 	</li>

                    </ul>

                </ PageContent>
                <div className="complaintPolicy">
                    <div className="container">


                        <div className="row">
                            <div className="col-md-12">

                                <h2 className="headingComplaintPrimary ArabicTextContentFont-bold" >سياسة الشكاوي</h2>
                            </div>
                        </div>
                        <p className="ArabicTextContentFont-medium"> نقدر مراجعاتكم وملاحظاتكم، لذلك قمنا بتحديد سياسة الشكاوي لنضمن التعامل مع كل المشكلات بصدق وشفافية.</p>
                        <p className="ArabicTextContentFont-medium"> يرجى التواصل على الرقم المجاني 800878362 في حال وجود شكوى، أو إرسال بريد إلكتروني إلى complaints@trudoc.ae في أي وقت. يمكن للمرضى ملء استمارة الشكوى إلى TruDoc مباشرة، أو إلى دائرة الصحة - أبوظبي عبر مركز اتصال حكومة أبوظبي على الرقم المجاني 800555.</p>
                        <p className="ArabicTextContentFont-medium">نتعهد بالتعامل مع جميع الشكاوى خلال 5 أيام وقبول جميع الشكاوى المكتوبة خلال 3 أيام عمل. إذا كانت طبيعية الشكوى لا تسمح بحل المشكلة خلال 5 أيام، فإننا سنقوم بإبلاغك بالتأخير. سنخبرك بالإجراءات التي سيتم اتخاذها للتعامل مع شكواك في حال طلبت ذلك.</p>
                        <p className="ArabicTextContentFont-medium">نلتزم بمعايير دائرة الصحة - أبوظبي لحقوق المريض ومسؤولياته، ونؤكد لك أنه سيتم التعامل مع شكواك بشكل عادل وفوري وفق سياسة الشكاوي. </p>



                    </div>
                </div>


            </section>

            <section className="searchFaqSection bg-white arabicFAQ">
                <div className="container">
                    <div className="row">
                        <h3 className="text-ThemeOne heading-Section mb-5 ArabicTextContentFont-bold" color="blue" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }}>
                            الأسئلة الشائعة
                        </h3>
                        <div className="row ArabicTextContentFont-medium">
                            <Accordion defaultActiveKey={['0']}>
                                {articles.map((element, index) => {
                                    return <Accordion.Item eventKey={index} className="accordianFAQ">
                                        <Accordion.Header>{element.questionArabic}</Accordion.Header>
                                        <Accordion.Body className="fs-20 text-thm-blue contentPara">
                                            {ReactHtmlParser(element.answerArabic)}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            <section id="BottomHeadingStripText">
                <div className="container">
                    <h3 className="text-ThemeOne1 colorTehemeOne faqHEading ArabicTextContentFont-bold" style={{ direction: arabic == 'arabic' ? 'rtl' : 'ltr' }} >
                        تعمل TruDoc في أبوظبي كمنشأة صحية تحت الرخصة رقم MF7305
                    </h3>
                </div>
            </section>
            <Footerar />
        </>
    );
}
export default memo(TeleMedicinesAbuDhabiAr);