import React from 'react';

const StatsCard = ({ title, content }) => {
  return (
    <div className='cardstats'>
      <div className='title'>{title}</div>
      <div className='content2'>{content}</div>
    </div>
  );
};

export default StatsCard;
