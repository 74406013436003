import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import OurPresenceNewMap from "../components/OurPresence/OurPresenceNewMap";
import TestOutPresence from "../components/OurPresence/TestOurPresence";
import { map } from 'jquery';
import $ from 'jquery';
import { IoMdClose } from 'react-icons/io';
import ReactDOM from 'react-dom';
import Select from "react-dropdown-select";
import httpHelper from '../httpHelper';

var URL = process.env.PUBLIC_URL + 'assets/img/map.png';
var URLNEW = process.env.PUBLIC_URL + 'assets/img/mapNewOurPresence.png';  
var URLMapIcon = process.env.PUBLIC_URL + 'assets/img/icon-map.png';


var MAP = {
    name: "my-map",
    areas: [
        { value: 1, shape: "circle", coords: [519, 161, 13], label: 'Nigeria', title: 'TruDoc', phone: '800878362', email: 'info@trudocgroup.com' },
        { value: 2, shape: "circle", coords: [520, 268, 17], label: 'South Africa', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' },
        { value: 3, shape: "circle", coords: [556, 395, 14], label: 'Kenya', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' },
        { value: 4, shape: "circle", coords: [603, 300, 18], label: 'Kuwait', title: 'TruDoc', phone: '93852741', email: 'info@aa.com' }
    ]
}

class OurPresenceMapDemo extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectMapData: "" };
        this.MapIconUpdate = this.MapIconUpdate.bind(this);
    }

    componentDidMount() {
        MAP.areas.unshift({
            value: 6, shape: "circle", coords: [575, 168, 16], label: 'MAP', title: 'World Map', phone: '', email: ''
        })
        window.initilizeMap(); 
    };

    MapIconUpdate(e) {
        var MapOurPresenceId;
        //httpHelper.get('GetListOurPresence').then((res) => {
        //    if (e.target.attributes.id.value !== null) {
        //        $('.map-newPresenceLocationFlag').attr('src', res.data[e.target.attributes.id.value].countryFlag);
        //        console.log(res.data[e.target.attributes.id.value].countryFlag, res.data)
        //    }
        //    else {
        //        $('.map-newPresenceLocationFlag').attr('src', 'aa');
        //    }
            
        //});
    }

    render() {
        return (
            <>
                <div>
                    <Header transparent={false} scrolling={false} whiteheader="#fff" />
                    <section className="paddingTopOurPresence" style={{paddingBottom: '0px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="heading headingWhiteBold text-white">Our Presence</h2>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="paddingTopOurPresence mapContentDataFull" style={{ marginTop: '-2px' }}>
                        <div className="container">
                            <div className="row">
                    
                                <div className="mapDataSelectResponsibeMobile">
                                    <Select options={MAP.areas}
                                        onChange={(e) => {
                                            this.setState({
                                                selectMapData: e[0].value
                                            })
                                            window.initilizeMap();
                                            window.MapcordinateAndDetailsSetRes(e);
                                            window.HideMapOnResponsive(e);
                                        }}
                                    />

                                    
                                    <div className="selectBoxData d-none">
                                        {MAP.areas.filter(e => e.value == this.state.selectMapData).map((e) => {
                                            return (
                                                <>
                                                    {e.value == 5 ? 
                                                        'Map'
                                                        : <div className="mapPopupDetails" >
                                                        <div className='mapPopupDetailsMap'>
                                                            {/*<img src={URLMapIcon} />*/}
                                                            <img src={process.env.PUBLIC_URL + 'assets/img/place.png'} className="map-newPresenceLocation" />
                                                            <img src="https://cdn-icons-png.flaticon.com/512/197/197626.png" className="map-newPresenceLocationFlag" />
                                                            {/* {abc[0].name} */}
                                                        </div>
                                                        <div className='mapPopupDetailsDetails'>
                                                            <p className='titleMap'>{e.label}</p>
                                                            <p className='countryMap'>{e.title}</p>
                                                            <p className='number'>{e.phone}</p>
                                                            <p className='email'>{e.email}</p>
                                                        </div>
                                                    </div>}
                                                   

                                                </>

                                            )
                                        })}
                                    </div>

                                </div>

                                <div id="mapContainerLocationPin" class="container">
                                    <img id="map" src={URLNEW} usemap="#map-mask" />
                                    <map name="map-mask">
                                        <area shape="circle" coords="855,548,23" label='South Africa' title='TruDoc SA' iconFlag="assets/img/South-Africa-flag.png" phone='0800 878633' email='service@trudoc24x7.co.za ' target="2" id="1" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <area shape="circle" coords="797,339,26" label='Nigeria' title='Health Connect 24x7' iconFlag="assets/img/nigeria-flag.png" phone='800 0432584' email='info@healthconnect247.com' id="2" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <area shape="circle" coords="928,394,26" label='Kenya' title='HealthX' iconFlag="assets/img/kenya-flag.png" phone='0800 720795' email='support@healthxafrica.com' target="3" id="3" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <area shape="circle" coords="796,166,20.5" label='Monaco' title='TruDoc Healthcare Group' iconFlag="assets/img/monaco-flag.png" phone='Raouf Khalil, Founder & CEO' email='rkhalil@trudocgroup.com' target="4" id="4" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <area shape="circle" coords="1000,270,22" label='UAE' title='TruDoc' iconFlag="assets/img/uae-flag.png" phone='800 878362' email='info@trudoc.ae' target="4" id="5" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <area shape="circle" coords="955,285,19" label='KSA' title='TruDoc KSA' iconFlag="assets/img/ksa-flag.png" phone='' email='info@trudoc.sa' target="4" id="5" onClick={(e) => { window.MapcordinateAndDetailsSet(e); this.MapIconUpdate(e) }} />
                                        <div className="mapPopupDetails mapPopupresHideDetail" id="HideMapOnResponsiveData" style={{ position: 'absolute', left: '575px', top: '168px', zIndex: 9999, display:'none' }} >
                                            <div className="crossIconMap" onTouchStart={(e) => { window.hidepopupMapOurPresence(); window.HideMapOnResponsiveCross(); }} onClick={(e) => { window.hidepopupMapOurPresence(); window.HideMapOnResponsiveCross();}}>
                                                <IoMdClose />
                                            </div>
                                            <div className='mapPopupDetailsMap'>
                                                {/*<img src={URLMapIcon} />*/}
                                                <img src={process.env.PUBLIC_URL + 'assets/img/place.png'} className="map-newPresenceLocation" />
                                                <img src="https://cdn-icons-png.flaticon.com/512/197/197626.png" className="map-newPresenceLocationFlag" />
                                                {/* {abc[0].name} */}
                                            </div>
                                            <div className='mapPopupDetailsDetails'>
                                                <p className='titleMap mapDetailsTitleMap'></p>
                                                <p className='countryMap mapDetailsCoutryMap'></p>
                                                <p className="MonacoDetailPerson">Contact Person: </p> 
                                                <p className="mapPhoneLocationCall">  <img src={process.env.PUBLIC_URL + 'assets/img/call-new.svg'} class="imgCallUsHeader mapPhoneLocationCallimg Call-Buttons-On-Header" alt="Call Us Button" title="Call Us Button" /> <p className='mb-0 number mapDetailsNumber'></p></p>
                                                <p className='email mapDetailsEmail'></p>
                                            </div>
                                        </div>
                                    </map>
                                </div>

                                <div className="col-md-12 d-flex justify-content-start align-items-start cominSoonMapDataLocation">
                                    <img src={process.env.PUBLIC_URL + 'assets/img/mapPincomingSoon.png'} alt="Coming Soon" title="Coming Soon" className="img-fluid comingSoonImg" />
                                    <p className="comingSoonText">Coming soon</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*<OurPresenceNewMap />*/}
                    {/*<TestOutPresence />*/}
                    <Footer />

                </div>
            </>
        );
    }
}
const rootElement = document.getElementById("root");
export default OurPresenceMapDemo;