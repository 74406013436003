import React, { Component } from 'react'
import HttpHelper from '../httpHelper';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { ToastContainer, toast } from 'react-toastify';
import { error } from 'jquery';

class CareerComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            coverLetter: '',
            attachments: '',
            errors: {},
            message: '',
            colorMSG: 'red',
            showCV: false,
            showLetter: false,
            isValidNUmber: true,
            
        }
        this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
        this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
        this.saveOrUpdateCareer = this.saveOrUpdateCareer.bind(this);
    }

    handleValidation(data) {
        let formIsValid = true;
        if (data.firstName == "") {
            formIsValid = false;
            var errorFristName = this.state.errors;
            errorFristName.firstName = 'Can not be empty';
            this.setState({ errorFristName });

        } else {
            var errorFristName = this.state.errors;
            errorFristName.firstName = '';
            this.setState({ errorFristName });
        }
        if (data.lastName == "") {
            formIsValid = false;
            var errorLastName = this.state.errors;
            errorLastName.lastName = 'Can not be empty';
            this.setState({ errorLastName });
        } else {
            var errorLastName = this.state.errors;
            errorLastName.lastName = '';
            this.setState({ errorLastName });
        }
        if (isValidPhoneNumber(data.phoneNumber) === false) {
            formIsValid = false;
            this.state.isValidNUmber = false;
            var errorPhone = this.state.errors;
            errorPhone.phoneNumber = 'Can not be empty';
            this.setState({ errorPhone });
        } else {
            var errorPhone = this.state.errors;
            errorPhone.phoneNumber = '';
            this.setState({ errorPhone });
        }
        if (data.email == "") {
            formIsValid = false;
            var errorEmail = this.state.errors;
            errorEmail.email = 'Can not be empty';
            this.setState({ errorEmail });
        } else {
            var errorEmail = this.state.errors;
            errorEmail.email = '';
            this.setState({ errorEmail });
        }
        if (data.coverLetter == "") {
            formIsValid = false;
            var errorCover = this.state.errors;
            errorCover.coverLetter = 'Can not be empty';
            this.setState({ errorCover });
        } else {
            var errorCover = this.state.errors;
            errorCover.coverLetter = '';
            this.setState({ errorCover });
        }
        return formIsValid;
    }

    saveOrUpdateCareer = (e) => {
        e.preventDefault();
        this.state.isValidNUmber = true;
        let career = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            coverLetter: this.state.coverLetter,
            attachmentsType: this.state.showCV || this.showLetter,
            attachments: this.state.attachments,
        };

        if (this.handleValidation(career)) {
        this.setState({ firstName: '', lastName: '', phoneNumber: '', email: '', coverLetter: '', attachments: '', showCV: false, showLetter: false });
        
        var cvFile = document.getElementById('idCV');
        if (cvFile != undefined && cvFile.files.length > 0) {
            career.attachments = cvFile.files[0].name;
            this.uploadFile(cvFile.files[0]);
        }

        var letterFile = document.getElementById('idLetter');
        if (letterFile != undefined && letterFile.files.length > 0) {
            career.attachments = career.attachments + ';' + letterFile.files[0].name;
            this.uploadFile(letterFile.files[0]);
        }

        HttpHelper.create('/PostCareers', career).then(res => {
            if (res) {
                toast.success('Email sent successfully!!');
                document.getElementById('chkCV').checked = false;
                document.getElementById('chkLetter').checked = false;
            }
        });

        }
        else {
            if (this.state.phoneNumber && !this.state.isValidNUmber) {
                toast.error("Please enter a valid phone number!")
            }
            else {
                //toast.error('Please fill all the mandatory fields!');
            }
        }

    }

    async uploadFile(file) {
        const formData = new FormData();
        const filePath = "Upload/Documents";
        formData.append("fileName", file.name);
        formData.append("formFile", file);
        formData.append("filePath", filePath);
        await HttpHelper.upload("CommonFileUpload", formData);
    }

    changeFirstNameHandler = (event) => {
        this.setState({ firstName: event.target.value });
        if (!event.target.value) {
            var error = this.state.errors;
            error.firstName = 'Can not be empty';
            this.setState({ error });
        } else {
            var errorFristName = this.state.errors;
            errorFristName.firstName = '';
            this.setState({ errorFristName });
        }
    }

    changeLastNameHandler = (event) => {
        this.setState({ lastName: event.target.value });
        if (!event.target.value) {
            var error = this.state.errors;
            error.lastName = 'Can not be empty';
            this.setState({ error });
        } else {
            var error = this.state.errors;
            error.lastName = '';
            this.setState({ error });
        }
    }

    changePhoneNumberHandler = (phone) => {
        this.setState({ phoneNumber: phone });

        if (!phone) {
            var error = this.state.errors;
            error.phoneNumber = 'Can not be empty';
            this.setState({ error });
        } else {
            var error = this.state.errors;
            error.phoneNumber = '';
            this.setState({ error });
        }
    }

    changeEmailHandler = (event) => {
        this.setState({ email: event.target.value });
        if (!event.target.value) {
            var error = this.state.errors;
            error.email = 'Can not be empty';
            this.setState({ error });
        } else {
            var error = this.state.errors;
            error.email = '';
            this.setState({ error });
        }
    }

    changeCoverLetterHandler = (event) => {
        this.setState({ coverLetter: event.target.value });
        if (!event.target.value) {
            var error = this.state.errors;
            error.coverLetter = 'Can not be empty';
            this.setState({ error });
        } else {
            var error = this.state.errors;
            error.coverLetter = '';
            this.setState({ error });
        }
    }

    changeCVAttachmentsTypeHandler = (event) => {
        this.setState({ showCV: this.state.showCV === true ? false : true })
    }

    changeLetterAttachmentsTypeHandler = (event) => {
        this.setState({ showLetter: this.state.showLetter === true ? false : true })
    }

    render() {
        return (
          <a href="https://app.whitecarrot.io/share/careers/trudoc" target="_blank" rel="noopener noreferrer">
          Click here to explore current opportunities at TruDoc
          </a>
        )
    }
}

export default CareerComponent