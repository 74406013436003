import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import SubServiceContent from "../components/SubServiceContent";
import SubServiceOffered1 from "../components/SubServiceOffered1";
export default function MentalHealthCare() {
    return (
        <>
            <Header transparent={false} />
            <PageTitle title="Mental Health Care" subtitle={"An entire support system to help you be kind to your mind"} bgImage="assets/img/bg-mentalcare.png" />
            <SubServiceContent
                main_heading="You can schedule a phone or video consultation with our psychologists to receive:"
                image1="assets/img/mentalcare_img1.jpg"
                image2="assets/img/mentalcare_img2.jpg"
                image3="assets/img/mentalcare_img3.jpg"
                heading1="Psychological counseling and support"
                heading2="Well-being interventions"
                heading3="Wellness workshops and webinars"
                para1="This includes individual and family counseling and psychotherapy; Crisis intervention, disaster and trauma management; Assessment techniques for the diagnosis of psychological disorders; Personalized treatment plans " para2="This includes helping with developing and maintaining an optimistic outlook and quality of life to incorporate mindfulness"
                para3="This includes evidence-based, practical, and engaging webinars and workshops around mental and emotional health, designed to empower you with insights and tools to enhance your life"
            />
            <SubServiceOffered1 right_image1="assets/img/offer-right-img-2.png" />
            <Footer />
        </>
    );
}
