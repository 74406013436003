import React, { memo } from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const WhyTrudocPageContent = (props) => {
    return (
        <>
            <section id="WhyTrudocPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="mb-4">
                                {props.para}
                            </p>
                            <p className="mb-4">
                                {props.para_1}
                            </p>
                            <ul className="truoc_ul">
                                <li><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="tick" className="li_tick" />{props.why_li1}</li>
                                <li><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="tick" className="li_tick" />{props.why_li2}</li>
                                <li><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="tick" className="li_tick" />{props.why_li3}</li>
                                <li><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="tick" className="li_tick" />{props.why_li4}</li>
                                <li><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="tick" className="li_tick" />{props.why_li5}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section id="WhyTrudocPage_bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mb-5">From everyday to complex care, our range of high-quality, accessible, and affordable services include:</p>
                            <p class="btm_sec_bg"><span>TruDoc’s Integrated Mobile Application</span> offers instant access to 24x7 reliable, convenient, and affordable healthcare</p>
                            <p class="btm_sec_bg"><span>TruDoc’s Virtual Clinic</span> offers access to doctors through voice/video calls and live chat during working hours</p>
                            <p class="btm_sec_bg"><span>TruDoc’s Hospitalist/Roaming Doctor Program</span> offers coordinated care in the hospital between the patient, family, and the treating physician</p>
                            <p class="btm_sec_bg"><span>TruDoc’s Hospital at Home Program</span> offers 24x7 telemonitoring for acute and chronic care of patients in the comfort of their homes</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(WhyTrudocPageContent);