import React, { memo, Component } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import httpHelper from "../httpHelper";
import Service_Static_img from "./Service_Static_img";

class Service_WWD extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            id: this.props.service_id,
            services: []
        }

        httpHelper.getById('GetListServiceServicesByServiceId', this.state.id).then(res => {
            console.log("services-", res.data);
            this.setState({ services: res.data });
        });
    }



    render() {
        return (
            <>
                <section id="WhoweServe" className="lessPadding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <h2 className="heading">
                                    Services
                                </h2>
                            </div>
                            {
                                this.state.services.map(
                                    services =>
                                        <div className="col-md-6 mb-4">
                                            <div className="row align-center">
                                                <div className="col-md-4 text-center">
                                                    <img src={process.env.PUBLIC_URL + services.serviceIcon} alt={services.title} title={services.title} className="width100" />
                                                </div>
                                                <div className="col-md-8 spacingData">
                                                    <h5 className="text-grey content-desc">
                                                        {services.title}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                )}

                        </div>
                        <Service_Static_img
                            service_id={this.props.service_id}
                        />
                    </div>
                </section>
            </>
        )
    }
}


export default memo(Service_WWD);