import React, { memo, useEffect } from "react";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import { Header } from "../components/Header";


const My404Component = () => {

    const titleWebsite = "404 | Xetech";
    const descriptionWebsite = "This is Work Page";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitle
                title="Page not Found."
                bgImage="assets/images/pages/work.png"
            />
            <Footer />
        </>
    )
}

export default memo(My404Component);