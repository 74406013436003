import { Component } from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { Header } from './Header';
import Footer from './Footer';
import httpHelper from '../httpHelper';

class SearchDetailComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.text,
            mediaTypeId: '',
            searchData: [
            ],
            title: '',
            content: '',
            thumbnail: '',
            isVideo: false,
            videoUrl: '',
            mediamentions: []
        }

    }
    //get all data for media list as per id
    componentDidMount() {
        var searchtext = this.props.match.params.text;
        httpHelper.getById('Search', searchtext).then((res) => {
            this.setState({ searchData: res.data });
        });
    }

    render() {
        return (
            <div>
                <Header transparent={true} scrolling={true} whiteheader="#fff"  />
                <section className="lightBgColorWidth">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <h2 className="search-heading mb-5">Search result</h2>
                                <ul className="search-result">
                                    {this.state.searchData.map(element => (
                                        <li><Link to={element.url}>
                                            <p className="one_line_ellipsis">{ReactHtmlParser(element.title)} </p>
                                        </Link></li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div >
        )
    }

}
export default SearchDetailComponent
