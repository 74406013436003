import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import FileBase64 from 'react-file-base64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateHomeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            bannerImage: '',
            errors: {}
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateHome = this.saveOrUpdateHome.bind(this);
    }

    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            HttpHelper.getById('GetHomeById', this.state.id).then((res) => {
                let home = res.data;
                this.setState({
                    title: home.title,
                    bannerImage: home.bannerImage
                });
            });
        }
    }

    saveOrUpdateHome = (e) => {
        e.preventDefault();
        let home = {
            title: this.state.title,
            bannerImage: this.state.bannerImage
        };
        /*console.log('home => ' + JSON.stringify(home));*/

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(home)) {
                HttpHelper.create('/PostHome', home).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/home');
                    /*setInterval(() => { this.props.history.push('/home'); }, 1000);                    */
                });
            }

        } else {
            if (this.handleValidation(home)) {
                HttpHelper.update('UpdateHome', home, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/home');
                    /*setInterval(() => { this.props.history.push('/home'); }, 1000);*/
                });
            }
        }
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    getFiles(files) {
        this.setState({ bannerImage: files.base64 })
    }
    cancel() {
        this.props.history.push('/home');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add Home</h3>
        } else {
            return <h3 className="text-center">Update Home</h3>
        }
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Home</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <img width='500' height='200' src={this.state.bannerImage} />
                                                        </div>
                                                        <div className="form-group">
                                                            {/*<input type="file" placeholder="Attachments" name="Attachments" className="form-control"*/}
                                                            {/*    onChange={this.changeAttachmentsHandler} />*/}
                                                            <FileBase64 placeholder="Attachments" name="attachments" className="form-control"
                                                                multiple={false}
                                                                onDone={this.getFiles.bind(this)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateHome} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span style={{ color: "red" }}>{this.state.error}</span>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateHomeComponent