import React, { memo } from "react";
import CallButton from "./CallButton";

const TextWithImageFullWidthRight = (props) => {
    return (
        <>
            <section id="TextWithImageFullWidthRight" className="SectionDefaultstyle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})` }} >
                <div className="BgGradient"></div>
                <div className="container content">
                    <div className="row d-flex justify-content-end">
                        <div className="col-md-6">
                            <h2>
                                {props.heading}
                            </h2>
                            <p className="text-white desc">
                                Our health library provides access to high-quality, vetted, and reliable information in order to enhance your knowledge around different and important health-related topics
                            </p>
                            <CallButton url="/healthlibrary" content="Learn More" icon="" class="mt-Btn-Section padding-button" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default memo(TextWithImageFullWidthRight);
