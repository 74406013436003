import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import SunEditor from 'suneditor-react';

class ViewHealthCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            HealthCategories: [],
            home: {}
        }
    }

    componentDidMount() {
        HttpHelper.get('GetHealthLibraryCategory').then(response => {
            this.setState({
                HealthCategories: response.data
            });
        });
        HttpHelper.getById('GetHealthLibraryById', this.state.id).then(res => {
            this.setState({ home: res.data });
        })
    }

    cancel() {
        this.props.history.push('/health');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Health Library</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Health Category: </label>
                                                            <select disabled="true" className="form-control" name="healthCategoryId" value={this.state.home.healthCategoryId}  >
                                                                <option>Select Health Category</option>
                                                                {this.state.HealthCategories.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.home.title} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="description" className="form-control" readOnly={true}
                                                                value={this.state.home.description} />
                                                        </div>
                                                        <div className="form-group">                                                           
                                                            <img width='500' height='200' src={this.state.home.image} alt={this.state.home.title} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="200" placeholder="Content" setOptions={{
                                                               buttonList: [
                                                                /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                /*'/', //Line break*/
                                                                ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                ['fontColor','fontSize', 'font'],
                                                                /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                            ],
                                                            }}
                                                                setContents={this.state.home.content} disable={true} />
                                                            {/*<input placeholder="Content" name="content" className="form-control"*/}
                                                            {/*    value={this.state.content} onChange={this.changeContentHandler} />*/}
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Page Url : </label>
                                                            <input placeholder="Page Url" name="routeName" className="form-control" readOnly={true}
                                                                value={this.state.home.routeName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5"></div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5"></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewHealthCategoryComponent